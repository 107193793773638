/// <reference path="../../interfaces/models/IArchiveSystemData.ts"/>

class ArchiveSystemData {
    public id?: number = 0;
    public name?: string = "";
    public platformId?: number = 0;
    public platformName?: string = "";
    public baseUrl?: string = "";
    public spBaseUrl?: string = "";
    public isOnline?: boolean = false;
    public userName?: string = "";
    public writeUploadProtocol?: boolean = true;
    public writeSearchProtocol?: boolean = false;

    constructor() { }
}

export default ArchiveSystemData; 
