import * as React from 'react';

export interface AppContextInterface {
    key: string;
    setKey: (value: string) => void;
}

export const ctxt = React.createContext<AppContextInterface | null>(null);
export const AppContextProvider = ctxt.Provider;

