import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { CommandBar, DefaultButton, PrimaryButton, FontIcon, ICommandBarItemProps, SelectionMode } from '@fluentui/react';

import ArchiveSystemData from '../../classes/models/ArchiveSystemData';
import WaitingIndicator from '../../customComponents/WaitingIndicator';
import M365DetailList from '../../customComponents/M365DetailList';
import CustomArchivSystemHandling from '../../customComponents/CustomArchivSystemHandling';

import { ctxt } from '../../config/AppContext';
import { Modus } from '../../classes/CustomColumn';
import ModusRequests from '../../classes/RequestHandler';

export default class ArchiveSystems extends React.Component<any, any> {
    private ArchivSystemHandling = React.createRef<CustomArchivSystemHandling>();

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);

        this.state = {
            items: [], loading: true, hideDialog: true, dialogMessage: "",
            isLoading: true, selectedItem: new ArchiveSystemData(), viewItemMenuParam: "none"
        };
        this.getArchiveSystemData();
    }

    public render(): JSX.Element {

        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading} ></WaitingIndicator >
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}>
                        Verfügbare Dokument Management Systeme
                        </Label>
                    <M365DetailList
                        heightHeader={190}
                        items={this.state.items}
                        columns={this.setColumns()}
                        modalSelection={SelectionMode.single}
                        onSelectEvent={this.onItemClick}
                        onSelectedItemEvent={this.onSelectedItemParamClick}
                    />
                </div>
                <CustomArchivSystemHandling
                    confirmedEvent={this.confirm}
                    history={this.props.history}
                    ref={this.ArchivSystemHandling}
                    startBusy={() => this.setState({ isLoading: true })}
                    stopBusy={() => this.setState({ isLoading: false })}
                ></CustomArchivSystemHandling>
                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={this.closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Löschen bestätigen",
                        subText: this.state.dialogMessage
                    }}
                    modalProps={{ isBlocking: true }}>
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog} text="Abbrechen" primaryDisabled={false} />
                        <PrimaryButton onClick={this.deleteConfirmed} text="Löschen" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private confirm = (message: string): void => {
        if (message !== "") {
            this.setState({isLoading: true });
            this.getArchiveSystemData();
        }
        this.ArchivSystemHandling.current.closeDialog();
    }

    private deleteConfirmed = (): void => {
        this.setState({ isLoading: true, hideDialog: true });
        ModusRequests.Post<any>("ArchiveSystem/Delete", JSON.stringify(this.state.itemToDelete))
            .then((data: any) => {
                this.getArchiveSystemData();
            });
    }

    private showDialog = (item: ArchiveSystemData): void => {
        this.setState({ hideDialog: false, dialogMessage: `Wollen Sie das DMS ${item.name} wirklich löschen?`, itemToDelete: item });
    }

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    }

    private getArchiveSystemData = (): void => {
        ModusRequests.GetData<ArchiveSystemData>("ArchiveSystem/Get")
            .then((data: Array<ArchiveSystemData>) => {
                this.setState({ items: data, loading: false, isLoading: false });
                this.context.setKey('key3');
            });
    }

    private editClick = (item: ArchiveSystemData): void => {
        this.ArchivSystemHandling.current.updateData(item);
    }

    private editMappingClick = (item: ArchiveSystemData): void => {
        this.props.history.push({
            pathname: '/mappings',
            state: { Archiv: item.name }
        });
    }

    private deleteArchiveSystemClick = (item: ArchiveSystemData): void => {
        this.showDialog(item);
    }

    private onItemClick = (pData: ArchiveSystemData): void => {
        this.editClick(JSON.parse(JSON.stringify(pData)))
    }

    private onSelectedItemParamClick = (pData: Array<ArchiveSystemData>): void => {
        if (pData.length === 0)
            this.setState({ viewItemMenuParam: "none" });
        else
            this.setState({ selectedItem: pData[0], viewItemMenuParam: "block" });
    }

    private setColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'Name', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true)
            }, {
                key: 'Platform', name: 'Plattform Name', fieldName: 'platformName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string'
            }, {
                key: 'IsOnline', name: 'Aktiv', fieldName: 'isOnline', minWidth: 50, maxWidth: 50, isResizable: false, data: 'boolean',
                onRender: (item: ArchiveSystemData) => {
                    return (item.isOnline ?
                        <FontIcon iconName="completed" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'green' }} /> :
                        <FontIcon iconName="ErrorBadge" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} />)
                },
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.booleanFilter, true, true, [], [{ key: true, text: 'Aktiv' }, { key: false, text: 'Inaktiv' }])
            }, {
                key: 'UserName', name: 'Geändert von', fieldName: 'userName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string',
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10
            }
        ]
        return columns;
    }

    private renderCommands = (): ICommandBarItemProps[] => {
        return [
            {
                key: 'newItem', text: 'Neu', ariaLabel: 'New', style: { backgroundColor: "transparent" },
                iconProps: { iconName: 'Add', style: { fontSize: "15px" } },
                onClick: () => this.ArchivSystemHandling.current.updateData(new ArchiveSystemData())
            }, {
                key: 'editItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
                onClick: () => this.editClick(this.state.selectedItem)
            }, {
                key: 'deleteItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
                onClick: () => this.deleteArchiveSystemClick(this.state.selectedItem)
            }, {
                key: 'mappings', text: 'Zuordnungen', ariaLabel: 'Mappings', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'PlugConnected', style: { fontSize: "15px" } },
                onClick: () => this.editMappingClick(this.state.selectedItem)
            }]
    }
}