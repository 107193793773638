import * as React from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DroppableProvided,
    DraggableLocation,
    DropResult,
    DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { Icon, Label } from '@fluentui/react';
import ResultData from '../../classes/models/ResultData';

interface Props {
    items: Array<ResultData>;
    selectedItems: Array<ResultData>;
    orderedEvent: (items: Array<ResultData>, selectedItems: Array<ResultData>) => void;
}

interface IAppState {
    items: Array<ResultData>;
    selectedItems: Array<ResultData>;
}

interface IMoveResult {
    droppable: Array<ResultData>;
    droppable2: Array<ResultData>;
}

export default class M365MultiDragDrop extends React.Component<Props, any> {

    private id2List = {
        droppable: 'items',
        droppable2: 'selectedItems'
    };

    private grid = 8;

    constructor(props: any) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px', width: "50%", minWidth: '300px', maxWidth: '600px', float: "left" }}>
                    <Label required>Zugeordnete Spalten</Label>
                    <Droppable droppableId="droppable">
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.props.items.map((item, index) => (
                                    <Draggable key={item.resultRowName} draggableId={item.resultRowName} index={index}>
                                        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                            <div>

                                                <div
                                                    ref={providedDraggable.innerRef}
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        providedDraggable.draggableProps.style,
                                                        snapshotDraggable.isDragging, index
                                                    )}
                                                ><Icon className="m365DragIcon" iconName={"DragObject"} />
                                                    {item.resultRowDisplayName}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {this.props.items.length === 0 && <>Es wurden keine Spalten zugeordnet</>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px', width: "50%", minWidth: '300px', maxWidth: '600px', float: "left" }}>
                    <Label>Verfügbare Spalten</Label>
                    <Droppable droppableId="droppable2">
                        {(providedDroppable2: DroppableProvided, snapshotDroppable2: DroppableStateSnapshot) => (
                            <div
                                ref={providedDroppable2.innerRef}
                                style={this.getListStyle(snapshotDroppable2.isDraggingOver)}>
                                {this.props.selectedItems.map((item, index) => (
                                    <Draggable
                                        key={item.resultRowName}
                                        draggableId={item.resultRowName}
                                        index={index}>
                                        {(providedDraggable2: DraggableProvided, snapshotDraggable2: DraggableStateSnapshot) => (
                                            <div>

                                                <div
                                                    ref={providedDraggable2.innerRef}
                                                    {...providedDraggable2.draggableProps}
                                                    {...providedDraggable2.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        providedDraggable2.draggableProps.style,
                                                        snapshotDraggable2.isDragging, index
                                                    )}><Icon className="m365DragIcon" iconName={"DragObject"} />
                                                    {item.resultRowDisplayName}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {this.props.selectedItems.length === 0 && <>Es sind keine weiteren Spalten verfügbar</>}
                                {providedDroppable2.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        );
    }

    private getItemStyle = (draggableStyle: any, isDragging: boolean, index: number): {} => ({
        userSelect: 'none',
        borderTop: index === 0 ? !isDragging ? 0 : `1px solid #ddd` : `1px solid #ddd`,
        borderBottom: !isDragging ? 0 : `1px solid #ddd`,
        padding: 10,
        color: isDragging ? `rgba(0, 0, 0, 0.35)` : `rgba(0, 0, 0, 0.85)`,
        ...draggableStyle
    });

    private getListStyle = (isDraggingOver: boolean): {} => ({
        padding: this.grid,
        minHeight: 400
    });

    private onDragEnd = (result: DropResult): void => {
        let { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            let items = this.reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            let state: IAppState = { ...this.props };

            if (source.droppableId === "droppable2") {
                state = { ...this.props, selectedItems: items };
            } else if (source.droppableId === "droppable") {
                state = { ...this.props, items }
            }

            this.props.orderedEvent(state.items, state.selectedItems);


        } else {
            let resultFromMove: IMoveResult = this.move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );
            this.props.orderedEvent(
                resultFromMove.droppable,
                resultFromMove.droppable2
            );

        }
    };

    private move = (source: Array<ResultData>, destination: Array<ResultData>, droppableSource: DraggableLocation, droppableDestination: DraggableLocation): IMoveResult | any => {
        let sourceClone = [...source];
        let destClone = [...destination];
        let [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        let result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    private getList = (id: string): Array<ResultData> => {
        return this.props[this.id2List[id]];
    }

    private reorder = (list: Array<ResultData>, startIndex: number, endIndex: number): Array<ResultData> => {
        let result = [...list];
        let [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
}

