import * as React from 'react';
import { Customizations, Icon, Label } from '@fluentui/react';

interface Props {
    name: string;
    key: string;
    id: string;
    icon: string;
    url: string;
    selected: string;
    width: number;
    selectedEvent: (id: string, url: string) => void;
}

export default class ConfigurationItem extends React.Component<Props, any> {

    constructor(props: any) {
        super(props);
        this.state = { id: this.props.id, url: this.props.url };
    }

    public render(): JSX.Element {
        let theme = Customizations.getSettings(["theme"]).theme;
        return (
            <div className={this.props.selected === this.props.id ? "m365NavItem select" : "m365NavItem"}
                onClick={this.handleClick} style={{ width: this.props.width }} >
                <p className={"m365NavDiv"} style={{ backgroundColor: this.props.selected === this.props.id ? theme.palette.themePrimary : "transparent"}} />
                <Icon className="m365NavIcon" iconName={this.props.icon} />
                <Label className="m365NavLabel">{this.props.width === 200 ? this.props.name : ""}</Label>
            </div>
        );
    }

    private handleClick = (): void => { this.props.selectedEvent(this.state.id, this.state.url); }
}