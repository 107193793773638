/// <reference path="../../interfaces/models/IDocumentTypeData.ts"/>

import MappingData from "./MappingData";

class DocumentTypeData {
    public id?: number = 0;
    public systemId?: number = 0;
    public archiveSystemId?: number = 0;
    public systemName?: string = "";
    public archiveSystemName?: string = "";
    public siteUrl?: string = "";
    public documentTypeName?: string = "";
    public isOnline?: boolean = false;
    public contentTypeId?: string = "";
    public contentTypeName?: string = "";
    public useContentTypes?: boolean = false;
    public userName?: string = "";
    public mappingError?: boolean = false;
    public isSharepoint?: boolean = false;
    public isShareflex?: boolean = false;
    public unMapped?: boolean = false;
    public mappings?: Array<MappingData> = [];
    public availableParams?: Array<string> = [];
    public startUrl?: string = "";
    public documentLibrary?: string = "";
    public documentLibraryName?: string = "";
    public listLibrary?: string = "";
    public listLibraryName?: string = "";
    public history: any;

    constructor() { }
}

export default DocumentTypeData; 
