import * as React from 'react';
import { Stack, Dropdown, IDropdownOption } from '@fluentui/react';

import DmsMappingHandlingProps from './DmsMappingHandlingProps';
import { ModusRequests } from '../../classes/RequestHandler';

export default class DmsMappingHandling extends React.Component<DmsMappingHandlingProps, any> {

    constructor(props: any) {
        super(props);
        if (!this.props.sysData.unMapped) {
            this.validateDnsPage();
            this.state = { dropdownData: [] };
        }
        else
            this.state = { dropdownData: [], isValidated: true};
    }

    public render(): JSX.Element {
        return (<Stack tokens={{ childrenGap: 20 }}>
            {this.renderPage()}
        </Stack>);
    }

    private renderPage(): JSX.Element {
        return (<div style={{ width: "100%" }}>
            <Dropdown
                placeholder="Bitte wählen Sie eine Dokumentenmaske aus!"
                defaultSelectedKey={this.props.sysData.documentLibrary}
                required={true}
                styles={{ dropdown: { maxWidth: '100%' } }}
                options={this.state.dropdownData}
                errorMessage={this.props.inputRootFolderErrorMessage}
                onChange={this.onLibChanged}
                disabled={!this.state.isValidated || this.props.sysData.mappings.length > 0}
                label="Dokumentenmaske" />
        </div>
        );
    }

    private validateDnsPage = (): void => {
        this.props.clearErrorMessagesEvent();
        ModusRequests.Get<any>("Dns/GetLists?DocumentTypId=" + this.props.sysData.id)
            .then((data) => {
                if (typeof (data) === "string") {
                    this.props.errorEvent(data);
                } else {
                    let ddData: Array<any> = [];
                    Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.key, text: pf.value }) });
                    this.setState({ dropdownData: ddData, isValidated: true });
                    this.props.errorEvent("");
                }
            });
    }

    private onLibChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        this.props.dropChanged(option.key as string, option.text);
    }
}