import * as React from "react";
import {
  Panel,
  PanelType,
  Stack,
  DefaultButton,
  PrimaryButton,
  IChoiceGroupOption,
  ChoiceGroup,
  TextField,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";

import ArchiveSystemData from "../classes/models/ArchiveSystemData";
import ArchiveSystemValidatedModel from "../classes/models/ArchiveSystemValidatedModel";
import PlatformData from "../classes/models/PlatformData";
import { ModusRequests } from "../classes/RequestHandler";

interface Props {
  confirmedEvent: (message: string) => void;
  history: any;
  startBusy: () => void;
  stopBusy: () => void;
}

export default class CustomArchivSystemHandling extends React.Component<
  Props,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = { data: new ArchiveSystemData(), dropdownData: [] };
    this.getPlatformData();
  }

  public render(): JSX.Element {
    return (
      <Panel
        isOpen={this.state.hideDialog}
        onDismiss={() => this.confirmDialog("")}
        type={PanelType.custom}
        customWidth={"500px"}
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
        styles={{
          content: {
            height: "calc(100vh - 140px) !important",
            minHeight: "calc(100vh - 140px) !important",
          },
        }}
        headerText={
          this.state.data.id > 0
            ? this.state.data.name + " bearbeiten"
            : "Neues DMS anlegen"
        }
      >
        <div style={{ minWidth: "200px", maxWidth: "600px" }}>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              marginTop: "20px",
              borderColor: "#CFCDCC",
              borderStyle: "solid",
              borderWidth: "0px",
            }}
          >
            <Stack tokens={{ childrenGap: 20 }}>
              <TextField
                label="Eindeutiger Name"
                required={true}
                errorMessage={this.state.inputNameErrorMessage}
                validateOnLoad={false}
                value={this.state.data.name}
                onChange={this.textChange}
                styles={{ root: { maxWidth: "100%" } }}
                disabled={this.state.data.id > 0}
              />
              <Dropdown
                placeholder="..."
                defaultSelectedKey={this.state.data.platformId}
                label="Plattform"
                options={this.state.dropdownData}
                styles={{ dropdown: { maxWidth: "100%" } }}
                onChange={this.onDropdownChange}
                required={true}
                disabled={this.state.data.id > 0}
                errorMessage={this.state.inputPlatFormErrorMessage}
              />
              <ChoiceGroup
                className="defaultChoiceGroup"
                defaultSelectedKey={
                  this.state.data.writeUploadProtocol ? "true" : "false"
                }
                options={[
                  {
                    key: "true",
                    text: "Aktiv",
                  } as IChoiceGroupOption,
                  {
                    key: "false",
                    text: "Inaktiv",
                  },
                ]}
                onChange={this.onUploadProtocolChange}
                label="Übertragungsprotokoll"
              />
              <ChoiceGroup
                className="defaultChoiceGroup"
                defaultSelectedKey={
                  this.state.data.writeSearchProtocol ? "true" : "false"
                }
                options={[
                  {
                    key: "true",
                    text: "Aktiv",
                  } as IChoiceGroupOption,
                  {
                    key: "false",
                    text: "Inaktiv",
                  },
                ]}
                onChange={this.onSearchProtocolChange}
                label="Suchprotokoll"
              />
              <TextField
                label="Basis Url des UniversalLink-Adapters"
                required={true}
                errorMessage={this.state.inputBaseUrlErrorMessage}
                validateOnLoad={false}
                value={this.state.data.baseUrl}
                onChange={this.urlChange}
                styles={{ root: { maxWidth: "100%" } }}
              />
              {(this.state.data.platformName.toLowerCase() === "sharepoint" ||
                this.state.data.platformName.toLowerCase() === "shareflex" ||
                this.state.data.platformName.toLowerCase() === "teams") && (
                <TextField
                  label={"Basis Url vom SharePoint-Mandant"}
                  required={true}
                  errorMessage={this.state.inputSpBaseUrlErrorMessage}
                  validateOnLoad={false}
                  value={this.state.data.spBaseUrl}
                  onChange={this.spUrlChange}
                  styles={{ root: { maxWidth: "100%" } }}
                />
              )}
              <ChoiceGroup
                className="defaultChoiceGroup"
                defaultSelectedKey={this.state.data.isOnline ? "true" : "false"}
                options={[
                  { key: "true", text: "Aktiv" } as IChoiceGroupOption,
                  { key: "false", text: "Inaktiv" },
                ]}
                onChange={this.onChoiceGroupChange}
                label="Zustand"
              />
            </Stack>
          </div>
        </div>
      </Panel>
    );
  }

  public updateData = (data: ArchiveSystemData): void => {
    this.setState({
      data: data,
      hideDialog: true,
      inputNameErrorMessage: "",
      inputPlatFormErrorMessage: "",
      inputBaseUrlErrorMessage: "",
    });
  };

  public closeDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private confirmDialog = (message: string): void => {
    this.props.confirmedEvent(message);
  };

  private onRenderFooterContent = () => (
    <div style={{ minWidth: "200px", maxWidth: "600px" }}>
      <Stack tokens={{ childrenGap: 20 }} horizontal>
        <Stack.Item align="start" grow>
          <DefaultButton
            onClick={() => this.confirmDialog("")}
            text="Zurück ohne speichern"
            primaryDisabled={false}
          />
        </Stack.Item>
        <Stack.Item
          align="end"
          grow
          styles={{ root: { alignContent: "flex-end", flexGrow: 0 } }}
        >
          <PrimaryButton onClick={this.saveClick} text="DMS speichern" />
        </Stack.Item>
      </Stack>
    </div>
  );

  private saveClick = (): void => {    
    this.clearErrorMessages();
    ModusRequests.Post<ArchiveSystemValidatedModel>(
      "ArchiveSystem/Post",
      JSON.stringify(this.state.data)
    ).then((data: ArchiveSystemValidatedModel) => {
      this.mapMessages(data);
    });
  };

  private clearErrorMessages = (): void => {
    this.props.startBusy();
    this.setState({
      inputNameErrorMessage: "",
      inputPlatFormErrorMessage: "",
      inputBaseUrlErrorMessage: "",
    });
  };

  private mapMessages = (data: ArchiveSystemValidatedModel): void => {
    if (data.isValid) this.props.confirmedEvent(data.okMessage.Message);
    else this.mapErrorMessages(data.errors);
  };

  private mapErrorMessages = (messages: ArchiveSystemValidatedModel): void => {
    if (messages.hasOwnProperty("Name"))
      this.setState({ inputNameErrorMessage: messages["Name"] });

    if (messages.hasOwnProperty("PlatForm"))
      this.setState({ inputPlatFormErrorMessage: messages["PlatForm"] });

    if (messages.hasOwnProperty("BaseUrl"))
      this.setState({ inputBaseUrlErrorMessage: messages["BaseUrl"] });

    if (messages.hasOwnProperty("SpBaseUrl"))
      this.setState({ inputSpBaseUrlErrorMessage: messages["SpBaseUrl"] });

    this.props.stopBusy();
  };

  private textChange = (eventData): void => {
    let newData = this.state.data;
    newData.name = eventData.target.value;
    this.setState({ data: newData });
  };

  private urlChange = (eventData): void => {
    let newData = this.state.data;
    newData.baseUrl = eventData.target.value;
    this.setState({ data: newData });
  };

  private spUrlChange = (eventData): void => {
    let newData = this.state.data;
    newData.spBaseUrl = eventData.target.value;
    this.setState({ data: newData });
  };

  private onDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption
  ): void => {
    let newData = this.state.data;
    newData.platformId = option.key as number;
    newData.platformName = option.text;
    this.setState({ data: newData });
  };

  private onChoiceGroupChange = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.data;
    newData.isOnline = option.key === "true";
    this.setState({ data: newData });
  };

  private onUploadProtocolChange = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.data;
    newData.writeUploadProtocol = option.key === "true";
    this.setState({ data: newData });
  };

  private onSearchProtocolChange = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.data;
    newData.writeSearchProtocol = option.key === "true";
    this.setState({ data: newData });
  };

  private getPlatformData = (): void => {
    ModusRequests.GetData<PlatformData>("Platform/Get").then(
      (data: Array<PlatformData>) => {
        let ddData: Array<any> = [];
        data
          .filter((x) => x.isDestinationPlatform)
          .forEach((pf) => {
            ddData.push({ key: pf.id, text: pf.name });
          });
        this.setState({ dropdownData: ddData, isLoading: false });
      }
    );
  };
}
