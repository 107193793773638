import * as React from 'react';
import { Panel, PanelType, Stack, IStackTokens, SearchBox, Checkbox, Toggle, DateRangeType, DayOfWeek, DatePicker, mergeStyleSets, IDatePickerStrings } from '@fluentui/react';

import Modus from '../classes/CustomColumn';

interface Props {
    onSelectEvent: (column: Modus.CustomColumn, allSelected: boolean) => void;
}

interface State {
    showPanel: boolean;
    column?: Modus.CustomColumn;
    isChecked: boolean;
    selectedDate: Date;
    startDate: Date;
    endDate: Date;
    
}

const DayPickerStrings: IDatePickerStrings = {
    months: ['Januar','Februar','März','April','Mai','Juni',
             'Juli','August','September','Oktober','November','Dezember',],
    shortMonths: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    goToToday: 'Heute',
};


const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 15px 0',
        maxWidth: '300px',
    },
});

export interface ICalendarInlineExampleProps {
    isMonthPickerVisible?: boolean;
    dateRangeType: DateRangeType;
    autoNavigateOnSelection: boolean;
    showGoToToday: boolean;
    showNavigateButtons?: boolean;
    highlightCurrentMonth?: boolean;
    highlightSelectedMonth?: boolean;
    isDayPickerVisible?: boolean;
    showMonthPickerAsOverlay?: boolean;
    showWeekNumbers?: boolean;
    minDate?: Date;
    maxDate?: Date;
    restrictedDates?: Date[];
    showSixWeeksByDefault?: boolean;
    workWeekDays?: DayOfWeek[];
    firstDayOfWeek?: DayOfWeek;
}

export interface ICalendarInlineExampleState {
    selectedDate?: Date | null;
    selectedDateRange?: Date[] | null;
}

export default class CustomFilterPanel extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = { showPanel: false, isChecked: false, selectedDate: null, startDate: null,  endDate: null };
    }

    public render() {
        return (
            <Panel
                isOpen={this.state.showPanel}
                onDismiss={this.closePanel}
                type={PanelType.custom}
                customWidth={'500px'}
                isBlocking={false}
                styles={{ content: { height: "calc(100vh - 140px) !important", minHeight: "calc(100vh - 140px) !important" } }}
                headerText={'Filtern nach \'' + this.state.column?.name  +  '\''}>
                <div style={{ minWidth: '200px', maxWidth: '600px' }}>
                    <div style={{
                        paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px',
                        borderColor: '#CFCDCC', borderStyle: 'solid', borderWidth: '0px'
                    }}>
                        <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                            {this.renderContent()}
                        </Stack>
                    </div>
                </div>
            </Panel>
        )
    }

    private renderContent = (): JSX.Element => {
        if (this.state.column === null || this.state.column === undefined)
            return (<div></div>);
        if (this.state.column !== undefined && this.state.column.customColumnProps?.filterType === Modus.FilterType.textFilter)
        return (
            <SearchBox
                placeholder="Suche"
                value={this.state.column.customColumnProps.filteredValues[0]?.value}
                defaultValue={this.state.column.customColumnProps.filteredValues[0]?.value}
                onChange={(ev, text?) => this.searchTextChanged(ev, text)}
                />);
        if (this.state.column !== undefined && this.state.column.customColumnProps?.filterType === Modus.FilterType.distinctFilter)
            return (<div>
                {this.state.column.customColumnProps.filteredValues.map(item => {
                    return (<Checkbox key={item.value} label={item.value} inputProps={item}
                        onChange={this.selectOptionChanged} checked={item.selected}/>)
                })} 
            </div>);
        if (this.state.column !== undefined && this.state.column.customColumnProps?.filterType === Modus.FilterType.booleanFilter)
            return (<div>
                {this.state.column.customColumnProps.filteredValues.map(item => {
                    return (<Checkbox key={item.value} label={item.value} inputProps={item}
                        onChange={this.selectBoolOptionChanged} checked={item.selected} />)
                })}
            </div>);
        if (this.state.column !== undefined && this.state.column.customColumnProps?.filterType === Modus.FilterType.dateRangeFilter)
            return (<div>
                <Toggle
                    onText="Datumsbereich" offText="Datum" checked={this.state.isChecked}
                    onChange={() => this.setState({ isChecked: !this.state.isChecked })} />
                {!this.state.isChecked && <DatePicker label="Datum" className={controlClass.control}
                    strings={DayPickerStrings} placeholder="Datum auswählen"
                    onSelectDate={this.selectDateChanged} formatDate={this.formatDate}
                    value={ this.state.column.isFiltered ? this.state.selectedDate: null}
                />}
                {this.state.isChecked && <DatePicker label="StartDatum" className={controlClass.control}
                    strings={DayPickerStrings} placeholder="Datum auswählen"
                    formatDate={this.formatDate}
                    onSelectDate={this.selectStartDateChanged}
                    value={this.state.startDate }
                />}
                {this.state.isChecked && <DatePicker label="EndDatum" className={controlClass.control}
                    strings={DayPickerStrings} placeholder="Datum auswählen" formatDate={this.formatDate}                 
                    onSelectDate={this.selectEndDateChanged}
                    value={ this.state.endDate }
                />}
            </div>);
    }

    private selectBoolOptionChanged = (ev: React.FormEvent<HTMLElement>, newChecked: boolean): void => {
        let column = this.state.column as Modus.CustomColumn;
        column.customColumnProps.filteredValues.find(x => x.value === (ev.currentTarget as HTMLInputElement).value).selected = newChecked;
        if (newChecked)
            column.customColumnProps.filteredValues.find(x => x.value !== (ev.currentTarget as HTMLInputElement).value).selected = !newChecked;
        this.props.onSelectEvent(JSON.parse(JSON.stringify(column)), false);
    }

    private selectOptionChanged = (ev: React.FormEvent<HTMLElement>, newChecked: boolean): void => {
        let column = this.state.column as Modus.CustomColumn;
        column.customColumnProps.filteredValues.find(x => x.value === (ev.currentTarget as HTMLInputElement).value).selected = newChecked;
        this.props.onSelectEvent(JSON.parse(JSON.stringify(column)), column.customColumnProps.filteredValues.filter(x => x.selected).length === column.customColumnProps.filteredValues.length);
    }

    private searchTextChanged = (ev: React.FormEvent<HTMLInputElement>, text?: string): void => {
            let column = this.state.column as Modus.CustomColumn;
            let filter = new Modus.FilterValue();
            filter.value = text;
            filter.selected = text?.length >= 3;
            column.customColumnProps.filteredValues[0] = filter;
            this.props.onSelectEvent(column, false);
    }

    private selectDateChanged = (date: Date | null | undefined): void => {  
        this.setState({ selectedDate: date, startDate: null, endDate: null  });
        let column = this.state.column as Modus.CustomColumn;
            column.customColumnProps.filteredValues[0] = this.getFilterValue(date);
        this.props.onSelectEvent(column, false);
    }

    private getFilterValue = (date: Date): Modus.FilterValue => {
        let filter = new Modus.FilterValue();
            filter.value = this.formatDate(date);
            filter.selected = true;
        return filter;
    }

    private getFilterValues = (startDate: Date, endDate: Date): Modus.FilterValue => {
        let filter = new Modus.FilterValue();
        filter.value = this.formatDate(startDate) + ';' + this.formatDate(endDate);
        filter.selected = true;
        return filter;
    }

    private selectStartDateChanged = (date: Date | null | undefined): void => {
        this.setState({ selectedDate: null,startDate: date });
        let column = this.state.column as Modus.CustomColumn;
        if (this.state.endDate !== null) {
            column.customColumnProps.filteredValues[0] = this.getFilterValues(date, this.state.endDate);
            this.props.onSelectEvent(column, false);
        }  
    }

    private selectEndDateChanged = (date: Date | null | undefined): void => {
        this.setState({ selectedDate: null, endDate: date });     
        let column = this.state.column as Modus.CustomColumn;
        if (this.state.startDate !== null) {
            column.customColumnProps.filteredValues[0] = this.getFilterValues(this.state.startDate, date);
            this.props.onSelectEvent(column, false);
        }
    }

    private formatDate = (date?: Date): string => {
        return (date.toLocaleDateString('de-DE', { year: "numeric", month: "2-digit", day: "2-digit" }));
    }

    private closePanel = (): void => {
        this.setState({ showPanel: false }); 
    }

    public updateData = (column: Modus.CustomColumn): void => {
        this.setState({ column: column, showPanel: true });
    }



}