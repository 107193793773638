import * as React from 'react';
import { Dropdown, Dialog, DialogType, DialogFooter, DefaultButton, PrimaryButton, IDropdownOption } from '@fluentui/react';

interface Props {
    confirmedEvent: (key: number) => void;
}

interface State {
    hideDialog: boolean;
    ddData: Array<any>;
    key: number;
    errorMessage: string;
}

export default class CustomCopyDialog extends React.Component<Props, State> {
    props: any;

    constructor(props: any) {
        super(props);
        this.state = { hideDialog: true, ddData: [], key: 0, errorMessage: "" }
    }

    public render() {
        return (
            <Dialog
                hidden={this.state.hideDialog}
                onDismiss={this.closeDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Zuordnung auswählen",
                    subText: this.props.dialogMessage
                }} modalProps={{ isBlocking: true }} >
                <Dropdown placeholder="Bitte wählen Sie eine Zuordnung aus!"
                    label="Zuordnung"
                    options={this.state.ddData}
                    styles={{ dropdown: { maxWidth: '100%' }, root: {paddingBlockStart:"15px", paddingBlockEnd:"15px"} }}
                    onChange={this._onDropdownChange}
                    required={true}
                    errorMessage={this.state.errorMessage}
                />
                <DialogFooter>
                    <DefaultButton className="ms-Cancel-Button" onClick={this.closeDialog} text="Abbrechen" primaryDisabled={false} />
                    <PrimaryButton className="ms-Default-Button2" onClick={this.confirmed} text="Ok" />
                </DialogFooter>
            </Dialog>
        );
    }

    public openDialog = (ddData: any): void => {
        this.setState({ ddData: ddData, hideDialog: false });
    }

    private _onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        this.setState({ key: option.key as number, errorMessage: "" });
    }

    private closeDialog = (): void => {
        this.setState({ ddData: [], hideDialog: true, key: 0 });
    }

    private confirmed = (): void => {
        if (this.state.key === 0)
            this.setState({ errorMessage: "Bitte wählen Sie eine Zuordnung aus!" });
        else {
            this.props.confirmedEvent(this.state.key);
            this.closeDialog();
        }
    }
}