import * as React from 'react';
import { Stack, PrimaryButton, ChoiceGroup, IChoiceGroupOption, Label, CommandBar, ICommandBarItemProps, FontIcon, Dialog, DialogType, DialogFooter, DefaultButton, TextField, SelectionMode } from '@fluentui/react';
import Dropzone from '../../customComponents/ImExport/ModusDragDrop';
import WaitingIndicator from '../../customComponents/WaitingIndicator';
import ImportData, { ImportSystemData, ImportArchiveData, ImportResultData, ImportDocumentTypeData } from '../../classes/models/ImportData';
import { Modus } from '../../classes/CustomColumn';
import ImportConnectorHandling from '../../customComponents/ImExport/ImportConnectorHandling';
import { ctxt } from '../../config/AppContext';
import ModusRequests from '../../classes/RequestHandler';

import { Steps } from 'antd';
import '../../css/antd.css';
import M365DetailList from '../../customComponents/M365DetailList';
import DocumentTypeData from '../../classes/models/DocumentTypeData';

const { Step } = Steps;

interface state {
    blob: Blob;
    allData: boolean;
    isLoading: boolean;
    initLoading: boolean;
    connectorError: boolean;
    hideDialogError: boolean;
    hideDialog: boolean;
    dialogMessage: string;
    viewItemMenuParam: string;
    viewItemExport: string;
    state: number;
    task: string;
    errorText: string;
    errorData: Array<any>;
    newData: ImportData;
    systemData: ImportSystemData;
    archivData: ImportArchiveData;
    resultData: ImportResultData;
    connectorData: Array<ImportDocumentTypeData>;
    availableSystemData: Array<any>;
    availableArchivData: Array<any>;
    steps: Array<any>;
    currentStep: number;
    couldImEx: boolean;
    exportItems: Array<DocumentTypeData>;
    itemsToExport: Array<DocumentTypeData>;
}

export default class ImExportHandler extends React.Component<any, state> {

    private MapLink = React.createRef<HTMLAnchorElement>();
    private ConnectorHandling = React.createRef<ImportConnectorHandling>();

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);
        this.state = {
            blob: null, allData: false, isLoading: false, hideDialog: true, hideDialogError: true, dialogMessage: "",
            viewItemMenuParam: "none", viewItemExport: "none", state: 1, task: "",
            newData: null, systemData: null, archivData: null, resultData: null, errorText: "", errorData: [], connectorError: false,
            connectorData: [], availableSystemData: [], availableArchivData: [], itemsToExport: [],
            steps: [], currentStep: 0, initLoading: true, couldImEx: false, exportItems: []
        };

        this.isExportAble()
    }
    componentDidMount() { if (this.context.key !== "key8") this.context.setKey('key8'); }

    componentDidUpdate(prevState) {
        if (this.state.blob !== null && this.state.blob !== prevState.blob) {
            setTimeout(() => {
                const href = window.URL.createObjectURL(this.state.blob);
                const a = this.MapLink.current;
                a.download = 'UniversalLink.json';
                a.href = href;
                a.click();
                a.href = '';
                setTimeout(() => { this.setState({ state: 6, blob: null }); }, 1000);
            }, 1000);
        }
    }

    public render() {

        return <>{this.state.initLoading && <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.initLoading} ></WaitingIndicator >}

            {!this.state.initLoading && <div><WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading} ></WaitingIndicator >
                <CommandBar
                    items={this.renderCommands()}
                    farItems={this.renderFarCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}>
                        Import / Export Wizard
                    </Label>
                    <div style={{ width: "100%", height: "100%" }}>
                        {!this.state.couldImEx && <>
                            <TextField
                                disabled borderless multiline rows={3} resizable={false}
                                style={{ backgroundColor: "white", padding: "0px", color: "rgb(50, 49, 48)" }}
                                defaultValue={"Für die Verwendung des Import-Export-Wizards müssen mindestens ein System und ein DMS konfiguriert sein!"}
                            />
                        </>}
                        {this.state.couldImEx && <>
                            <div style={{ float: "left", width: "250px", height: "100%", marginBlockStart: "50px" }}>
                                <Steps current={this.state.currentStep} direction="vertical">
                                    {this.state.steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <div className="steps-action" style={{ marginBlockStart: "40px", maxWidth: '250px', paddingRight: "20px" }}>
                                    <div style={{ float: "left", width: "50%" }}>
                                        {this.state.currentStep > 0 && this.state.currentStep < 2 && (
                                            <DefaultButton text="Zurück" onClick={() => this.setState({ currentStep: this.state.currentStep - 1 })}
                                                style={{ width: "110px" }} />
                                        )}
                                        {this.state.currentStep === 2 && this.state.task !== "Export" && (
                                            <DefaultButton text="Zurück" onClick={() => this.setState({ currentStep: this.state.currentStep - 1 })}
                                                style={{ width: "110px" }} />
                                        )}
                                        {this.state.currentStep === 0 && (
                                            <>&nbsp;</>
                                        )}
                                        {this.state.currentStep >= 2 && this.state.task === "Export" && (
                                            <>&nbsp;</>
                                        )}
                                    </div>
                                    <div style={{ float: "left", width: "50%", display: "flex", justifyContent: "flex-end" }}>
                                        {this.state.currentStep === 1 && this.state.task === "Export" && (
                                            <PrimaryButton text="Exportieren" onClick={this.exportButtonClick}
                                                style={{ width: "110px" }} disabled={this.state.viewItemExport !== "block"} />
                                        )}

                                        {this.state.currentStep === 2 && this.state.task === "Export" && (
                                            <PrimaryButton text="Neustart" onClick={this.startAgain} style={{ width: "110px" }} />
                                        )}

                                        {this.state.currentStep === 2 && this.state.task !== "Export" && (
                                            <PrimaryButton text="Importieren" onClick={this.uploadData}
                                                style={{ width: "110px" }} disabled={this.state.newData?.Connectors.filter(x => !x.IsMapped).length > 0} />
                                        )}

                                        {this.state.currentStep === 3 && this.state.task !== "Export" && (
                                            <PrimaryButton text="Neustart" onClick={this.startAgain} style={{ width: "110px" }} />
                                        )}

                                        {this.state.currentStep < this.state.steps.length && this.state.currentStep === 0 && (
                                            <PrimaryButton text="Weiter" onClick={this.nextStep} style={{ width: "110px" }} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div style={{ float: "left", width: "calc(100% - 250px)" }}>
                                <Stack tokens={{ childrenGap: 20 }} >
                                    {this.state.currentStep === 0 && (<>
                                        <ChoiceGroup
                                            className="defaultChoiceGroup"
                                            defaultSelectedKey={this.state.task !== "Export" ? "true" : "false"}
                                            options={[
                                                {
                                                    key: 'true',
                                                    text: 'Import'
                                                } as IChoiceGroupOption,
                                                {
                                                    key: 'false',
                                                    text: 'Export'
                                                }
                                            ]}
                                            onChange={this.TaskChange}
                                            label="Mit welcher Aufgabe wollen Sie fortfahren?" />
                                    </>)}
                                    {this.state.currentStep === 1 && this.state.task === "Export" && (<>
                                        <M365DetailList
                                            heightHeader={190}
                                            items={this.state.exportItems}
                                            columns={this.setExportColumns()}
                                            modalSelection={SelectionMode.multiple}
                                            onSelectedItemEvent={this.onSelectExportItemClick}
                                        />
                                    </>)}
                                    {this.state.currentStep === 2 && this.state.task === "Export" && (<>
                                        <Label>Der Wizard ist abgeschlossen!</Label>
                                        <a ref={this.MapLink} />
                                    </>)}
                                    {this.state.currentStep === 1 && this.state.task !== "Export" && (<>
                                        <TextField
                                            disabled borderless
                                            style={{ backgroundColor: "white", padding: "0px", color: "rgb(50, 49, 48)" }}
                                            defaultValue={"Bitte laden sie eine zuvor exportierte JSON Datei hoch."}
                                        />
                                        <Dropzone handleFileAdded={this.fileEvent} />
                                        <Label style={{ color: "red", display: this.state.errorText === "" ? "none" : "block" }}>{this.state.errorText}</Label>
                                    </>)}
                                    {this.state.currentStep === 2 && this.state.task !== "Export" && (<>
                                        <TextField
                                            disabled borderless multiline rows={2} resizable={false}
                                            style={{ backgroundColor: "white", padding: "0px", color: "rgb(50, 49, 48)" }}
                                            defaultValue={"Die folgenden Zuordnungen werden importiert. \nBitte stellen sie sicher, dass jede Zuordnung gültige Systeme bzw. DMS enthält."}
                                        />
                                        <M365DetailList
                                            heightHeader={250}
                                            items={this.state.newData?.Connectors}
                                            columns={this.setConnectorColumns()}
                                            modalSelection={SelectionMode.multiple}
                                            onSelectEvent={this.onItemClick}
                                            onSelectedItemEvent={this.onConnectorSelectedItemParamClick}
                                        />
                                    </>)}
                                    {this.state.currentStep === 3 && this.state.task !== "Export" && (<>
                                        <Label>Der Wizard ist abgeschlossen!</Label>
                                    </>)}
                                </Stack>
                            </div>
                        </>}
                    </div>
                </div>

                <ImportConnectorHandling Systems={this.state.availableSystemData}
                    Archive={this.state.availableArchivData} waitingEvent={this.waitingChanged}
                    saveEvent={this.saveConnectorEvent} ref={this.ConnectorHandling} />

                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={() => this.setState({ hideDialog: true })}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Löschen bestätigen",
                        subText: this.state.dialogMessage
                    }}
                    modalProps={{ isBlocking: true }} >
                    <DialogFooter>
                        <DefaultButton onClick={() => this.setState({ hideDialog: true })} text="Abbrechen" primaryDisabled={false} />
                        <PrimaryButton onClick={this.deleteConfirmed} text="Löschen" />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    maxWidth="80%"
                    minWidth="60%"
                    hidden={this.state.hideDialogError}
                    onDismiss={() => this.setState({ hideDialogError: true })}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Fehlermeldungen"
                    }}
                    modalProps={{ isBlocking: true }} >
                    {this.state.errorData.map((x, index) =>
                        <div key={"div_" + index.toString()}><p key={"p_" + index.toString()}>{x.Name}</p>
                            <ul key={"ul_" + index.toString()}>{x.Values.map((y, index) =>
                                <li key={"li_" + index.toString()}>{y}</li>
                            )}</ul>
                        </div>)}
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.setState({ hideDialogError: true })} text="Ok" />
                    </DialogFooter>
                </Dialog>
            </div>}
        </>
    }

    private startAgain = (): void => {
        this.setState({
            blob: null, allData: false, isLoading: false, hideDialog: true, hideDialogError: true, dialogMessage: "",
            viewItemMenuParam: "none", viewItemExport: "none", state: 1, task: "",
            newData: null, systemData: null, archivData: null, resultData: null, errorText: "", errorData: [], connectorError: false,
            connectorData: [], availableSystemData: [], availableArchivData: [], itemsToExport: [],
            steps: this.setSteps(true, true), currentStep: 0, initLoading: false, couldImEx: true, exportItems: []
        });
    }

    private waitingChanged = (isWaiting: boolean): void => {
        this.setState({ isLoading: isWaiting });
    }

    //#region Export

    private isExportAble = (): void => {
        ModusRequests.Get<boolean>("Export/IsExportAble")
            .then((result: boolean) => {
                this.setState({ initLoading: false, couldImEx: result, steps: this.setSteps(true, result) });
            });
    }

    private getExportData = (): void => {
        ModusRequests.GetData<DocumentTypeData>("Mapping/Get?Id=0",)
            .then((data: Array<DocumentTypeData>) => {
                this.setState({
                    exportItems: data.filter(x => !x.mappingError && x.isOnline)
                        .sort((a, b) => a.documentTypeName.localeCompare(b.documentTypeName)), isLoading: false
                });
            });
    }

    private setExportColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'Systemname', name: 'Systemname', fieldName: 'systemName', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true)
            }, {
                key: 'Archivname', name: 'DMS-Name', fieldName: 'archiveSystemName', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true)
            }, {
                key: 'Dokumenttyp', name: 'Dokumenttyp', fieldName: 'documentTypeName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string', isSorted: true,
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true)
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10
            }
        ];
        return columns;
    }

    private onSelectExportItemClick = (pData: Array<DocumentTypeData>): void => {
        if (pData.length === 0)
            this.setState({ viewItemExport: "none", itemsToExport: [] });
        else
            this.setState({ viewItemExport: "block", itemsToExport: pData });
    }

    private exportButtonClick = (): void => {
        this.setState({ currentStep: this.state.currentStep + 1, isLoading: true });
        ModusRequests.GetJson("Export/Export", JSON.stringify(this.getExportIds()))
            .then(blob => {
                this.setState({ isLoading: false, blob: blob });
            });
    }

    private getExportIds = (): Array<number> => {
        return this.state.itemsToExport.map(a => a.id);
    }

    //#endregion Export

    //#region Import

    //#endregion Import

    private fileEvent = (file: File): void => {
        this.setState({ isLoading: true });
        var reader = new FileReader();
        reader.onload = (e): void => {
            var contents = JSON.parse(e.target.result.toString());
            if (contents.hasOwnProperty('Archive') || contents.hasOwnProperty('Connectors') || contents.hasOwnProperty('Systems') || contents.hasOwnProperty('Results')) {
                contents.Systems = [];
                contents.Connectors.map(y => y.SystemId = 0);
                contents.Archive = [];
                contents.Connectors.map(y => y.ArchiveSystemId = 0);
                this.getData(contents);

            } else
                this.setState({
                    errorText: "Die JSON-Datei enhält keine importierbaren Daten!"
                });
        };
        reader.readAsText(file);
    }

    private nextStep = (): void => {

        if ((this.state.currentStep === 0 || this.state.currentStep > 4) && this.state.task !== "Export") {
            this.setState({ currentStep: this.state.currentStep + 1, errorData: [], errorText: "" });
        } else if (this.state.currentStep === 0 && this.state.task === "Export") {
            this.setState({ currentStep: this.state.currentStep + 1, isLoading: true });
            this.getExportData();
        }
    }

    private getData = (p_Data: ImportData): void => {
        ModusRequests.Get<any>("ImportConnector/Get")
            .then((data: any) => {
                (data.Systems as Array<any>).forEach(system => {
                    p_Data.Connectors.filter(x => x.SystemName === system.Name && x.SystemId === 0).map(y => y.SystemId = system.ID);
                });
                (data.Archive as Array<any>).forEach(archiv => {
                    p_Data.Connectors.filter(x => x.ArchiveSystemName === archiv.Name && x.ArchiveSystemId === 0).map(y => y.ArchiveSystemId = archiv.ID);
                });
                p_Data.Connectors.filter(x => x.ArchiveSystemId === 0).map(y => y.ArchiveSystemName = "");
                p_Data.Connectors.filter(x => x.SystemId === 0).map(y => y.SystemName = "");
                this.setState({
                    newData: JSON.parse(JSON.stringify(p_Data)),
                    availableSystemData: data.Systems.map(x => ({ key: x.ID, text: x.Name })),
                    availableArchivData: data.Archive.map(x => ({ key: x.ID, text: x.Name })),
                    connectorError: p_Data.Connectors.filter(x => x.SystemId === 0 || x.ArchiveSystemId === 0).length > 0,
                    isLoading: false, errorText: "", currentStep: this.state.currentStep + 1
                });
            });
    }

    private uploadData = (): void => {
        this.setState({ isLoading: true });
        this.uploadConnectorData();
    }

    private TaskChange = (ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption): void => {
        this.setState({ task: option.text, steps: this.setSteps(option.text === "Import", this.state.couldImEx) });
    };

    private renderCommands = (): Array<ICommandBarItemProps> => {
        if (!this.state.couldImEx)
            return [];
        return [
            {
                key: 'editItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.connectorData.length === 1 ? "block" : "none", backgroundColor: "transparent" },
                iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
                onClick: () => this.editItemClick()
            }, {
                key: 'deleteItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
                onClick: () => this.deleteItemClick()
            }, {
                key: 'dividerError',
                onRender: () => <div style={{ display: this.state.errorData.length === 0 ? "none" : "block", margin: '5px', width: '1px', background: '#ddd' }} > </div>
            }, {
                key: 'showError', text: 'Fehler anzeigen', ariaLabel: 'Show Errors', style: { display: this.state.errorData.length === 0 ? "none" : "block", backgroundColor: "transparent" },
                iconProps: { iconName: 'Error', style: { fontSize: "15px", color: 'salmon' } },
                onClick: () => {
                    this.setState({ hideDialogError: false });
                }
            }
        ];
    }

    private renderFarCommands = (): Array<ICommandBarItemProps> => {
        return [];
    }

    private onItemClick = (): void => {
        if (this.state.connectorData.length === 1)
            this.editItemClick();
    }

    private editItemClick = (): void => {
        if (this.state.currentStep === 2)
            this.ConnectorHandling.current.updateData(JSON.parse(JSON.stringify(this.state.connectorData[0])));
    }

    private deleteItemClick = (): void => {
        if (this.state.currentStep === 2) {
            if (this.state.connectorData.length === 1)
                this.setState({ hideDialog: false, allData: false, dialogMessage: `Wollen Sie die Zuordnung ${this.state.connectorData[0].DocumentTypeName} löschen?` });
            else if (this.state.connectorData.length === this.state.newData.Connectors.length)
                this.setState({ hideDialog: false, dialogMessage: `Wollen Sie alle Zuordnungen löschen?`, allData: true });
            else {
                this.setState({ hideDialog: false, allData: false, dialogMessage: `Wollen Sie die ausgewählten Zuordnungen löschen?` });
            }
        }
    }

    private deleteConfirmed = (): void => {
        if (this.state.currentStep === 2)
            this.deleteConnector();
    }

    private setConnectorColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'IsMapped', name: '', fieldName: '', minWidth: 25, maxWidth: 25, isResizable: false, data: 'boolean',
                onRender: (item: ImportDocumentTypeData) => {
                    return (!item.IsMapped ?
                        <FontIcon iconName="Error" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} /> :
                        <></>)
                }
            }, {
                key: 'SystemName', name: 'Systemname', fieldName: 'SystemName', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string'
            }, {
                key: 'ArchiveSystemName', name: 'DMS', fieldName: 'ArchiveSystemName', minWidth: 150, maxWidth: 300, isResizable: true, data: 'string'
            }, {
                key: 'DocumentTypeName', name: 'Dokumenttyp', fieldName: 'DocumentTypeName', minWidth: 150, maxWidth: 300, isResizable: true, data: 'string'
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10
            }
        ];

        return columns;
    }

    private onConnectorSelectedItemParamClick = (pData: Array<ImportDocumentTypeData>): void => {
        if (pData.length === 0)
            this.setState({ connectorData: [], viewItemMenuParam: "none" });
        else
            this.setState({ connectorData: pData, viewItemMenuParam: "block" });
    }

    private saveConnectorEvent = (data: ImportDocumentTypeData): void => {
        if (data === null)
            this.ConnectorHandling.current.closeDialog();
        else {
            if (this.state.errorData.length > 0) {
                let newErrorData = this.state.errorData.filter(x => x.Name !== data.DocumentTypeName);
                this.setState({ errorData: newErrorData });
            }
            let newData = this.state.newData;
            newData.Connectors.filter(x => x.Id === data.Id).map(y => Object.assign(y, {
                DocumentTypeName: data.DocumentTypeName,
                ArchiveSystemId: data.ArchiveSystemId,
                ArchiveSystemName: data.ArchiveSystemName,
                SystemId: data.SystemId,
                SystemName: data.SystemName,
                IsOnline: data.IsOnline,
                StartUrl: data.StartUrl,
                IsMapped: true,
                ListLibrary: data.ListLibrary,
                ListLibraryName: data.ListLibraryName,
                ContentTypeId: data.ContentTypeId,
                ContentTypeName: data.ContentTypeName,
                DocumentLibrary: data.DocumentLibrary,
                DocumentLibraryName: data.DocumentLibraryName
            }));
            this.setState({ newData: JSON.parse(JSON.stringify(newData)), connectorError: newData.Connectors.filter(x => x.SystemId === 0 || x.ArchiveSystemId === 0).length > 0 });
            this.ConnectorHandling.current.closeDialog();
        }
    }

    private deleteConnector = (): void => {
        let newData = this.state.newData;
        if (this.state.allData) {
            newData.Connectors = [];
            newData.Results = [];
            this.setState({
                hideDialog: true, connectorError: false, connectorData: [], viewItemMenuParam: "none",
                resultData: null, currentStep: this.state.currentStep + 1
            });
        }
        else {
            this.setState({ hideDialog: true, isLoading: true });
            this.state.connectorData.forEach((item: ImportDocumentTypeData) => this.deleteSelectedConnector(item));
            this.setState({ connectorData: [], isLoading: false, viewItemMenuParam: "none" });
        }
    }

    private deleteSelectedConnector = (connectorData: ImportDocumentTypeData): void => {
        let newData = this.state.newData;
        let connectors = this.state.newData.Connectors.filter((item: ImportDocumentTypeData) => item.Id !== connectorData.Id);
        newData.Connectors = connectors;
        let results = this.state.newData.Results.filter((result: ImportResultData) => result.DocumentTypeId !== connectorData.Id);
        newData.Results = results;
        if (this.state.errorData.length > 0) {
            let newErrorData = this.state.errorData.filter(x => x.Name !== connectorData.DocumentTypeName);
            this.setState({ errorData: newErrorData });
        }
        this.setState({
            newData: JSON.parse(JSON.stringify(newData)),
            connectorError: newData.Connectors.filter(x => x.SystemId === 0 || x.ArchiveSystemId === 0).length > 0,
            resultData: null
        });
    }

    private uploadConnectorData = (): void => {
        let data = this.state.newData.Connectors;
        let resultData = this.state.newData.Results;
        ModusRequests.Export<any>("ImportConnector/Post", JSON.stringify(
            {
                ConnectorModel: data,
                ResultModel: resultData
            }))
            .then(() => {
                this.setState({ currentStep: this.state.currentStep + 1, isLoading: false, allData: false });
            },
                (error) => {
                    this.setState({ hideDialogError: false, errorData: error, isLoading: false });
                })
            .catch(error => {
            });
    }

    private setSteps = (i: boolean, couldImEx: boolean): Array<any> => {
        let result = [
            { title: 'Aufgabe' }
        ];

        if (!couldImEx)
            return result;

        if (i) {
            result.push({ title: 'Importieren' },
                /* { title: 'Systeme' }, { title: 'DMS' },*/
                { title: 'Zuordnungen' });
        } else {
            result.push({ title: 'Exportieren' });
        }

        return result;
    }
}
