import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { DefaultButton, PrimaryButton, FontIcon, CommandBar, ICommandBarItemProps, SelectionMode } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';

import DocumentTypeData from '../../classes/models/DocumentTypeData';
import WaitingIndicator from '../../customComponents/WaitingIndicator';
import M365DetailList from '../../customComponents/M365DetailList';

import Modus from '../../classes/CustomColumn';
import { ctxt } from '../../config/AppContext';
import ModusRequests from '../../classes/RequestHandler';

export default class Mappings extends React.Component<any, any> {

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);

        let archivFilter = [];
        if (this.props.location.state !== undefined && this.props.location.state.Archiv !== undefined)
            archivFilter = [{ value: this.props.location.state.Archiv, selected: true }];

        let systemFilter = [];
        if (this.props.location.state !== undefined && this.props.location.state.System !== undefined)
            systemFilter = [{ value: this.props.location.state.System, selected: true }];

        let docFilter = [];
        if (this.props.location.state !== undefined && this.props.location.state.Document !== undefined)
            docFilter = [{ value: this.props.location.state.Document, selected: true }];

        this.state = {
            systemFilter: systemFilter, archivFilter: archivFilter, docFilter: docFilter,
            items: [], loading: true,
            hideDialog: true, dialogMessage: "", selectedItem: new DocumentTypeData(), viewItemMenuParam: "none"
        };
        this.getMappingData(true);
    }

    public render(): JSX.Element {

        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.loading}></WaitingIndicator >
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}> Verfügbare Zuordnungen </Label>
                    <M365DetailList
                        heightHeader={190}
                        items={this.state.items}
                        columns={this.setColumns()}
                        modalSelection={SelectionMode.single}
                        onSelectEvent={this.onItemClick}
                        onSelectedItemEvent={this.onSelectedItemParamClick}
                    />
                </div>

                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={this.closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Löschen bestätigen",
                        subText: this.state.dialogMessage
                    }}
                    modalProps={{
                        isBlocking: true
                    }}
                >
                    <DialogFooter>
                        <DefaultButton className="ms-Cancel-Button" onClick={this.closeDialog} text="Abbrechen" primaryDisabled={false} />
                        <PrimaryButton className="ms-Default-Button2" onClick={this.deleteConfirmed} text="Löschen" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private deleteConfirmed = (): void => {
        this.setState({ loading: true, hideDialog: true });
        ModusRequests.Post<any>("DocumentType/Delete", JSON.stringify(this.state.itemToDelete))
            .then((data: any) => {
                this.getMappingData(false);
            });
    }

    private onItemClick = (pData: DocumentTypeData): void => {
        this.editClick(pData)
    }

    private onSelectedItemParamClick = (pData: Array<DocumentTypeData>): void => {
        if (pData.length === 0) {
            this.setState({ viewItemMenuParam: "none" });
        } else {

            this.setState({ selectedItem: pData[0], viewItemMenuParam: "block" });
        }
    }

    private showDialog = (item: DocumentTypeData): void => {
        this.setState({ hideDialog: false, dialogMessage: `Wollen Sie die Zuordnung wirklich löschen?`, itemToDelete: item });
    }

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    }

    private getMappingData = (init: boolean): void => {
        ModusRequests.GetData<DocumentTypeData>("Mapping/Get?Id=0",)
            .then((data: Array<DocumentTypeData>) => {
                if (init)
                    this.setState({ items: data.sort((a, b) => a.documentTypeName.localeCompare(b.documentTypeName)), loading: false });
                else {
                    this.setState({ items: data, loading: false });
                }
                this.context.setKey('key4');
            });
    }

    private editClick = (item: DocumentTypeData): void => {
        this.props.history.push({
            pathname: '/mappingHandling',
            state: { data: item }
        })
    }

    private deleteClick = (item: DocumentTypeData): void => {
        this.showDialog(item);
    }

    private setColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'Systemname', name: 'Systemname', fieldName: 'systemName', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string', isFiltered: this.state.systemFilter.length > 0,
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true, this.state.systemFilter)
            }, {
                key: 'Archivname', name: 'DMS-Name', fieldName: 'archiveSystemName', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string', isFiltered: this.state.archivFilter.length > 0,
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true, this.state.archivFilter)
            }, {
                key: 'Dokumenttyp', name: 'Dokumenttyp', fieldName: 'documentTypeName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string', isSorted: true,
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true, this.state.docFilter)
            }, {
                key: 'IsOnline', name: 'Aktiv', fieldName: 'isOnline', minWidth: 50, maxWidth: 50, isResizable: false, data: 'boolean',
                onRender: (item: DocumentTypeData) => {
                    return (item.isOnline ?
                        <FontIcon iconName="completed" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'green' }} /> :
                        <FontIcon iconName="ErrorBadge" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} />)
                },
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.booleanFilter, true, true, [], [{ key: true, text: 'Aktiv' }, { key: false, text: 'Inaktiv' }])
            }, {
                key: 'Error', name: '', minWidth: 20, maxWidth: 20, isResizable: false,
                onRender: (item: DocumentTypeData) => {
                    return <div>
                        <FontIcon iconName="Error"
                            style={{ fontSize: '12px', color: 'salmon', display: item.mappingError ? 'block' : 'none' }} />
                    </div>;
                }
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10
            }
        ];
        return columns;
    }

    private renderCommands = (): ICommandBarItemProps[] => {
        return [
            {
                key: 'editItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
                onClick: () => this.editClick(this.state.selectedItem)
            }, {
                key: 'deleteItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
                onClick: () => this.deleteClick(this.state.selectedItem)
            }]
    }
}