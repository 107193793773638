/// <reference path="../../interfaces/models/IProtocolData.ts"/>

class ProtocolData {
    public id?: number = 0;
    public fileName?: string = "";
    public fileIcon?: string = "";
    public sourceSystemName?: string = "";
    public destinationSystemName?: string = "";
    public documentType?: string = "";
    public folder?: string = "";
    public timeStamp?: Date = new Date();
    public displayDate?: string = "";
    public status?: boolean = false;
    public errorMessage?: string = "";

    constructor() { }
}

export default ProtocolData;