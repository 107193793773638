/// <reference path="../../interfaces/models/ISecurityData.ts"/>

class SecurityData {
    public id?: number = 0;
    public userAccount: string = "";
    public permissionLevel: string = "";
    public isActive: boolean = true;

    constructor() { }
}

export default SecurityData;
