import * as React from 'react';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DroppableProvided,
    DraggableLocation,
    DropResult,
    DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot
} from 'react-beautiful-dnd';
import { FontIcon, Icon } from '@fluentui/react';
import MappingData from '../../classes/models/MappingData';

interface Props {
    items: Array<MappingData>;
    orderedEvent: (items: Array<MappingData>) => void;
    selectItem: (data: MappingData) => void
}

interface IAppState {
    items: Array<MappingData>;
}

interface IMoveResult {
    droppable: Array<MappingData>;
}

export default class M365SingleDragDrop extends React.Component<Props, any> {

    private id2List = {
        droppable: 'items'
    };

    private grid = 8;

    constructor(props: any) {
        super(props);
    }

    public render(): JSX.Element {
        return (<>
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px', width: "100%", minWidth: '600px', float: "left" }}>
                    <Droppable droppableId="droppable">
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={this.getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.props.items.filter(x => x.useAsFolder).map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.name} index={index}>
                                        {(providedDraggable: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
                                            <div>
                                                <div
                                                    ref={providedDraggable.innerRef}
                                                    {...providedDraggable.draggableProps}
                                                    {...providedDraggable.dragHandleProps}
                                                    style={this.getItemStyle(
                                                        providedDraggable.draggableProps.style,
                                                        snapshotDraggable.isDragging, index,
                                                        item.isSelected
                                                    )}
                                                >
                                                    <div onClick={() => this.handleClick(item)} style={{ width:"100%", display: "flex" }}>
                                                        <Icon className="m365SingleDragIcon" iconName={"DragObject"} />
                                                        <FontIcon iconName="FolderHorizontal" style={{ fontSize: '20px', color: '#F8D775' }} />
                                                        <div style={{ lineHeight: 2, display: "flex" }}>&nbsp; &nbsp;{item.name}&nbsp; &nbsp;|
                                                            &nbsp; &nbsp;{this.replacePlaceholder(item)}
                                                            <div style={{display: item.hasError ? 'flex' : 'none' }}> &nbsp; &nbsp;|&nbsp; &nbsp;
                                                                <FontIcon iconName='Error' style={{paddingTop: "4px", fontSize: '12px', color: 'salmon', display: 'block' }} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {this.props.items.filter(x => x.useAsFolder).length === 0 && <>Es wurden keine Ordner konfiguriert</>}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </>);
    }

    private handleClick = (data: MappingData) => {
        this.props.selectItem(data);
    }

    private replacePlaceholder = (p_Data: MappingData): string => {
        let data: MappingData = JSON.parse(JSON.stringify(p_Data));
        do {
            data.mappingValue = this.replaceMentionText(data.mappingValue);
        } while (data.mappingValue.indexOf("@[{") >= 0);
        if (data.isFormula)
            return this.getPreview(data);
        return data.mappingValue;
    }

    private getPreview = (data: MappingData): string => {
        let param = data.mappingValue;
        let type = data.formulaType === 1 ? "TEXT(" + param : "LINKS(" + param;
        type += this.getFormat(data);
        return this.getTextFormat(type, data);
    }

    private getTextFormat = (pText: string, data: MappingData): string => {
        if (data.formulaType === 1)
            return pText;
        if (data.formulaType === 2 && data.formatting <= 1)
            return pText;
        pText = data.formatting === 2 ? "GROSS(" + pText + ")" : "KLEIN(" + pText + ")";
        return pText;
    }

    private getFormat = (data: MappingData): string => {
        let format = ";";
        if (data.formulaType === 1) {
            switch (data.formatting) {
                case 1:
                    format += "M";
                    break;
                case 2:
                    format += "MM";
                    break;
                case 3:
                    format += "JJ";
                    break;
                case 4:
                    format += "JJJJ";
                    break;
                default:
            }
        } else
            format += data.length > 0 ? data.length.toString() : "1";
        format += ")";
        return format;
    }

    private replaceMentionText = (text: string): string => {
        let startIndex = text.indexOf("@[{");
        if (startIndex < 0)
            return text;
        startIndex += 3;
        let stopIndex = text.indexOf("}](");
        let mentionText = text.substring(startIndex, stopIndex);
        let mention = "@[{" + mentionText + "}](" + mentionText + ")";
        return text.replace(mention, "{" + mentionText + "}");
    }

    private getItemStyle = (draggableStyle: any, isDragging: boolean, index: number, selected: boolean): {} => ({
        userSelect: 'none',
        borderTop: index === 0 ? !isDragging ? 0 : `1px solid #ddd` : `1px solid #ddd`,
        borderBottom: !isDragging ? 0 : `1px solid #ddd`,
        padding: 5,
        display: 'flex',
        background: selected ? `#f3f2f1` : `white`,
        color: isDragging ? `rgba(0, 0, 0, 0.35)` : `rgba(0, 0, 0, 0.85)`,
        ...draggableStyle
    });

    private getListStyle = (isDraggingOver: boolean): {} => ({
        padding: this.grid,
        minHeight: 400
    });

    private onDragEnd = (result: DropResult): void => {
        let { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            let items = this.reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            let state: IAppState = { ...this.props };

            if (source.droppableId === "droppable") {
                state = { ...this.props, items }
            }

            this.props.orderedEvent(state.items);


        } else {
            let resultFromMove: IMoveResult = this.move(
                this.getList(source.droppableId),
                source
            );
            this.props.orderedEvent(
                resultFromMove.droppable
            );

        }
    };

    private move = (source: Array<MappingData>, droppableSource: DraggableLocation): IMoveResult | any => {
        let sourceClone = [...source];
        let result = {};
        result[droppableSource.droppableId] = sourceClone;

        return result;
    };

    private getList = (id: string): Array<MappingData> => {
        return this.props[this.id2List[id]];
    }

    private reorder = (list: Array<MappingData>, startIndex: number, endIndex: number): Array<MappingData> => {
        let result = [...list];
        let [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
}

