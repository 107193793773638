import * as React from "react";
import { Steps } from "antd";
import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import { ConfigurationErrorModel, ConfigurationModel } from "./WizardModel";
import ModusProgressIndicator from "../../standard/ModusProgressIndicator";
import ModusRequests from "../../classes/RequestHandler";

import "../../css/antd.css";

const { Step } = Steps;

interface Props {
  isSqlConnection: boolean;
}

interface State {
  steps: Array<any>;
  currentStep: number;
  configuration: ConfigurationModel;
}

export default class Wizard extends React.Component<Props, State> {
  private process = React.createRef<ModusProgressIndicator>();

  constructor(props: any) {
    super(props);
    this.state = {
      steps: this.setSteps(true),
      currentStep: 0,
      configuration: new ConfigurationModel(),
    };
  }

  public render() {
    return (
      <>
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <ModusProgressIndicator
            ref={this.process}
            finishEvent={this.processFinished}
          />
          <Label
            style={{
              paddingTop: "30px",
              paddingBottom: "15px",
              fontSize: "18px",
            }}
          >
            Setup Wizard
          </Label>
          <div style={{ width: "100%", height: "500px" }}>
            <div
              style={{
                float: "left",
                width: "250px",
                height: "100%",
                marginBlockStart: "50px",
              }}
            >
              <Steps current={this.state.currentStep} direction="vertical">
                {this.state.steps.map((item) => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
            </div>
            <div style={{ float: "left", width: "calc(100% - 250px)" }}>
              <Stack tokens={{ childrenGap: 20 }}>
                {this.state.configuration.authError !== undefined &&
                  this.state.configuration.authError.length > 0 && (
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      styles={{ root: { maxWidth: "420px" } }}
                    >
                      {this.state.configuration.authError}
                    </MessageBar>
                  )}
                {this.state.currentStep === 0 && (
                  <ChoiceGroup
                    className="defaultChoiceGroup"
                    defaultSelectedKey={
                      this.state.configuration.authProvider ? "true" : "false"
                    }
                    options={[
                      { key: "true", text: "Azure" } as IChoiceGroupOption,
                      { key: "false", text: "Windows" },
                    ]}
                    onChange={this.onChoiceGroupChange}
                    label="Authentifizierunganbieter"
                  />
                )}
                {this.state.currentStep === this.state.steps.length - 1 &&
                  this.props.isSqlConnection && (
                    <>
                      <TextField
                        label="SQL-Server"
                        required={true}
                        errorMessage={this.state.configuration.sqlServerError}
                        validateOnLoad={false}
                        value={this.state.configuration.sqlServer}
                        onChange={(ev) => this.propChanged(ev, "sqlServer")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <TextField
                        label="Datenbankname"
                        required={true}
                        errorMessage={this.state.configuration.sqlDatabaseError}
                        validateOnLoad={false}
                        value={this.state.configuration.sqlDatabase}
                        onChange={(ev) => this.propChanged(ev, "sqlDatabase")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <TextField
                        label="SQL-Benutzer"
                        required={true}
                        errorMessage={this.state.configuration.sqlUserError}
                        validateOnLoad={false}
                        value={this.state.configuration.sqlUser}
                        onChange={(ev) => this.propChanged(ev, "sqlUser")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <TextField
                        label="SQL-Passwort"
                        required={true}
                        type="password"
                        canRevealPassword={true}
                        errorMessage={this.state.configuration.sqlSecretError}
                        validateOnLoad={false}
                        value={this.state.configuration.sqlSecret}
                        onChange={(ev) => this.propChanged(ev, "sqlSecret")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <ChoiceGroup
                        defaultSelectedKey={
                          this.state.configuration.rebuildDatabase
                            ? "true"
                            : "false"
                        }
                        options={[
                          {
                            key: "true",
                            text: "Neue Datebank einrichten",
                          } as IChoiceGroupOption,
                          { key: "false", text: "Datenbank existiert bereits" },
                        ]}
                        onChange={this.onRebuildOptionChanged}
                        label="Einrichtungsoption"
                      />
                    </>
                  )}
                {this.state.currentStep === 1 &&
                  this.state.steps.length > 2 &&
                  this.props.isSqlConnection && (
                    <>
                      <TextField
                        label="Mandant-ID"
                        required={true}
                        errorMessage={this.state.configuration.tenantIdError}
                        validateOnLoad={false}
                        value={this.state.configuration.tenantId}
                        onChange={(ev) => this.propChanged(ev, "tenantId")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <TextField
                        label="Client-ID"
                        required={true}
                        errorMessage={this.state.configuration.clientIdError}
                        validateOnLoad={false}
                        value={this.state.configuration.clientId}
                        onChange={(ev) => this.propChanged(ev, "clientId")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                      <TextField
                        label="Anwendungs-URI"
                        required={true}
                        errorMessage={this.state.configuration.domainError}
                        validateOnLoad={false}
                        value={this.state.configuration.domain}
                        onChange={(ev) => this.propChanged(ev, "domain")}
                        styles={{ root: { maxWidth: "420px" } }}
                      />
                    </>
                  )}
              </Stack>
              <div
                className="steps-action"
                style={{ marginBlockStart: "40px", maxWidth: "420px" }}
              >
                <div style={{ float: "left", width: "50%" }}>
                  {this.state.currentStep > 0 && (
                    <DefaultButton
                      text="Zurück"
                      onClick={() =>
                        this.setState({
                          currentStep: this.state.currentStep - 1,
                        })
                      }
                      style={{ width: "110px" }}
                    />
                  )}
                  {this.state.currentStep === 0 && <>&nbsp;</>}
                </div>
                <div
                  style={{
                    float: "left",
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {this.state.currentStep < this.state.steps.length - 1 && (
                    <PrimaryButton
                      text="Weiter"
                      onClick={() =>
                        this.setState({
                          currentStep: this.state.currentStep + 1,
                        })
                      }
                      style={{ width: "110px" }}
                    />
                  )}
                  {this.state.currentStep === this.state.steps.length - 1 && (
                    <PrimaryButton
                      text="Speichern"
                      onClick={this.saveConfig}
                      style={{ width: "110px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private processFinished = (): void => {
    window.location.reload();
  };

  private saveConfig = (): void => {
    this.clearErrorMessages();
    this.process.current.startProcess();
    fetch(`${this.GetBaseUrl()}api/Wizard/Configure`, {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(this.state.configuration),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((data: ConfigurationErrorModel) => this.mapMessages(data))
      .catch((error) => {
        console.error(error);
      });
  };

  private callApi = (): void => {
    fetch(`${this.GetBaseUrl()}api/Authentication/AuthenticationMethode`, {
      method: "GET",
      redirect: "follow",
    });
  };

  private mapMessages = (data: ConfigurationErrorModel): void => {
    if (data.isValid) this.callApi();
    else this.mapErrorMessages(data.errors);
  };

  private mapErrorMessages = (messages: { [key: string]: string }): void => {
    this.process.current.stopProcess();
    let newData = this.state.configuration;
    Object.keys(messages).forEach((key) => {
      newData[this.capitalizeFirstLetter(key) + "Error"] = messages[key];
    });
    this.setState({
      configuration: newData,
      currentStep: newData.aadError !== "" ? 1 : this.state.currentStep,
    });
  };

  private clearErrorMessages = (): void => {
    let newData = this.state.configuration;
    Object.keys(newData).forEach((key) => {
      if (key.indexOf("Error") > 0) newData[key] = "";
    });
    this.setState({ configuration: newData });
  };

  private GetBaseUrl() {
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      return "https://localhost:44380/";
    } else {
      return "";
    }
  }

  private capitalizeFirstLetter(p_Value: string): string {
    return p_Value.charAt(0).toLocaleLowerCase() + p_Value.slice(1);
  }

  private setSteps = (aad: boolean): Array<any> => {
    let result = [{ title: "Authentifizierung" }];

    if (aad) {
      result.push({ title: "App-Registrierung" });
    }

    if (this.props.isSqlConnection) result.push({ title: "SQL-Server" });

    return result;
  };

  private onRebuildOptionChanged = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.configuration;
    newData.rebuildDatabase = option.key === "true" ? true : false;
    this.setState({ configuration: newData });
  };

  private onChoiceGroupChange = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.configuration;
    newData.authProvider = option.key === "true";
    this.setState({
      configuration: newData,
      steps: this.setSteps(option.key === "true"),
    });
  };

  private propChanged = (eventData, propName: string): void => {
    let newData = this.state.configuration;
    newData[propName] = eventData.target.value;
    this.setState({ configuration: newData });
  };
}
