import * as React from 'react';
import { TextField, IconButton, Dropdown, IDropdownOption, Stack, Label, ChoiceGroup, IChoiceGroupOption, PrimaryButton } from '@fluentui/react';
import { MentionsInput, Mention } from 'react-mentions';

import SpMappingHandlingProps from './SpMappingHandlingProps';
import defaultStyle from '../Style/MentionStyle';
import ModusRequests from '../../classes/RequestHandler';

export default class SpMappingHandling extends React.Component<SpMappingHandlingProps, any> {

    constructor(props: any) {
        super(props);
        if (!this.props.sysData.unMapped && this.props.sysData.startUrl !== null && this.props.sysData.startUrl !== "") {
            this.validateSpPage();
            this.state = { contentTypeData: [], dropdownData: [], dropdownDataSf: [], availableParams: [] };
        } else if (this.props.sysData.startUrl === null || this.props.sysData.startUrl === "")
            this.state = { contentTypeData: [], dropdownData: [], dropdownDataSf: [], isValidated: false, availableParams: [] };
        else
            this.state = { contentTypeData: [], dropdownData: [], dropdownDataSf: [], isValidated: true, availableParams: [] };
    }

    public render(): JSX.Element {
        return (<Stack tokens={{ childrenGap: 20 }}>
            {this.renderPage()}{this.renderLib()}{this.renderShareflexLib()}{this.renderContentType()}
        </Stack>);
    }

    private renderPage(): JSX.Element {
        return (<div style={{ width: "100%" }}>
            <Label required={true} style={!this.props.sysData.unMapped && this.props.sysData.mappings.length > 0 ? { color: "rgb(161, 159, 157)" } : {}}>Site-URL</Label>
            <div style={{ width: '100%' }}>
                <div style={ !this.state.isValidated ? { width: 'calc(100% - 105px)', float: 'left' } : { width: '100%', float: 'left' }}>
                    <MentionsInput
                        value={this.props.sysData.startUrl ?? ""}
                        onChange={this.onMentionChange}
                        style={this.props.inputRootUrlErrorMessage === '' ? !this.props.sysData.unMapped && this.props.sysData.mappings.length > 0 ? defaultStyle.controlDisabled : defaultStyle : defaultStyle.controlError}
                        placeholder={"Parameterliste mit '{' aufrufen"}
                        disabled={!this.props.sysData.unMapped && this.props.sysData.mappings.length > 0}>
                        <Mention
                            markup="@[__display__](__id__)"
                            trigger="{"
                            data={this.props.sysData.availableParams.map(item => { return { id: item, display: '{' + item + '}' } })}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                <div className={`${focused ? 'focused' : ''}`}>
                                    {highlightedDisplay}
                                </div>
                            )}
                            style={{ backgroundColor: '#e6e6e6', }} />
                    </MentionsInput>
                </div>
                {!this.state.isValidated &&
                    <PrimaryButton
                        onClick={this.validateSpPage}
                        text="Validieren"
                        primaryDisabled={false}
                        ariaLabel="Validieren"
                    />} 
            </div>
            <span style={{ whiteSpace: "nowrap", fontWeight: 400, paddingTop: "5px", fontSize: '12px', color: 'rgb(164, 38, 44)' }}>{this.props.inputRootUrlErrorMessage} </span>

        </div>
        );
    }

    private renderLib(): JSX.Element {
        if (!this.props.sysData.unMapped && this.props.sysData.startUrl !== null && this.props.sysData.startUrl !== "")
            return (
                <Dropdown
                    placeholder="Bitte wählen Sie eine Dokumentbibliothek aus!"
                    defaultSelectedKey={this.props.sysData.documentLibrary}
                    required={true}
                    styles={{ dropdown: { maxWidth: '100%' } }}
                    options={this.state.dropdownData}
                    errorMessage={this.props.inputRootFolderErrorMessage}
                    onChange={this.onLibChanged}
                    disabled={!this.state.isValidated || this.props.sysData.mappings.length > 0}
                    label="Dokumentbibliothek" />
            );
        else
            return (
                <TextField
                    label="Dokumentbibliothek"
                    required={true}
                    validateOnLoad={false}
                    value={this.props.sysData.documentLibrary}
                    disabled={true} />
            );
    }

    private renderShareflexLib(): JSX.Element {
        if (!this.props.sysData.isShareflex) return;
        if (!this.props.sysData.unMapped && this.props.sysData.startUrl !== null && this.props.sysData.startUrl !== "")
            return (
                <Dropdown
                    placeholder="Bitte wählen Sie eine Shareflexliste aus!"
                    defaultSelectedKey={this.props.sysData.listLibrary}
                    required={true}
                    styles={{ dropdown: { maxWidth: '100%' } }}
                    options={this.state.dropdownDataSf}
                    errorMessage={this.props.inputListNameErrorMessage}
                    onChange={this.onLibSfChanged}
                    disabled={!this.state.isValidated || this.props.sysData.mappings.length > 0}
                    label="Shareflexliste" />
            );
        else
            return (
                <TextField
                    label="Shareflexliste"
                    required={true}
                    validateOnLoad={false}
                    value={this.props.sysData.listLibrary}
                    disabled={true} />
            );
    }

    private renderContentType(): JSX.Element {
        if (!this.props.sysData.unMapped && this.props.sysData.startUrl !== null && this.props.sysData.startUrl !== "")
            return (<Stack tokens={{ childrenGap: 20 }}>
                <ChoiceGroup
                    className="defaultChoiceGroup"
                    defaultSelectedKey={this.props.sysData.useContentTypes ? 'true' : 'false'}
                    options={[
                        { key: 'true', text: 'Ja' } as IChoiceGroupOption,
                        { key: 'false', text: 'Nein' }
                    ]}
                    disabled={!this.state.isValidated || this.props.sysData.mappings.length > 0}
                    onChange={this.onContentTypeChanged}
                    label="Inhaltstypen verwenden"
                />
                {this.props.sysData.useContentTypes &&
                    <Dropdown placeholder="Bitte wählen Sie einen Inhaltstypen aus!"
                        defaultSelectedKey={this.props.sysData.contentTypeId}
                        label="Inhaltstyp"
                        options={this.state.contentTypeData}
                        styles={{ dropdown: { maxWidth: '100%' } }}
                        onChange={this.onContentTypeChange}
                        required={true}
                        errorMessage={this.props.contentTypeErrorMessage}
                        disabled={!this.state.isValidated || this.props.sysData.mappings.length > 0}
                    />}
            </Stack>
            );
        else {
            if (!this.props.sysData.useContentTypes) return;
            return (
                <TextField
                    label="Inhaltstype"
                    required={true}
                    validateOnLoad={false}
                    value={this.props.sysData.contentTypeName}
                    disabled={true} />
            );
        }
    }

    private validateSpPage = (): void => {
        this.props.clearErrorMessagesEvent();
        ModusRequests.Get<any>("Sharepoint/GetLists?DocumentTypId=" + this.props.sysData.id + "&PathToFolder=" + this.props.sysData.startUrl)
            .then((data: any) => {
                if (typeof (data) === "string") {
                    this.props.errorEvent(data);
                } else {
                    let ddData: Array<any> = [];
                    Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.key, text: pf.value }) });
                    this.setState({ dropdownData: ddData, isValidated: true });
                    if (this.props.sysData.documentLibrary === "" && !this.props.sysData.isShareflex)
                        this.props.errorEvent("");
                }
            });

        if (this.props.sysData.documentLibrary !== "")
            this.getContentTypes(this.props.sysData.documentLibraryName);

        if (this.props.sysData.isShareflex)
            ModusRequests.Get<any>("Sharepoint/GetSfLists?DocumentTypId=" + this.props.sysData.id + "&PathToFolder=" + this.props.sysData.startUrl)
                .then((data: any) => {
                    if (typeof (data) === "string") {
                        this.props.errorEvent(data);
                    } else {
                        let ddData: Array<any> = [];
                        Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.key, text: pf.value }) });
                        this.setState({ dropdownDataSf: ddData });
                        this.props.errorEvent("");
                    }
                });
    }

    private getContentTypes = (pFolder: string): void => {
        ModusRequests.Get<any>("Sharepoint/GetContentTypes?DocumentTypId=" + this.props.sysData.id + "&PathToFolder=" + this.props.sysData.startUrl + '&LibaryName=' + pFolder)
            .then((data: any) => {
                if (typeof (data) === "string") {
                    this.props.errorEvent(data);
                } else {
                    let ddData: Array<any> = [];
                    Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.value, text: pf.key }) });
                    this.setState({ contentTypeData: ddData });
                    if (!this.props.sysData.isShareflex)
                        this.props.errorEvent("");
                }
            });
    }

    private onMentionChange = (event, newValue, newPlainTextValue, mentions): void => {
        this.setState({
            dropdownData: [], dropdownDataSf: [], isValidated: false
        });
        newValue = newValue.replace("/ @[", "/@[");

        this.props.textChanged(newValue);
        this.props.dropChanged("", "");
        this.props.dropSfChanged("", "");
        this.props.contentTypeChanged("", "");
    }

    private onLibChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        this.getContentTypes(option.text);
        this.props.dropChanged(option.key as string, option.text);
    }

    private onLibSfChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        this.props.dropSfChanged(option.key as string, option.text);
    }

    private onContentTypeChanged = (ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption): void => {
        this.props.contentTypeSelectionChanged(option.key === "true");
    };

    private onContentTypeChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        this.props.contentTypeChanged(option.key as string, option.text);
    };
}