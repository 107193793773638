import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { TextField, ITextField } from '@fluentui/react/lib/TextField';
import { MentionsInput, Mention } from 'react-mentions';
import { Toggle, Panel, PanelType, Dropdown, IDropdown, IDropdownOption, Slider, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';

import MappingData from '../classes/models/MappingData';
import MappingValidatedModel from '../classes/models/MappingValidatedModel';
import defaultStyle from '../customComponents/Style/MentionStyle';
import { ParamMappingError } from '../classes/ParamMappingError';
import ModusRequests from '../classes/RequestHandler';

interface Props {
    confirmedEvent: (message: string) => void;
}

interface State {
    data: MappingData;
    dropdownData: Array<any>;
    availableParams: Array<any>;
    formatTypes: Array<any>;
    hideDialog: boolean;
    errors: ParamMappingError;
}

export default class CustomMappingDialog extends React.Component<Props, State> {
    private txtName = React.createRef<ITextField>();
    private drpColumns = React.createRef<IDropdown>();

    constructor(props: any) {
        super(props);
        this.state = {
            data: new MappingData(), dropdownData: [], availableParams: [], formatTypes: [], hideDialog: false,
            errors: new ParamMappingError()
        };
    }

    public render() {
        return (
            <Panel
                isOpen={this.state.hideDialog}
                onDismiss={() => this.confirmDialog("")}
                type={PanelType.custom}
                customWidth={"500px"}
                styles={{ content: { height: "calc(100vh - 140px) !important", minHeight: "calc(100vh - 140px) !important" } }}
                onRenderFooterContent={this.onRenderFooterContent}
                isFooterAtBottom={true}
                headerText={this.state.data.id > 0 ? this.state.data.name + " bearbeiten" : "Neue Verknüpfung anlegen"} >
                <div style={{ minWidth: "200px", maxWidth: "600px" }}>
                    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px", marginTop: "20px" }}>
                        {!this.state.data.isSharepoint && !this.state.data.isShareflex && !this.state.data.useAsFolder && this.renderElo()}
                        {this.state.data.isSharepoint && !this.state.data.useAsFolder && this.renderSharepointParam()}
                        {this.state.data.isShareflex && !this.state.data.useAsFolder && this.renderShareflexParam()}
                        {this.state.data.useAsFolder && this.renderFolder()}
                    </div>
                </div>
            </Panel >
        )
    }

    public renderElo = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                <Dropdown
                    defaultSelectedKey={this.state.data.name}
                    label="Spaltenname"
                    options={this.state.dropdownData}
                    onChange={this.onDropdownChange}
                    required={true}
                    componentRef={this.drpColumns}
                    errorMessage={this.state.errors.name} />
                <Label required={true} >Parameterzuordnung</Label>
                <MentionsInput
                    value={this.state.data.mappingValue}
                    onChange={this.onMentionChange}
                    style={this.state.data.inputMappingErrorMessage === '' ? defaultStyle : defaultStyle.controlError}
                    placeholder={"Parameterliste mit '{' aufrufen"} >
                    <Mention
                        markup="@[__display__](__id__)"
                        trigger="{"
                        data={this.state.availableParams}
                        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                            <div className={`${focused ? 'focused' : ''}`}>
                                {highlightedDisplay}
                            </div>
                        )}
                        style={{ backgroundColor: '#e6e6e6' }} />
                </MentionsInput>
                <span style={{ marginTop: '0', fontSize: '12px', color: 'rgb(164, 38, 44)' }}
                    className="ms-TextField-errorMessage">{this.state.errors.mappingValue}</span>
            </Stack>);
    }

    public renderSharepointParam = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                <Dropdown
                    defaultSelectedKey={this.state.data.name}
                    label="Spaltenname"
                    options={this.state.dropdownData}
                    onChange={this.onDropdownChange}
                    required={true}
                    componentRef={this.drpColumns}
                    errorMessage={this.state.errors.name} />
                <Label required={true} >Parameterzuordnung</Label>
                <MentionsInput
                    value={this.state.data.mappingValue}
                    onChange={this.onMentionChange}
                    style={this.state.data.inputMappingErrorMessage === '' ? defaultStyle : defaultStyle.controlError}
                    placeholder={"Parameterliste mit '{' aufrufen"} >
                    <Mention
                        markup="@[__display__](__id__)"
                        trigger="{"
                        data={this.state.availableParams}
                        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                            <div className={`${focused ? 'focused' : ''}`}>
                                {highlightedDisplay}
                            </div>
                        )}
                        style={{ backgroundColor: '#e6e6e6' }} />
                </MentionsInput>
                <span style={{ marginTop: '0', fontSize: '12px', color: 'rgb(164, 38, 44)' }}
                    className="ms-TextField-errorMessage">{this.state.errors.mappingValue}</span>
            </Stack>);
    }

    public renderShareflexParam = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                {this.renderSharepointParam()}
                <ChoiceGroup
                    className="defaultChoiceGroup"
                    defaultSelectedKey={this.state.data.isPrimaryKey ? 'true' : 'false'}
                    options={[
                        { key: 'true', text: 'Schlüsselspalte' } as IChoiceGroupOption,
                        { key: 'false', text: 'keine Schlüsselspalte' }
                    ]}
                    onChange={this.onPrimaryKeyChanged}
                    label="Schlüsselspalte"
                    styles={{ root: { display: this.state.data.showPrimaryKey ? "block" : "none" } }}
                />
            </Stack>);
    }

    public renderFolder = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                <TextField
                    label="Eindeutiger Name"
                    required={true}
                    errorMessage={this.state.errors.name}
                    validateOnLoad={false}
                    value={this.state.data.name}
                    onChange={this.onTextChanged}
                    styles={{ root: { maxWidth: '100%' } }}
                    disabled={this.state.data.id > 0}
                    componentRef={this.txtName} />
                {this.state.data.isFormula ? this.renderFormular() : this.renderParameter()}
            </Stack>
        );
    }

    private renderParameter = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                <Label required={true} >Parameterzuordnung</Label>
                <MentionsInput
                    value={this.state.data.mappingValue}
                    onChange={this.onMentionChange}
                    style={this.state.data.inputMappingErrorMessage === '' ? defaultStyle : defaultStyle.controlError}
                    placeholder={"Parameterliste mit '{' aufrufen"} >
                    <Mention
                        markup="@[__display__](__id__)"
                        trigger="{"
                        data={this.state.availableParams}
                        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                            <div className={`${focused ? 'focused' : ''}`}>
                                {highlightedDisplay}
                            </div>
                        )}
                        style={{ backgroundColor: '#e6e6e6' }} />
                </MentionsInput>
                <span style={{ marginTop: '0', fontSize: '12px', color: 'rgb(164, 38, 44)' }}
                    className="ms-TextField-errorMessage">{this.state.errors.mappingValue}</span>
                <Toggle onText="Formel" offText="Parameter" defaultChecked={this.state.data.isFormula} onChange={this.onToggleChanged} />
            </Stack>);
    }
    private renderFormular = (): JSX.Element => {
        return (
            <Stack tokens={{ childrenGap: 20 } as IStackTokens}>
                <TextField label="Formal Vorschau" value={this.getPreview()} styles={{ root: { maxWidth: '100%' } }} disabled={true} />
                <Toggle onText="Formel" offText="Parameter" defaultChecked={this.state.data.isFormula} onChange={this.onToggleChanged} />
                <Dropdown
                    label="Formeltyp" placeholder="Bitte wählen Sie einen Formeltyp aus" defaultSelectedKey={this.state.data.formulaType}
                    required={true} styles={{ dropdown: { width: "100%" } }} onChange={this.onFormulaTypeChange}
                    options={[{ key: 1, text: "Datum" }, { key: 2, text: "Teilzeichenfolge" }]}
                    errorMessage={this.state.errors.mappingFormulaType} />
                <Stack tokens={{ childrenGap: 20 } as IStackTokens} style={{ display: this.state.data.formulaType > 0 ? "block" : "none" }}>
                    <Dropdown
                        label="Formatierung" placeholder="Bitte wählen Sie eine Formatierung aus"
                        required={true} styles={{ dropdown: { width: "100%" } }} defaultSelectedKey={this.state.data.formatting}
                        options={this.state.formatTypes} onChange={this.onFormattingChange}
                        errorMessage={this.state.errors.mappingFormatting} />
                    <Stack tokens={{ childrenGap: 20 } as IStackTokens} style={{ display: this.state.data.formulaType === 2 ? "block" : "none" }}>
                        <Slider label="Anzahl Zeichen" onChange={this.onSliderChange}
                            styles={{ lineContainer: { width: "100%" }, valueLabel: { width: "20px", marginRight: 0 } }}
                            min={1} max={4} step={1} value={this.state.data.length} showValue snapToStep />
                    </Stack>
                    <Dropdown
                        label="Parameter" placeholder="Bitte wählen Sie einen Parameter aus"
                        required={true} onChange={this.onParamChange} defaultSelectedKey={this.state.data.mappingValue}
                        options={this.state.availableParams.map(x => { return { key: "@[{" + x.id + "}](" + x.id + ")", text: x.display } })}
                        styles={{ dropdown: { width: "100%" } }} errorMessage={this.state.errors.mappingValue}
                    />
                </Stack>
            </Stack>);
    }

    private onRenderFooterContent = () => (
        <div style={{ minWidth: '200px', maxWidth: '600px' }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal >
                <Stack.Item align="start" grow >
                    <DefaultButton className="ms-Cancel-Button" onClick={() => this.confirmDialog("")} text="Zurück ohne speichern" primaryDisabled={false} />
                </Stack.Item>
                <Stack.Item align="end" grow styles={{ root: { alignContent: "flex-end", flexGrow: 0 } }} >
                    <PrimaryButton className="ms-Default-Button2" onClick={() => {
                        ModusRequests.Post<MappingValidatedModel>("Mapping/Post", JSON.stringify(this.state.data))
                            .then((data: MappingValidatedModel) => {
                                this.mapResult(data);
                            });
                    }} text="Verknüpfung speichern" />
                </Stack.Item>
            </Stack>
        </div>
    );

    public updateData = (data: MappingData, dropdownData: Array<any>, availableParams: Array<any>): void => {
        let formatTypes = this.getFormatTypeArray(data.formulaType);
        this.setState({ formatTypes: formatTypes, data: data, dropdownData: dropdownData, hideDialog: true, availableParams: availableParams, errors: new ParamMappingError() });
    }

    public closeDialog = (): void => {
        this.setState({ hideDialog: false });
    }

    private getPreview = (): string => {
        let param = this.state.availableParams.find(x => "@[{" + x.id + "}](" + x.id + ")" === this.state.data.mappingValue)?.display + ";";
        let type = this.state.data.formulaType === 1 ? "TEXT(" + param : "LINKS(" + param;
        type += this.getFormat();
        return this.getTextFormat(type);
    }

    private getTextFormat = (pText: string): string => {
        if (this.state.data.formulaType === 1)
            return pText;
        if (this.state.data.formulaType === 2 && this.state.data.formatting <= 1)
            return pText;
        pText = this.state.data.formatting === 2 ? "GROSS(" + pText + ")" : "KLEIN(" + pText + ")";
        return pText;
    }

    private getFormat = (): string => {
        let format = "";
        if (this.state.data.formulaType === 1) {
            switch (this.state.data.formatting) {
                case 1:
                    format = "M";
                    break;
                case 2:
                    format = "MM";
                    break;
                case 3:
                    format = "JJ";
                    break;
                case 4:
                    format = "JJJJ";
                    break;
                default:
            }
        } else
            format = this.state.data.length > 0 ? this.state.data.length.toString() : "1";
        format += ")";
        return format;
    }

    private onTextChanged = (eventData): void => {
        let data = this.state.data as MappingData;
        data.name = (eventData.target.value as string);
        this.setState({ data: data });
    }

    private onMentionChange = (event, newValue, newPlainTextValue, mentions): void => {
        let data = this.state.data as MappingData;
        data.mappingValue = newValue;
        this.setState({ data: data });
    }

    private onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let data = this.state.data as MappingData;
        data.value = option.text;
        data.name = option.key as string;
        if (this.state.data.isShareflex)
        {
            data.availableInDocumentRepository = option["availableInDocumentRepository"];
            data.availableInFileRepository = option["availableInFileRepository"];
        }
        this.setState({ data: data });
    }

    private onParamChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let data = this.state.data as MappingData;
        data.mappingValue = option.key as string;
        this.setState({ data: data });
    }

    private onFormulaTypeChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let data = this.state.data;
        data.formulaType = option.key as number;
        data.formatting = 0;
        let formatTypes = this.getFormatTypeArray(data.formulaType);
        this.setState({ data: data, formatTypes: formatTypes });
    }

    private getFormatTypeArray = (pKey: number): Array<any> => {
        let formatTypes = [];
        if (pKey === 1)
            formatTypes = [{ key: 1, text: "Monat ohne führende '0'" }, { key: 2, text: "Monat mit führende '0'" }, { key: 3, text: "Jahr kurz" }, { key: 4, text: "Jahr lang" }];
        else
            formatTypes = [{ key: 1, text: "ohne" }, { key: 2, text: "Großbuchstaben" }, { key: 3, text: "Kleinbuchstaben" }];
        return formatTypes;
    }

    private onFormattingChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let data = this.state.data;
        data.formatting = option.key as number;
        data.length = 1;
        this.setState({ data: data });
    }

    private onSliderChange = (value: number): void => {
        let data = this.state.data;
        data.length = value;
        this.setState({ data: data });
    }

    private onFolderChanged = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
        let data = this.state.data as MappingData;
        data.useAsFolder = checked;
        if (!checked)
            data.sequence = 9999;
        this.setState({ data: data });
    }

    public onToggleChanged = (ev: React.MouseEvent<HTMLElement>, checked: boolean) => {
        let data = this.state.data as MappingData;
        data.isFormula = checked;
        this.setState({ data: data });
    }

    public onPrimaryKeyChanged = (ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption): void => {
        let data = this.state.data as MappingData;
        data.isPrimaryKey = option.key === "true"
        this.setState({ data: data });
    }

    public focusName = (): void => {
        this.txtName.current.focus()
    }

    private confirmDialog = (message: string): void => {
        this.props.confirmedEvent(message);
    }

    private mapResult(data: MappingValidatedModel) {
        if (data.isValid)
            this.confirmDialog(data.okMessage["Message"]);
        else {
            let errors = new ParamMappingError();
            if (data.errors.hasOwnProperty("Name")) {
                errors.name = data.errors["Name"];
            } else if (data.errors.hasOwnProperty("ParameterName")) {
                errors.name = data.errors["ParameterName"];
            }
            if (data.errors.hasOwnProperty("MappingValue")) {
                errors.mappingValue = data.errors["MappingValue"];
            }
            if (data.errors.hasOwnProperty("MappingFormatting")) {
                errors.mappingFormatting = data.errors["MappingFormatting"];
            }
            if (data.errors.hasOwnProperty("MappingFormulaType")) {
                errors.mappingFormulaType = data.errors["MappingFormulaType"];
            }
            this.setState({ errors: errors });
        }
    }

}

