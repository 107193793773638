import * as React from 'react';
import { Component } from 'react';
import { createTheme, Customizations } from '@fluentui/react';
import { Container } from 'reactstrap';
import NavMenu from '../standard/NavMenu';
import Wizard from './Wizard';

import '../../css/navigation.css';

const myTheme = createTheme({
    palette: {
        themePrimary: '#00305e',
        themeLighterAlt: '#cfdeec',
        themeLighter: '#a8c2da',
        themeLight: '#84a7c8',
        themeTertiary: '#648fb7',
        themeSecondary: '#4978a5',
        themeDarkAlt: '#316493',
        themeDark: '#1d5182',
        themeDarker: '#0c4070',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

interface Props {
    isSqlConnection: boolean;
}

class WizardAppRoot extends Component<Props, any> {

    constructor(props: any) {
        super(props);
        this.state = { width: window.innerWidth <= 800 ? 48 : 200 };
    }

    public updateDimensions = () => {
        this.setState({ width: window.innerWidth <= 800 ? 48 : 200 })
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    public render() {
        Customizations.applySettings({ theme: myTheme });

        return (
            <div>
                <NavMenu isAuthorized={true} loadPersona={false} />
                <div className="clearfix" >
                    <div className="left">
                        <div className="modusActionBar" style={{ width: this.state.width }} ></div>
                    </div>
                    <div className="right">
                        <Container style={{ margin: "0px", padding: "0px", maxWidth: "100%" }}>
                            <Wizard isSqlConnection={this.props.isSqlConnection} />
                        </Container>
                    </div >
                </div>
            </div>
        );
    }
}

export const WizardApp = WizardAppRoot;