import * as React from 'react';
import { ProgressIndicator, Modal } from '@fluentui/react';

interface Props {
    finishEvent: () => void;
}

interface State {
    percentComplete: number;
    isLoading: boolean;
}

export default class ModusProgressIndicator extends React.Component<Props, State> {
    private timer: any;
    private interval: number = 0.99;

    constructor(props: any) {
        super(props);
        this.state = { percentComplete: 0, isLoading: false }
    }

    public render() {
        return (
            <div>
                <Modal isOpen={this.state.isLoading} isBlocking={true} styles={{ main: { padding: "2%" } }}>
                    <div style={{ width: '400px', height: '120px', paddingTop: '15px' }}>
                        <ProgressIndicator styles={{ itemName: { lineHeight: "45px", textAlign: "center" } }}
                            description="Der Service wird konfiguriert."
                            label={(this.state.percentComplete * 100).toFixed(0).toString() + " %"}
                            percentComplete={this.state.percentComplete} />
                    </div>
                </Modal>
            </div>
        );
    }

    public startProcess = (): void => {
        this.setState({ isLoading: true, percentComplete: 0});
        this.timer = setInterval(() => { this.incrementTimer(); }, 200);
    }

    public stopProcess = (): void => {
        clearInterval(this.timer!);
        this.setState({ isLoading: false });
    }

    private incrementTimer = () => {
        if (this.state.percentComplete < this.interval) {
            this.setState({ percentComplete: ((0.01 + this.state.percentComplete) % 1) });
        } else {
            clearInterval(this.timer!);
            this.props.finishEvent();
        }
    };
}
