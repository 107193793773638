import * as React from 'react';
import { FontIcon, CommandBar, ICommandBarItemProps, Label, Pivot, PivotItem, ChoiceGroup, IChoiceGroupOption, SelectionMode } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import DocumentTypeData from '../../classes/models/DocumentTypeData';
import WaitingIndicator from '../../customComponents/WaitingIndicator';
import ArchiveSystemValidatedModel from '../../classes/models/MappingValidatedModel';
import ArchiveSystemData from '../../classes/models/ArchiveSystemData';
import MappingValidatedModel from '../../classes/models/MappingValidatedModel';
import MappingData from '../../classes/models/MappingData';
import CustomMappingDialog from '../../customComponents/CustomMappingDialog';
import CustomDialog from '../../customComponents/Dialogs/CustomDialog';
import M365SingleDragDrop from '../../customComponents/DragDrop/M365SingleDragDrop';
import SpMappingHandling from '../../customComponents/Sharepoint/SpMappingHandling';
import Modus from '../../classes/CustomColumn';
import M365DetailList from '../../customComponents/M365DetailList';
import { ctxt } from '../../config/AppContext';
import ModusRequests from '../../classes/RequestHandler';
import CustomCopyDialog from '../../customComponents/CustomCopyDialog';
import DmsMappingHandling from '../../customComponents/DmsHandling/DmsMappingHandling';
import ResultData from '../../classes/models/ResultData';
import M365MultiDragDrop from '../../customComponents/DragDrop/M365MultiDragDrop';
import CustomSaveChangesDialog from '../../customComponents/Dialogs/CustomSaveChangesDialog';

interface State {
    sysData: DocumentTypeData,
    dropdownData: Array<any>,
    contentTypeData: Array<any>,
    inputArchiveSystemErrorMessage: string,
    ContentTypeErrorMessage: string,
    inputBaseUrlErrorMessage: string,
    inputRootUrlErrorMessage: string,
    inputRootFolderErrorMessage: string,
    inputListNameErrorMessage: string,
    isLoading: boolean,
    mappingParamItems: Array<MappingData>,
    mappingFolderItems: Array<MappingData>,
    mappingResultItems: Array<ResultData>,
    selectedMapping: MappingData,
    mappingColumns: Array<Modus.CustomColumn>,
    hideMappingDialog: boolean,
    hideSaveDialog: boolean,
    dialogMappingMessage: string,
    itemMappingToDelete: MappingData,
    columnItems: Array<any>,
    isMappingValid: boolean,
    tabName: string,
    viewItemMenuNewParam: string,
    viewItemMenuParam: string,
    viewItemMenuNewFolder: string,
    viewItemMenuFolder: string,
    viewResultData: string,
    viewItemMenu: string,
    displayDivider: string
}

export default class MappingHandling extends React.Component<any, State> {

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    private MapDia = React.createRef<CustomMappingDialog>();
    private CopyDia = React.createRef<CustomCopyDialog>();

    constructor(props: any) {
        super(props);

        let mappingData = this.props.location.state.data as DocumentTypeData;

        this.state = {
            sysData: mappingData,
            dropdownData: [],
            contentTypeData: [],
            tabName: '',
            inputArchiveSystemErrorMessage: '',
            ContentTypeErrorMessage: '',
            inputBaseUrlErrorMessage: '',
            inputListNameErrorMessage: '',
            isLoading: true,
            mappingParamItems: [],
            mappingFolderItems: [],
            mappingResultItems: [],
            mappingColumns: [],
            hideMappingDialog: true,
            hideSaveDialog: true,
            dialogMappingMessage: "",
            itemMappingToDelete: new MappingData(),
            inputRootUrlErrorMessage: '',
            inputRootFolderErrorMessage: '',
            isMappingValid: false,
            columnItems: [],
            selectedMapping: new MappingData(),
            viewItemMenuParam: "none",
            viewItemMenuFolder: "none",
            viewItemMenu: "none", viewResultData: "none",
            viewItemMenuNewParam: "none",
            viewItemMenuNewFolder: "none", displayDivider: "none"
        };

        this.getArchivSystems();
    }

    componentDidMount() {
        if (this.context.key !== "key4") this.context.setKey('key4');
    }

    public render() {
        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading} />
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: "30px", paddingBottom: '15px', fontSize: '18px' }}>
                        {!this.state.sysData.unMapped ? this.state.sysData.documentTypeName + '-Zuordnung bearbeiten' : 'Neue Zuordnung anlegen'}
                    </Label>
                    <DndProvider backend={Backend} >
                        <Pivot onLinkClick={this.tabClick}>
                            <PivotItem headerText="DMS">
                                <div>
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px', width: "50%", minWidth: '300px', maxWidth: '600px', float: "left" }}>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                            <Dropdown placeholder="Bitte wählen Sie ein DMS aus!"
                                                defaultSelectedKey={this.state.sysData.archiveSystemId}
                                                label="DMS"
                                                options={this.state.dropdownData}
                                                styles={{ dropdown: { maxWidth: '100%' } }}
                                                onChange={this._onDropdownChange}
                                                required={true}
                                                errorMessage={this.state.inputArchiveSystemErrorMessage}
                                                disabled={!this.state.sysData.unMapped && this.state.isMappingValid && this.state.sysData.mappings.length > 0}
                                            />
                                            <ChoiceGroup
                                                className="defaultChoiceGroup"
                                                defaultSelectedKey={this.state.sysData.isOnline ? 'true' : 'false'}
                                                options={[
                                                    {
                                                        key: 'true',
                                                        text: 'Aktiv'
                                                    } as IChoiceGroupOption,
                                                    {
                                                        key: 'false',
                                                        text: 'Inaktiv'
                                                    }
                                                ]}
                                                onChange={this._onChoiceGroupChange}
                                                label="Zustand"
                                            />
                                        </Stack>
                                    </div>
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '20px', width: "50%", minWidth: '300px', maxWidth: '600px', float: "left" }}>
                                        <Stack tokens={{ childrenGap: 20 }}>
                                            {(this.state.hideSaveDialog && (!this.state.sysData.isSharepoint && !this.state.sysData.isShareflex && this.state.sysData.archiveSystemId > 0)) &&
                                                <DmsMappingHandling
                                                    sysData={this.state.sysData}
                                                    key="DmsMappingHandling"
                                                    dropChanged={this.rootFolderChange}
                                                    errorEvent={this.errorEvent}
                                                    inputRootFolderErrorMessage={this.state.inputRootFolderErrorMessage}
                                                    clearErrorMessagesEvent={this.clearErrorSpMessages}
                                                />
                                            }
                                            {(this.state.hideSaveDialog && (this.state.sysData.isSharepoint || this.state.sysData.isShareflex)) &&
                                                <SpMappingHandling
                                                    sysData={this.state.sysData}
                                                    key="SpMappingHandling"
                                                    dropChanged={this.rootFolderChange}
                                                    dropSfChanged={this.rootListChange}
                                                    contentTypeChanged={this.contentTypeChange}
                                                    contentTypeSelectionChanged={this.contentTypeSelectionChange}
                                                    textChanged={this.rootUrlChange}
                                                    errorEvent={this.errorEvent}
                                                    inputRootUrlErrorMessage={this.state.inputRootUrlErrorMessage}
                                                    inputRootFolderErrorMessage={this.state.inputRootFolderErrorMessage}
                                                    inputListNameErrorMessage={this.state.inputListNameErrorMessage}
                                                    contentTypeErrorMessage={this.state.ContentTypeErrorMessage}
                                                    clearErrorMessagesEvent={this.clearErrorSpMessages}
                                                />
                                            }
                                        </Stack>
                                    </div>
                                </div>
                            </PivotItem>
                            {(!this.state.sysData.unMapped && this.state.isMappingValid) &&
                                <PivotItem headerText="Parameter" >
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                        <M365DetailList
                                            heightHeader={245}
                                            items={this.state.mappingParamItems}
                                            columns={this.state.mappingColumns}
                                            modalSelection={SelectionMode.single}
                                            onSelectEvent={this.onItemClick}
                                            onSelectedItemEvent={this.onSelectedItemParamClick}
                                        />
                                    </div>
                                </PivotItem>
                            }
                            {(!this.state.sysData.unMapped && this.state.isMappingValid) &&
                                <PivotItem headerText="Ordner" >
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                        <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                            <M365SingleDragDrop
                                                items={this.state.mappingFolderItems}
                                                orderedEvent={this.orderedFolder}
                                                selectItem={this.onSelectedItemFolderClick}
                                            />
                                        </div>
                                    </div>
                                </PivotItem>
                            }
                            {(!this.state.sysData.unMapped && this.state.isMappingValid) &&
                                <PivotItem headerText="Ansicht" >
                                    <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                        <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                            <M365MultiDragDrop
                                                items={this.state.mappingResultItems.filter(x => x.isSelected).sort((x, y) => { return x.sequence - y.sequence })}
                                                selectedItems={this.state.mappingResultItems.filter(x => !x.isSelected)}
                                                orderedEvent={this.ordered}
                                            />
                                        </div>
                                    </div>
                                </PivotItem>
                            }
                        </Pivot>
                    </DndProvider>
                </div>

                <CustomMappingDialog
                    confirmedEvent={(message) => this.handleConfirmEvent(message)}
                    ref={this.MapDia}>
                </CustomMappingDialog>

                <CustomDialog
                    hideDialog={this.state.hideMappingDialog}
                    dialogMessage={this.state.dialogMappingMessage as string}
                    closeEvent={() => this.setState({ hideMappingDialog: true })}
                    confirmedEvent={this.deleteMappingConfirmed}>
                </CustomDialog>

                <CustomSaveChangesDialog
                    hideDialog={this.state.hideSaveDialog}
                    dialogMessage={"Wollen Sie die Änderungen an den Zuordnungen speichern?"}
                    closeEvent={this.reloadFolder}
                    confirmedEvent={this.saveFolderConfirmed}
                />

                <CustomCopyDialog ref={this.CopyDia} confirmedEvent={this.copyConfirmed} />
            </div >
        );
    }

    private orderedFolder = (items: Array<MappingData>): void => {
        let mappings = this.state.sysData.mappings;
        let newMappings = items.map((m, i) => {
            let item = mappings.find(y => y.id === m.id);
            item.sequence = item.useAsFolder ? i : 9999;
            return item;
        });
        let sys = this.state.sysData
        sys.mappings = newMappings;
        this.setState({ sysData: sys, mappingFolderItems: newMappings, viewItemMenu: "block", displayDivider: "block" });
    }

    private ordered = (items: Array<ResultData>, selectedItems: Array<ResultData>): void => {
        let data: Array<ResultData> = [];

        items.forEach((item: ResultData, index: number) => {
            data.push({
                isSelected: true,
                documentTypeId: item.documentTypeId,
                id: item.id,
                resultRowDisplayName: item.resultRowDisplayName,
                resultRowName: item.resultRowName,
                sequence: index
            });
        });
        selectedItems.forEach((item: ResultData) => {
            data.push({
                isSelected: false,
                documentTypeId: item.documentTypeId,
                id: item.id,
                resultRowDisplayName: item.resultRowDisplayName,
                resultRowName: item.resultRowName,
                sequence: -1
            });
        });
        this.setState({ mappingResultItems: data, viewResultData: data.filter(x => x.isSelected).length > 0 ? "block" : "none" });
    }

    private tabClick = (item?: PivotItem): void => {

        if (this.askChanges()) {
            this.setState({ tabName: item.props.headerText, hideSaveDialog: false });
        } else
            this.setTabStates(item.props.headerText);
    }

    private reloadFolder = (): void => {
        this.clearErrorMessages(true);
        this.setTabStates(this.state.tabName);
    }

    private saveFolderConfirmed = (): void => {
        this.clearErrorMessages(true);
        let data: Array<ResultData> = this.state.mappingResultItems.filter((item: ResultData) => item.isSelected);
        ModusRequests.Post<any>("Result/Post", JSON.stringify(data)).then(() => { this.setTabStates(this.state.tabName); });
    }

    private setTabStates = (tabName: string): void => {
        if (tabName === "Parameter") {
            this.setState({
                isLoading: true,
                viewItemMenuNewParam: "block",
                viewItemMenuNewFolder: "none",
                viewResultData: "none",
                mappingParamItems: [],
                mappingFolderItems: [],
                displayDivider: this.state.viewItemMenu
            });
            this.getData();
        }
        else if (tabName === "Ordner") {
            this.setState({
                isLoading: true,
                viewItemMenuNewParam: "none",
                viewItemMenuNewFolder: "block",
                viewResultData: "none",
                mappingParamItems: [],
                mappingFolderItems: [],
                displayDivider: this.state.viewItemMenu
            });
            this.getData();
        }
        else if (tabName === "Ansicht") {
            this.setState({
                isLoading: true,
                viewItemMenuNewParam: "none",
                viewItemMenuNewFolder: "none",
                viewResultData: "none",
                mappingParamItems: [],
                mappingFolderItems: [],
                displayDivider: this.state.viewItemMenu
            });
            this.getResultData();
        }
        else {
            this.setState({
                isLoading: true,
                viewItemMenuNewParam: "none",
                viewItemMenuNewFolder: "none",
                viewResultData: "none",
                mappingParamItems: [],
                mappingFolderItems: [],
                displayDivider: "none"
            });
            this.getData();
        }
    }

    private askChanges = (): boolean => {
        return this.state.viewResultData === "block";
    }

    private getResultData = (): void => {
        let system = this.state.sysData.isShareflex || this.state.sysData.isSharepoint ? "Sharepoint" : "Dns";
        ModusRequests.GetData<any>(system + "/GetColumnResults?Id=" + this.state.sysData.id,)
            .then((ArrayData: Array<ResultData>) => {
                this.setState({
                    isLoading: false, mappingResultItems: ArrayData, viewResultData: "none", viewItemMenuParam: "none", viewItemMenu: "none",
                });
            });
    }

    private getArchivSystems = (): void => {
        ModusRequests.GetData<ArchiveSystemData>("ArchiveSystem/Get")
            .then((data: Array<ArchiveSystemData>) => {
                let ddData: Array<any> = [];
                data.forEach(pf => { ddData.push({ key: pf.id, text: pf.name }) });
                this.setState({ dropdownData: ddData });
                this.getData();
            });
    }

    private deleteMappingConfirmed = (): void => {
        this.setState({ hideMappingDialog: true, isLoading: true });
        ModusRequests.Post<MappingValidatedModel>("Mapping/Delete", JSON.stringify(this.state.itemMappingToDelete))
            .then((data: MappingValidatedModel) => {
                this.mapMessages(data);
            });
    }

    private handleConfirmEvent = (message: string): void => {
        this.setState({ isLoading: message !== "" });
        this.MapDia.current.closeDialog();
        if (message !== "")
            this.getData();
    }

    private getData = (): void => { //ToDo: Check if value is saved in DB
        ModusRequests.Get<DocumentTypeData>("DocumentType/Get?Id=" + this.state.sysData.id,)
            .then((data: DocumentTypeData) => {
                if (data.documentLibrary !== null && data.documentLibrary !== "")
                    this.getColumnData(data.isShareflex || data.isSharepoint,data.isShareflex , data);
                else
                    this.setState({
                        sysData: data, isLoading: false,
                        mappingParamItems: data.mappings.filter(x => !x.useAsFolder),
                        mappingFolderItems: data.mappings.sort((a, b) => { return a.sequence - b.sequence }),
                        isMappingValid: false, displayDivider: "none",
                        viewItemMenuParam: "none", viewItemMenuFolder: "none", viewItemMenu: "none"
                    });

                this.setColumns();
            });
    }

    private getColumnData = (isSharepoint: boolean,isShareflex:boolean, data: DocumentTypeData): void => {
        var system = isSharepoint ? "Sharepoint" : "Dns";
        ModusRequests.GetData<any>(system + "/GetColumns?Id=" + this.state.sysData.id,)
            .then((ArrayData: Array<any>) => {
                let ddData: Array<any> = [];
                if (isShareflex || isSharepoint) {
                    if (isShareflex)
                    {
                        ddData.push({ key: 'bothHeader', text: 'In beiden vorhanden', itemType: DropdownMenuItemType.Header });
                    }
                    Object.entries(ArrayData).filter(([key, value]) => value.availableInDocumentRepository && value.availableInFileRepository).map(([key, value]) => ({ key, value })).forEach
                        (pf => {
                            ddData.push({ key: pf.value.internalName, text: pf.value.displayName, available: false, availableInDocumentRepository: pf.value.availableInDocumentRepository, availableInFileRepository: pf.value.availableInFileRepository })
                        });
                    if (isShareflex)
                    {
                        ddData.push({ key: 'repoHeader', text: 'Aus Datei', itemType: DropdownMenuItemType.Header });
                    }
                    Object.entries(ArrayData).filter(([key, value]) => value.availableInDocumentRepository && !value.availableInFileRepository).map(([key, value]) => ({ key, value })).forEach
                        (pf => {
                            ddData.push({ key: pf.value.internalName, text: pf.value.displayName, available: false, availableInDocumentRepository: pf.value.availableInDocumentRepository, availableInFileRepository: pf.value.availableInFileRepository })
                        });
                    if (isShareflex)
                    {
                        ddData.push({ key: 'fruitsHeader', text: 'Aus Akte', itemType: DropdownMenuItemType.Header });
                    }
                    Object.entries(ArrayData).filter(([key, value]) => !value.availableInDocumentRepository && value.availableInFileRepository).map(([key, value]) => ({ key, value })).forEach
                        (pf => {
                            ddData.push({ key: pf.value.internalName, text: pf.value.displayName, available: false, availableInDocumentRepository: pf.value.availableInDocumentRepository, availableInFileRepository: pf.value.availableInFileRepository })
                        });
                }
                else {
                    Object.entries(ArrayData).map(([key, value]) => ({ key, value })).forEach
                        (pf => { ddData.push({ key: pf.key, text: pf.value, available: false }) });
                }
                this.setState({
                    columnItems: ddData, sysData: data, isMappingValid: true, isLoading: false,
                    mappingParamItems: data.mappings.filter(x => !x.useAsFolder),
                    mappingFolderItems: data.mappings.sort((a, b) => { return a.sequence - b.sequence }),
                    viewItemMenuParam: "none", viewItemMenuFolder: "none", viewItemMenu: "none", displayDivider: "none"
                });
            });
    }

    private saveClick = (): void => {
        this.clearErrorMessages(true);
        let newData = this.state.sysData;
        ModusRequests.Post<MappingValidatedModel>("DocumentType/Post", JSON.stringify(newData))
            .then((data: MappingValidatedModel) => {
                this.mapMessages(data);
            });
    }

    private clearErrorMessages = (isloading: boolean): void => {
        this.setState({
            ContentTypeErrorMessage: "", inputArchiveSystemErrorMessage: "",
            inputBaseUrlErrorMessage: "", inputRootFolderErrorMessage: "", inputRootUrlErrorMessage: "",
            inputListNameErrorMessage: "", isLoading: isloading, hideSaveDialog: true
        })
    }

    private clearErrorSpMessages = (): void => {
        this.setState({ ContentTypeErrorMessage: "", inputRootFolderErrorMessage: "", inputRootUrlErrorMessage: "", isLoading: true })
    }

    private mapMessages = (data: MappingValidatedModel): void => {
        if (data.isValid)
            this.getData();
        else
            this.mapErrorMessages(data.errors);
    }

    private mapErrorMessages = (messages: ArchiveSystemValidatedModel): void => {

        if (messages.hasOwnProperty("ArchiveSystem"))
            this.setState({ inputArchiveSystemErrorMessage: messages["ArchiveSystem"] });

        if (messages.hasOwnProperty("ContentType"))
            this.setState({ ContentTypeErrorMessage: messages["ContentType"] });

        if (messages.hasOwnProperty("BaseUrl"))
            this.setState({ inputBaseUrlErrorMessage: messages["BaseUrl"] });

        if (messages.hasOwnProperty("RootURL"))
            this.setState({ inputRootUrlErrorMessage: messages["RootURL"] });

        if (messages.hasOwnProperty("RootFolder"))
            this.setState({ inputRootFolderErrorMessage: messages["RootFolder"] });

        if (messages.hasOwnProperty("ListName"))
            this.setState({ inputListNameErrorMessage: messages["ListName"] });

        this.setState({ isLoading: false });
    }

    private rootUrlChange = (newText: string): void => {
        this.clearErrorMessages(false);
        let newData = this.state.sysData;
        newData.startUrl = newText;
        this.setState({ sysData: newData, viewItemMenu: "block" });
    }

    private errorEvent = (errorText: string): void => {
        if (errorText === "")
            this.setState({ isLoading: false });
        else
            this.setState({ inputRootUrlErrorMessage: errorText, isLoading: false });
    }

    private rootFolderChange = (newText: string, newName: string): void => {
        let newData = this.state.sysData;
        newData.documentLibrary = newText;
        newData.documentLibraryName = newName;
        newData.contentTypeId = "";
        newData.contentTypeName = "";
        this.setState({ sysData: newData, isMappingValid: false, viewItemMenu: "block" });
    }

    private contentTypeChange = (newText: string, newName: string): void => {
        let newData = this.state.sysData;
        newData.contentTypeId = newText;
        newData.contentTypeName = newName;
        this.setState({ sysData: newData, isMappingValid: false, viewItemMenu: "block" });
    }

    private contentTypeSelectionChange = (newOption: boolean): void => {
        let newData = this.state.sysData;
        newData.useContentTypes = newOption;
        this.setState({ sysData: newData, isMappingValid: false, viewItemMenu: "block" });
    }

    private rootListChange = (newText: string, newName: string): void => {
        let newData = this.state.sysData;
        newData.listLibrary = newText;
        newData.listLibraryName = newName;
        this.setState({ sysData: newData, isMappingValid: false, viewItemMenu: "block" });
    }

    private _onChoiceGroupChange = (ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption): void => {
        let newData = this.state.sysData;
        newData.isOnline = option.key === "true";
        this.setState({ sysData: newData, viewItemMenu: "block" });
    };

    private _onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        if (this.state.isLoading)
            return;
        this.setState({ isLoading: true });
        ModusRequests.Get<DocumentTypeData>("DocumentType/GetById?Id=" + this.state.sysData.id + "&ArchivId=" + option.key)
            .then((data: DocumentTypeData) => this.setState({
                sysData: data, isMappingValid: data.documentLibrary !== null && data.documentLibrary !== "",
                viewItemMenuParam: "none",
                viewItemMenuFolder: "none",
                viewItemMenu: "block", isLoading: false
            }));
    };

    private onItemDeleteClick = (pData: MappingData, pText: string): void => {
        this.setState({ hideMappingDialog: false, dialogMappingMessage: pText, itemMappingToDelete: pData });
    }

    private onItemClick = (pData: MappingData): void => {
        this.MapDia.current.updateData(this.getMappingData(pData), this.updateColumns(pData.name), this.state.sysData.availableParams.map(item => { return { id: item, display: '{' + item + '}' } }));
    }

    private onSelectedItemParamClick = (pData: Array<MappingData>): void => {
        if (pData.length === 0) {
            this.setState({ viewItemMenuParam: "none" });
        } else {
            this.setState({ selectedMapping: this.getMappingData(pData[0]), viewItemMenuParam: "block" });
        }
    }

    private onSelectedItemFolderClick = (pData: MappingData): void => {
        var folderMappings = this.state.mappingFolderItems;
        folderMappings.forEach(x => x.isSelected = false);
        if (pData === null) {
            this.setState({ viewItemMenuFolder: "none", mappingFolderItems: folderMappings });
        } else {
            folderMappings.find(x => x.id === pData.id).isSelected = true;
            this.setState({ selectedMapping: this.getMappingData(pData), viewItemMenuFolder: "block", mappingFolderItems: folderMappings });
        }
    }

    private getMappingData = (pData: MappingData): MappingData => {
        let data = new MappingData();
        data.id = pData.id;
        data.name = pData.name;
        data.sequence = pData.sequence;
        data.useAsFolder = pData.useAsFolder;
        data.mappingValue = pData.mappingValue;
        data.value = pData.value;
        data.documentTypeId = this.state.sysData.id;
        data.isSharepoint = this.state.sysData.isSharepoint;
        data.isShareflex = this.state.sysData.isShareflex;
        data.formatting = pData.formatting;
        data.formulaType = pData.formulaType;
        data.length = pData.length;
        data.isFormula = pData.isFormula;
        data.isPrimaryKey = pData.isPrimaryKey;
        data.showPrimaryKey = this.state.mappingParamItems.filter(x => x.isPrimaryKey).length === 0 || pData.isPrimaryKey;
        return data;
    }

    private replacePlaceholder = (text: string): string => {
        do {
            text = this.replaceMentionText(text);
        } while (text.indexOf("@[{") >= 0);
        return text;
    }

    private replaceMentionText = (text: string): string => {
        let startIndex = text.indexOf("@[{");
        if (startIndex < 0)
            return text;
        startIndex += 3;
        let stopIndex = text.indexOf("}](");
        let mentionText = text.substring(startIndex, stopIndex);
        let mention = "@[{" + mentionText + "}](" + mentionText + ")";
        return text.replace(mention, "{" + mentionText + "}");
    }

    private setColumns = (): void => {
        let columns = [
            {
                key: 'Name', name: 'Parametername', fieldName: 'value', minWidth: 150,
                maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true)
            }, {
                key: 'PrimaryKey', name: 'Schlüsselspalte', fieldName: 'isPrimaryKey', minWidth: 110, maxWidth: 110,
                isResizable: true, data: "boolean", onRender: (item: MappingData) => {
                    return <div>
                        <FontIcon iconName="completed"
                            style={{ paddingLeft: "40px", fontSize: '12px', color: 'green', display: item.isPrimaryKey ? 'block' : 'none' }} /></div>;
                }
            }, {
                key: 'mappingValue', name: 'Inhalt', fieldName: 'mappingValue', minWidth: 250,
                maxWidth: 250, isResizable: true, data: 'string', isCollapsible: true,
                onRender: (item: MappingData) => {
                    return <div>{this.replacePlaceholder(item.mappingValue)}</div>;
                }
            },
            {
                key: 'AvailableInDocumentRepository', name: 'Datei', fieldName: 'availableInDocumentRepository', minWidth: 100, maxWidth: 100,
                isResizable: true, data: "boolean", onRender: (item: MappingData) => {
                    return <div>
                        <FontIcon iconName="completed"
                            style={{ paddingLeft: "10px", fontSize: '12px', color: 'green', display: item.availableInDocumentRepository ? 'block' : 'none' }} /></div>;
                }
            },
            {
                key: 'AvailableInFileRepository', name: 'Akte', fieldName: 'availableInFileRepository', minWidth: 100, maxWidth: 100,
                isResizable: true, data: "boolean", onRender: (item: MappingData) => {
                    return <div>
                        <FontIcon iconName="completed"
                            style={{ paddingLeft: "10px", fontSize: '12px', color: 'green', display: item.availableInFileRepository ? 'block' : 'none' }} /></div>;
                }
            },
            {
                key: 'Error', name: '', minWidth: 20, maxWidth: 20, isResizable: false,
                onRender: (item: MappingData) => {
                    return <div>
                        <FontIcon iconName="Error"
                            style={{ fontSize: '12px', color: 'salmon', display: item.hasError ? 'block' : 'none' }} /></div>;
                }
            }, {
                key: 'UserName', name: 'Geändert von', fieldName: 'userName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string', isCollapsible: true,
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10
            }
        ];

        if (!this.state.sysData?.isShareflex) {
            columns.splice(1, 1); //Primary key
            columns.splice(2, 2); //Repository and File
        }

        this.setState({ mappingColumns: columns });
    }

    private updateColumns = (name: string): Array<any> => {

        let newArray: Array<any> = [];
        if (!this.state.sysData.isShareflex)
        {
            this.state.columnItems.filter(x => x.key === name).forEach(y => newArray.push({
                key: y.key,
                text: y.text
            }));
        }
        this.state.columnItems.forEach(columnItem => {
            ///ME: Changed from includes to equals
            if (!this.state.sysData.mappings.some(item => item.name === columnItem.key)) {
                if (this.state.sysData.isShareflex) {
                    if (columnItem.itemType !== undefined) {
                        newArray.push({
                            key: columnItem.key,
                            text: columnItem.text,
                            availableInDocumentRepository: columnItem.availableInDocumentRepository,
                            availableInFileRepository: columnItem.availableInFileRepository,
                            itemType: columnItem.itemType
                        });
                        switch (columnItem.key)
                        {
                            case "bothHeader":
                                this.state.columnItems.filter(x => x.key === name).forEach(y => {
                                    console.log(y);
                                    if (y.availableInDocumentRepository && y.availableInFileRepository)
                                    {
                                        newArray.push({
                                            key: y.key,
                                            text: y.text,
                                            availableInDocumentRepository: y.availableInDocumentRepository,
                                            availableInFileRepository: y.availableInFileRepository
                                        });
                                    }
                                });
                                break;
                            case "repoHeader":
                                this.state.columnItems.filter(x => x.key === name).forEach(y => {
                                    console.log(y);
                                    if (y.availableInDocumentRepository && !y.availableInFileRepository)
                                    {
                                        newArray.push({
                                            key: y.key,
                                            text: y.text,
                                            availableInDocumentRepository: y.availableInDocumentRepository,
                                            availableInFileRepository: y.availableInFileRepository
                                        });
                                    }
                                });
                                break;
                            case "fruitsHeader":
                                this.state.columnItems.filter(x => x.key === name).forEach(y => {
                                    console.log(y);
                                    if (!y.availableInDocumentRepository && y.availableInFileRepository)
                                    {
                                        newArray.push({
                                            key: y.key,
                                            text: y.text,
                                            availableInDocumentRepository: y.availableInDocumentRepository,
                                            availableInFileRepository: y.availableInFileRepository
                                        });
                                    }
                                });
                                break;
                        }
                    }
                    else {
                        newArray.push({
                            key: columnItem.key,
                            text: columnItem.text,
                            availableInDocumentRepository: columnItem.availableInDocumentRepository,
                            availableInFileRepository: columnItem.availableInFileRepository
                        });
                    }
                }
                else {
                    newArray.push({
                        key: columnItem.key,
                        text: columnItem.text
                    });
                }
            }
        });

        return newArray;
    }

    private getMappingsToCopy = (): void => {
        this.setState({ isLoading: true });
        ModusRequests.GetData<DocumentTypeData>("Clone/GetDocType?DocId" + this.state.sysData.id + "&ArchivId=" + this.state.sysData.archiveSystemId + "&SystemId=" + this.state.sysData.systemId,)
            .then((data: Array<DocumentTypeData>) => {
                let ddData: Array<any> = [];
                data.forEach(pf => { ddData.push({ key: pf.id, text: pf.documentTypeName }) });
                this.CopyDia.current.openDialog(ddData);
                this.setState({ isLoading: false });
            });
    }

    private copyConfirmed = (key: number): void => {
        this.setState({ isLoading: true });
        let newData = {
            "docId": this.state.sysData.id,
            "docIdToCopy": key,
            "columns": this.state.columnItems
        }
        ModusRequests.Post<boolean>("Clone/CopyParams", JSON.stringify(newData))
            .then((data: boolean) => {
                this.getData();
            });
    }

    private saveResultData = (): void => {
        this.clearErrorMessages(true);
        let data: Array<ResultData> = this.state.mappingResultItems.filter((item: ResultData) => item.isSelected);
        ModusRequests.Post<any>("Result/Post", JSON.stringify(data))
            .then(() => {
                this.getResultData();
            });
    }

    private renderCommands = (): ICommandBarItemProps[] => {
        return [{
            key: 'save', text: 'Speichern', ariaLabel: 'Save', style: { display: this.state.viewItemMenu, backgroundColor: "transparent" },
            iconProps: { iconName: 'Save', style: { fontSize: "15px" } },
            onClick: () => this.saveClick()
        }, {
            key: 'divider',
            onRender: () => <div style={{ display: this.state.displayDivider, margin: '5px', width: '1px', background: '#ddd' }} > </div>
        }, {
            key: 'newParamItem', text: 'Neu', ariaLabel: 'New', style: { display: this.state.viewItemMenuNewParam, backgroundColor: "transparent" },
            iconProps: { iconName: 'Add', style: { fontSize: "15px" } },
            onClick: () => {
                let newData = new MappingData();
                newData.sequence = 999;
                this.onItemClick(newData);
            }
        }, {
            key: 'dividerItems',
            onRender: () => <div style={{
                display: this.state.sysData.mappings.length === 0 ? this.state.viewItemMenuNewParam : "none",
                margin: '5px', width: '1px', background: '#ddd'
            }} > </div>
        }, {
            key: 'copyParamItem', text: 'Parameter kopieren', ariaLabel: 'Copy', style: {
                display: this.state.sysData.mappings.length === 0 ? this.state.viewItemMenuNewParam : "none",
                backgroundColor: "transparent"
            },
            iconProps: { iconName: 'Copy', style: { fontSize: "15px" } },
            onClick: () => this.getMappingsToCopy()
        }, {
            key: 'editParamItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
            iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
            onClick: () => this.MapDia.current.updateData(JSON.parse(JSON.stringify(this.state.selectedMapping)),
                this.updateColumns(this.state.selectedMapping.name),
                this.state.sysData.availableParams.map(item => { return { id: item, display: '{' + item + '}' } }))
        }, {
            key: 'deleteParamItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
            iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
            onClick: () => this.onItemDeleteClick(this.state.selectedMapping,
                `Wollen Sie den Parameter ${this.state.selectedMapping.name} wirklich löschen?`
            )
        }, {
            key: 'newFolderItem', text: 'Neu', ariaLabel: 'New', style: { display: this.state.viewItemMenuNewFolder, backgroundColor: "transparent" },
            iconProps: { iconName: 'Add', style: { fontSize: "15px" } },
            onClick: () => {
                let newData = new MappingData();
                newData.sequence = this.state.sysData.mappings.length + 1;
                newData.useAsFolder = true;
                this.onItemClick(newData);
            }
        }, {
            key: 'editFolderItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuFolder, backgroundColor: "transparent" },
            iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
            onClick: () => this.MapDia.current.updateData(JSON.parse(JSON.stringify(this.state.selectedMapping)),
                this.updateColumns(this.state.selectedMapping.name),
                this.state.sysData.availableParams.map(item => { return { id: item, display: '{' + item + '}' } }))
        }, {
            key: 'deleteFolderItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuFolder, backgroundColor: "transparent" },
            iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
            onClick: () => this.onItemDeleteClick(this.state.selectedMapping,
                `Wollen Sie den Ordner ${this.state.selectedMapping.name} wirklich löschen?`
            )
        }, {
            key: 'saveResultData', text: 'Speichern', ariaLabel: 'Save',
            style: { display: this.state.viewResultData, backgroundColor: "transparent" },
            iconProps: { iconName: 'Save', style: { fontSize: "15px" } },
            onClick: () => this.saveResultData()
        }]
    }
}
