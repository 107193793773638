import * as React from 'react';
import { Route } from 'react-router-dom';
import ModusNavItem from './ModusNavItem';
import { ctxt } from '../config/AppContext';

import '../css/navigation.css';
import '../css/ModusButtons.css';
import '../css/Checker.css';

export default class ModusNavigation extends React.Component<any, any> {
    private history: any;
    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);
        this.state = { navItems: this.getNavLinks(), width: window.innerWidth <= 800 ? 48 : 200 };
    }

    public updateDimensions = () => {
        this.setState({ width: window.innerWidth <= 800 ? 48 : 200 })
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    private handleItemClick = (key: string, url: string): void => {

        if (key === "key1") {
            this.state.width === 48 ? this.setState({ width: 200 }) : this.setState({ width: 48 });
        } else {
            this.context.setKey(key);
            this.history.push(url);
        }
    }

    private setHistory = (history: any): void => {
        this.history = history;
    }

    public render() {
        const { key, setKey } = this.context!;
        return (
            <div className="modusActionBar" >
                <Route render={({ history }) => { this.setHistory(history);
                    return (
                        <div>
                            {this.state.navItems.map(item => (
                                <ModusNavItem
                                    key={item.key} id={item.key} width={this.state.width}
                                    icon={item.icon} selectedEvent={this.handleItemClick}
                                    selected={key} name={item.name} url={item.altText} />
                            ))}
                        </div>
                    )
                }} />
            </div >
        );
    }

    private getNavLinks(): any[] {
        return [
            {
                name: "", key: "key1", icon: "GlobalNavButton", url: "", selected: false
            }, {
                name: "Systeme", key: "key2", icon: "System", altText: "/", selected: false
            },  {
                name: "DMS", altText: "/archivs", key: "key3", icon: "PageData", selected: false
            }, {
                name: "Zuordnungen", altText: "/mappings", key: "key4", icon: "PlugConnected", selected: false
            }
        ]
    }
}