/// <reference path="../../interfaces/models/ISystemData.ts"/>

class SystemData {
    public id?: number = 0;
    public name?: string = "";
    public sourceSystemGuid?: string = "";
    public isOnline?: boolean = false;
    public userName?: string = "";
    public mappingError?: boolean = false;
    public unMapped?: boolean = false;

    constructor() { }
}

export default SystemData; 
