import * as React from "react";
import { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  createTheme,
  Customizations,
  MessageBar,
  MessageBarButton,
  MessageBarType,
} from "@fluentui/react";
import withAuth from "./hoc/Auth";

import Layout from "./components/standard/Layout";
import Systems from "./components/pages/Systems";
import Archive from "./components/pages/ArchiveSystems";
import Mapping from "./components/pages/Mappings";
import Security from "./components/pages/Security";
import MappingHandling from "./components/pages/MappingHandling";
import { AppContextProvider } from "./config/AppContext";
import Protocols from "./components/pages/Protocols";
import SearchProtocols from "./components/pages/SearchProtocols";
import ImExportHandler from "./components/pages/ImExportHandler";
import WaitingIndicator from "./customComponents/WaitingIndicator";
import ModusRequests from "./classes/RequestHandler";
import Enumerable from "linq";

const myTheme = createTheme({
  palette: {
    themePrimary: "#00305e",
    themeLighterAlt: "#cfdeec",
    themeLighter: "#a8c2da",
    themeLight: "#84a7c8",
    themeTertiary: "#648fb7",
    themeSecondary: "#4978a5",
    themeDarkAlt: "#316493",
    themeDark: "#1d5182",
    themeDarker: "#0c4070",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

interface currentUser {
  displayName: string;
  image: string;
}

const KeyProvider: React.FC = ({ children }) => {
  const [key, setKey] = React.useState("");
  React.useEffect(() => {}, []);

  return (
    <AppContextProvider value={{ key, setKey }}>{children}</AppContextProvider>
  );
};

export class RootApp extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      isAuthorized: false,
      allowedUser: "",
    };
  }

  public render() {
    let result = false;
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(
        localStorage.getItem("useAadAuthentication") ?? "false"
      );
    }

    if (this.props.auth === null && !this.props.auth?.isAuthenticated && result)
      return <></>;
    Customizations.applySettings({ theme: myTheme });
    let contents = this.renderContent();
    this.CheckPermissions();
    return (
      <div>
        <Router>{contents}</Router>
      </div>
    );
  }

  private async CheckPermissions() {
    if (this.state.isLoading) {
      let permissions: Array<any> = await ModusRequests.Get(
        "permission/GetActivePermissions"
      );
      if (permissions.length <= 0) {
        let payload = {
          id: 0,
          userAccount: this.props.auth.Account.username,
          permissionLevel: "Admin",
          isActive: true,
        };
        let result = await ModusRequests.Post("permission/Post", JSON.stringify(payload));
        if (result['resultType'] === "Error")
        {
          console.error(result['resultMessage']);
        }
        permissions = await ModusRequests.Get(
          "permission/GetActivePermissions"
        );
      }
      let result = Enumerable.from(permissions).where((permission) =>permission.userAccount === this.props.auth.Account.username).firstOrDefault();
      if (result !== undefined) {
        this.setState({ isLoading: false, isAuthorized: true });
      } else {
        this.setState({
          isLoading: false,
          isAuthorized: false,
          allowedUser:
            Enumerable.from(permissions).firstOrDefault()?.userAccount ?? "",
        });
      }
    }
  }

  private onRequestPermissionClick(userName)
  {
    let payload = {
      id: 0,
      userAccount: userName,
      permissionLevel: "Admin",
      isActive: false,
    };
    ModusRequests.Post("permission/Post", JSON.stringify(payload)).then(result => console.info(result));
  }

  private renderContent() {
    return (
      <div>
        <WaitingIndicator
          labelText="App wird initialisiert..."
          isLoading={this.state.isLoading}
        />
        <KeyProvider>
          <Layout isAuthorized={this.state.isAuthorized}>
            {!this.state.isLoading && this.state.isAuthorized && (
              <Switch>
                <Route exact path="/" component={Systems} />

                <Route exact path="/archivs" component={Archive} />

                <Route exact path="/mappings" component={Mapping} />
                <Route
                  exact
                  path="/mappingHandling"
                  component={MappingHandling}
                />

                <Route exact path="/protocols" component={Protocols} />
                <Route exact path="/searchprotocols" component={SearchProtocols} />
                <Route exact path="/security" component={Security} />

                <Route exact path="/import" component={ImExportHandler} />

                <Route exact path="/swaggerUI/Index.html" />
                <Route
                  exact
                  path="/.well-known/microsoft-identity-association.json"
                />
              </Switch>
            )}
            {!this.state.isLoading && !this.state.isAuthorized && (
              <MessageBar
                messageBarType={MessageBarType.blocked}
                isMultiline={false}
                actions={
                  <div style={{marginLeft:'0'}}>
                    <MessageBarButton onClick={() => this.onRequestPermissionClick(this.props.auth.Account.username)}>Zugriff anfordern</MessageBarButton>
                  </div>
                }
              >
                Sie haben keinen Zugriff auf das System! Bitte fordern Sie den Zugriff an oder wenden Sie sich an den Administrator.
              </MessageBar>
            )}
          </Layout>
        </KeyProvider>
      </div>
    );
  }
}

export const App = withAuth(RootApp);
