import * as React from 'react';
import { Label } from '@fluentui/react/lib/Label';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { CommandBar, DefaultButton, PrimaryButton, FontIcon, ICommandBarItemProps, SelectionMode } from '@fluentui/react';

import WaitingIndicator from '../../customComponents/WaitingIndicator';
import M365DetailList from '../../customComponents/M365DetailList';
import CustomSecurityHandling from '../../customComponents/CustomSecurityHandling';

import { ctxt } from '../../config/AppContext';
import { Modus } from '../../classes/CustomColumn';
import ModusRequests from '../../classes/RequestHandler';
import SecurityData from '../../classes/models/SecurityData';

export default class Security extends React.Component<any, any> {
    private CustomSecurityHandling = React.createRef<CustomSecurityHandling>();

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;
    constructor(props: any) {
        super(props);
        this.state = {
            items: [], loading: true, hideDialog: true, dialogMessage: "",
            isLoading: true, selectedItem: new SecurityData(), viewItemMenuParam: "none"
        };
        this.getSecurityData();
    }

    public render(): JSX.Element {
        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading} ></WaitingIndicator >
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}>
                        Zugriffsliste
                        </Label>
                    <M365DetailList
                        heightHeader={190}
                        items={this.state.items}
                        columns={this.setColumns()}
                        modalSelection={SelectionMode.single}
                        onSelectEvent={this.onItemClick}
                        onSelectedItemEvent={this.onSelectedItemParamClick}
                    />
                </div>
                <CustomSecurityHandling
                    confirmedEvent={this.confirm}
                    history={this.props.history}
                    ref={this.CustomSecurityHandling}
                    startBusy={() => this.setState({ isLoading: true })}
                    stopBusy={() => this.setState({ isLoading: false })}
                ></CustomSecurityHandling>
                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={this.closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Löschen bestätigen",
                        subText: this.state.dialogMessage
                    }}
                    modalProps={{ isBlocking: true }}>
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog} text="Abbrechen" primaryDisabled={false} />
                        <PrimaryButton onClick={this.deleteConfirmed} text="Löschen" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private setColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'userAccount', name: 'Benutzername', fieldName: 'userAccount', minWidth: 400, maxWidth: 500, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true)
            }, {
                key: 'isActive', name: 'Zugriff', fieldName: 'isActive', minWidth: 50, maxWidth: 50, isResizable: false, data: 'boolean',
                onRender: (item: SecurityData) => {
                    return (item.isActive ?
                        <FontIcon iconName="completed" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'green' }} /> :
                        <FontIcon iconName="ErrorBadge" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} />)
                },
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.booleanFilter, true, true, [], [{ key: true, text: 'Aktiv' }, { key: false, text: 'Inaktiv' }])
            }
        ]
        return columns;
    }

    private getSecurityData = (): void => {
        ModusRequests.GetData<SecurityData>("Permission/Get")
            .then((data: Array<SecurityData>) => {
                this.setState({ items: data, loading: false, isLoading: false });
                this.context.setKey('key5');
            });
    }

    private onItemClick = (pData: SecurityData): void => {
        this.editClick(JSON.parse(JSON.stringify(pData)))
    }
    private renderCommands = (): ICommandBarItemProps[] => {
        return [
            {
                key: 'newItem', text: 'Neu', ariaLabel: 'New', style: { backgroundColor: "transparent" },
                iconProps: { iconName: 'Add', style: { fontSize: "15px" } },
                onClick: () => this.CustomSecurityHandling.current.updateData(new SecurityData())
            }, {
                key: 'editItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
                onClick: () => this.editClick(this.state.selectedItem)
            }, {
                key: 'deleteItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
                onClick: () => this.deleteArchiveSystemClick(this.state.selectedItem)
            }]
    }

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    }

    private editClick = (item: SecurityData): void => {
        this.CustomSecurityHandling.current.updateData(item);
    }

    private deleteArchiveSystemClick = (item: SecurityData): void => {
        this.showDialog(item);
    }

    private showDialog = (item: SecurityData): void => {
        this.setState({ hideDialog: false, dialogMessage: `Wollen Sie die Sicherheitseinstellung für ${item.userAccount} wirklich löschen?`, itemToDelete: item });
    }

    private deleteConfirmed = (): void => {
        this.setState({ isLoading: true, hideDialog: true });
        ModusRequests.Post<any>("Permission/Delete", JSON.stringify(this.state.itemToDelete))
            .then((data: any) => {
                this.getSecurityData();
            });
    }

    private onSelectedItemParamClick = (pData: Array<SecurityData>): void => {
        if (pData.length === 0)
            this.setState({ viewItemMenuParam: "none" });
        else
            this.setState({ selectedItem: pData[0], viewItemMenuParam: "block" });
    }

    private confirm = (message: string): void => {
        if (message !== "") {
            this.setState({isLoading: true });
            this.getSecurityData();
        }
        this.CustomSecurityHandling.current.closeDialog();
    }
}