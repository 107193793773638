import * as React from "react";
import { Route } from "react-router-dom";
import ConfigurationItem from "./ConfigurationItem";
import { ctxt } from "../config/AppContext";

import "../css/navigation.css";
import "../css/ModusButtons.css";
import "../css/Checker.css";

export default class ConfigurationMenu extends React.Component<any, any> {
  private history: any;
  static contextType = ctxt;
  context: React.ContextType<typeof ctxt>;

  constructor(props: any) {
    super(props);
    this.state = {
      navItems: this.getNavLinks(),
      width: window.innerWidth <= 800 ? 48 : 200,
    };
  }

  public updateDimensions = () => {
    this.setState({ width: window.innerWidth <= 800 ? 48 : 200 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  private handleItemClick = (key: string, url: string): void => {
    this.context.setKey(key);
    this.history.push(url);
  };

  private setHistory = (history: any): void => {
    this.history = history;
  };

  public render() {
    const { key, setKey } = this.context!;
    return (
      <div className="modusActionBar settingsBar">
        <Route
          render={({ history }) => {
            this.setHistory(history);
            return (
              <div>
                {this.state.navItems.map((item) => (
                  <ConfigurationItem
                    key={item.key}
                    id={item.key}
                    width={this.state.width}
                    icon={item.icon}
                    selectedEvent={this.handleItemClick}
                    selected={key}
                    name={item.name}
                    url={item.altText}
                  />
                ))}
              </div>
            );
          }}
        />
      </div>
    );
  }

  private getNavLinks(): any[] {
    return [
      {
        name: "Sicherheit",
        key: "key5",
        icon: "Shield",
        altText: "/security",
        selected: false,
      },
      {
        name: "Übertragungsprotokoll",
        altText: "/protocols",
        key: "key6",
        icon: "ProtectedDocument",
        selected: false,
      },
      {
        name: "Suchprotokoll",
        altText: "/searchprotocols",
        key: "key7",
        icon: "ProtectedDocument",
        selected: false,
      },
      {
        name: "Import/Export",
        altText: "/import",
        key: "key8",
        icon: "CloudImportExport",
        selected: false,
      },
    ];
  }
}
