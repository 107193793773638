export interface IWizardModel {
    isConfigured: boolean;
    isSqlConnection: boolean;
    useAad: boolean;
    clientId: string;
    authority: string;
    scope: string;
}

export default class WizardModel implements IWizardModel {
    public isConfigured: boolean = false;
    public isSqlConnection: boolean = false;
    public useAad: boolean = false;
    public clientId: string = "";
    public authority: string = "";
    public scope: string = "";

    constructor() { }
}

export interface IConfigurationModel {
    authProvider: boolean;
    rebuildDatabase: boolean;
    tenantId: string;
    clientId: string;
    domain: string;

    sqlServer: string;
    sqlDatabase: string;
    sqlUser: string;
    sqlSecret: string;

    tenantIdError: string;
    clientIdError: string;
    secretError: string;
    domainError: string;

    sqlServerError: string;
    sqlDatabaseError: string;
    sqlUserError: string;
    sqlSecretError: string;

    authError: string;
    aadError: string;
    sqlError: string;
}

export class ConfigurationModel implements IConfigurationModel {
    public authProvider: boolean = true;
    public rebuildDatabase: boolean = true;
    public tenantId: string = "";
    public clientId: string = "";
    public domain: string = "";

    public sqlServer: string = "";
    public sqlDatabase: string = "";
    public sqlUser: string = "";
    public sqlSecret: string = "";

    public tenantIdError: string = "";
    public clientIdError: string = "";
    public secretError: string = "";
    public domainError: string = "";

    public sqlServerError: string = "";
    public sqlDatabaseError: string = "";
    public sqlUserError: string = "";
    public sqlSecretError: string = "";

    public authError: string = "";
    public aadError: string = "";
    public sqlError: string = "";

    constructor() { }
}

export class ConfigurationErrorModel {
    public isValid?: boolean;
    public errors?: { [key: string]: string };
    public okMessage?: { [key: string]: string };
    public configurationData?: any;

    constructor() { }
}