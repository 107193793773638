import * as React from 'react';
import { Panel, PanelType, Stack, DefaultButton, PrimaryButton, IChoiceGroupOption, ChoiceGroup, TextField } from '@fluentui/react';

import SystemData from '../classes/models/SystemData';
import SystemValidatedModel from '../classes/models/SystemValidatedModel';
import Modus from '../classes/CustomColumn';
import ModusRequests from '../classes/RequestHandler';

interface Props {
    confirmedEvent: (message: string) => void;
    history: any;
    startBusy: () => void;
    stopBusy: () => void;
}

export default class CustomSystemHandling extends React.Component<Props, any> {

    constructor(props: any) {
        super(props);
        this.state = { data: new SystemData() };
    }

    public render(): JSX.Element {
        return (
            <Panel
                isOpen={this.state.hideDialog}
                onDismiss={() => this.confirmDialog("")}
                type={PanelType.custom}
                customWidth={'500px'}
                onRenderFooterContent={this.onRenderFooterContent}
                isFooterAtBottom={true}
                styles={{ content: { height: "calc(100vh - 140px) !important", minHeight: "calc(100vh - 140px) !important" } }}
                headerText={
                    this.state.data.id > 0 ?
                        this.state.data.name + ' bearbeiten' : 'Neues System anlegen'
                } >
                <div style={{ minWidth: '200px', maxWidth: '600px' }}>
                    <div style={{
                        paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px',
                        borderColor: '#CFCDCC', borderStyle: 'solid', borderWidth: '0px'
                    }}>
                        <Stack tokens={{ childrenGap: 20 }}>
                            <TextField
                                label="Eindeutiger Name"
                                required={true}
                                errorMessage={this.state.inputNameErrorMessage}
                                validateOnLoad={false}
                                value={this.state.data.name}
                                onChange={this.textChange}
                                styles={{ root: { maxWidth: '100%' } }}
                                disabled={this.state.data.id > 0}
                            />
                            <div>
                                <TextField
                                    label="System ID"
                                    value={this.state.data.sourceSystemGuid}
                                    styles={{ root: { maxWidth: '100%' } }}
                                    disabled={true}
                                />
                            </div>
                            <ChoiceGroup
                                className="defaultChoiceGroup"
                                defaultSelectedKey={this.state.data.isOnline ? 'true' : 'false'}
                                options={[
                                    {
                                        key: 'true',
                                        text: 'Aktiv'
                                    } as IChoiceGroupOption,
                                    {
                                        key: 'false',
                                        text: 'Inaktiv'
                                    }
                                ]}
                                onChange={this.onChoiceGroupChange}
                                label="Zustand"
                            />
                        </Stack>
                    </div>
                </div>
            </Panel >
        );
    }

    public updateData = (data: SystemData): void => {
        data.sourceSystemGuid = data.id > 0 ? data.sourceSystemGuid : Modus.NewGuid();
        this.setState({ data: data, hideDialog: true, inputNameErrorMessage: ""});
    }

    public closeDialog = (): void => {
        this.setState({ hideDialog: false });
    }

    private confirmDialog = (message: string): void => {
        this.props.confirmedEvent(message);
    }

    private onRenderFooterContent = () => (
        <div style={{ minWidth: '200px', maxWidth: '600px' }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal >
                <Stack.Item align="start" grow >
                    <DefaultButton onClick={() => this.confirmDialog("")} text="Zurück ohne speichern" primaryDisabled={false} />
                </Stack.Item>
                <Stack.Item align="end" grow styles={{ root: { alignContent: "flex-end", flexGrow: 0 } }} >
                    <PrimaryButton onClick={this.saveClick} text="System speichern" />
                </Stack.Item>
            </Stack>
        </div>
    );

    private saveClick =(): void => {
        this.clearErrorMessages();
        ModusRequests.Post<SystemValidatedModel>("System/Post", JSON.stringify(this.state.data))
            .then((data: SystemValidatedModel) => {
                this.mapMessages(data);
            });
    }

    private clearErrorMessages = (): void => {
        this.props.startBusy();
        this.setState({ inputNameErrorMessage: ""})
    }

    private mapMessages = (data: SystemValidatedModel): void => {
        if (data.isValid)
            this.props.confirmedEvent(data.okMessage.Message);
        else
            this.mapErrorMessages(data.errors);
    }


    private mapErrorMessages = (messages: SystemValidatedModel): void => {
        if (messages.hasOwnProperty("Name"))
            this.setState({ inputNameErrorMessage: messages["Name"] });

        this.props.stopBusy();
    }

    private textChange = (eventData): void => {
        let newData = this.state.data;
        newData.name = eventData.target.value;
        this.setState({ data: newData });
    }

    private onChoiceGroupChange = (ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption): void => {
        let newData = this.state.data;
        newData.isOnline = option.key === "true"
        this.setState({ data: newData });
    };
}

