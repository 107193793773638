function getAbsoluteDomainUrl(): string {
    if (window
        && "location" in window
        && "protocol" in window.location
        && "host" in window.location)
        return window.location.protocol + "//" + window.location.host;
    return null;
}

export const msalConfig = {
    authority: 'https://login.microsoftonline.com/' + localStorage?.getItem("authority") ?? "",
    clientId: localStorage?.getItem("clientId") ?? "",
    redirectUri: getAbsoluteDomainUrl(),
    navigateToLoginRequestUrl: true
};

export const msalScope = {
    scopes: [
        localStorage?.getItem("scope") ?? ""
    ]
}