import * as React from 'react';
import { DetailsList, SelectionMode, Selection, TooltipHost, Icon, IContextualMenuProps, ContextualMenu, DirectionalHint, IContextualMenuItem, Stack, Label, Sticky, StickyPositionType, ScrollablePane, MarqueeSelection, ScrollbarVisibility } from '@fluentui/react';

import CustomFilterPanel from './CustomFilterPanel';
import Modus from '../classes/CustomColumn';

interface Props<T> {
    items?: Array<T>;
    columns?: Array<Modus.CustomColumn>;
    modalSelection: SelectionMode;
    onSelectEvent?: (pData: T) => void;
    onSelectedItemEvent?: (pData?: Array<T>) => void;
    heightHeader: number;
    compact?: boolean;
}

interface State<T> {
    contextualMenuProps?: IContextualMenuProps;
    columns?: Array<Modus.CustomColumn>;
    allItems?: Array<T>;
    items?: Array<T>;
}

export default class M365DetailList<T> extends React.Component<Props<T>, State<T>> {
    private filterPanel = React.createRef<CustomFilterPanel>();
    private selectionItems: Selection;

    constructor(props: any) {
        super(props);
        this.selectionItems = new Selection({
            onSelectionChanged: this.getSelectionDetails,
            getKey: (item: any) => item.id
        });

        this.state = {
            contextualMenuProps: undefined,
            items: this.props.items,
            allItems: this.props.items,
            columns: this.props.columns
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items && this.state.columns.filter(c => { return c.customColumnProps?.filteredValues?.some(x => x.selected) }).length > 0) {
            this.setState({ items: this.filter(this.state.columns, this.sortData(this.props.items)), allItems: this.props.items });
            //this.setState({ items: this.filter(this.state.columns, this.props.items), allItems: this.props.items });
            //this.selectionItems.setItems(this.state.items, true);
        }
        else if (prevProps.items !== this.props.items) {
            this.setState({ items: this.sortData(this.props.items), allItems: this.props.items });
            //this.setState({ items: this.props.items, allItems: this.props.items });
            //this.selectionItems.setItems(this.state.selectedItems, false);
        }
    }

    public render() {
        const { contextualMenuProps } = this.state;
        return (
            <div style={{
                height: "calc(100vh - " + this.props.heightHeader + "px)",
                position: 'relative', backgroundColor: 'white'
            }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <DetailsList
                        items={this.state.items}
                        columns={this.state.columns}
                        selection={this.selectionItems}
                        selectionMode={this.props.modalSelection}
                        setKey="set"
                        compact={this.props.compact}
                        onItemInvoked={this.onItemInvoked}
                        onRenderDetailsHeader={(props, defaultRender) => this.onRenderDetailsHeader(props, defaultRender)}
                    />

                    {!this.state.items.length && (
                        <Stack horizontalAlign='center'>
                            <Label>Es wurden keine Daten gefunden</Label>
                        </Stack>
                    )}
                    {this.state.contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
                    <CustomFilterPanel
                        ref={this.filterPanel} key={"m365Panel"} onSelectEvent={this.filterChangedEvent}
                    ></CustomFilterPanel></ScrollablePane>
            </div>)
    }

    private onRenderDetailsHeader(props, defaultRender?) {
        return <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                    if (tooltipHostProps.column !== undefined) {
                        if (tooltipHostProps.column.customColumnProps !== undefined) {
                            if (tooltipHostProps.column.customColumnProps.enableFilter || tooltipHostProps.column.customColumnProps.enableSort) {
                                return (<div onClick={(ev) => this.onColumnClick(ev, tooltipHostProps.column)}>
                                    <TooltipHost  {...tooltipHostProps} />
                                    <Icon iconName={"ChevronDown"} style={{ color: '#605e5c', float: 'right' }} />
                                </div>)
                            }
                        }
                    }
                    return (<TooltipHost  {...tooltipHostProps} />)
                }
            })}
        </Sticky>
    }

    private getSelectionDetails = (): void => {
        if (this.props.onSelectedItemEvent !== undefined) {
            const selectionCount = this.selectionItems.getSelectedCount();
            switch (selectionCount) {
                case 0:
                    this.props.onSelectedItemEvent([]);
                    break;
                case 1:
                    let result = Array<T>();
                    result.push(this.selectionItems.getSelection()[0] as T);
                    this.props.onSelectedItemEvent(result);
                    break;
                default:
                    this.props.onSelectedItemEvent(this.selectionItems.getSelection() as Array<T>);
            }
        }
    }

    private onItemInvoked = (pData: T): void => {
        if (this.props.onSelectEvent !== undefined)
            this.props.onSelectEvent(pData);
    }

    private onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: Modus.CustomColumn): void => {
        this.setState({ contextualMenuProps: this.setMenuProps(ev, column) });
    };

    private filterChangedEvent = (column: Modus.CustomColumn, allSelected?: boolean): void => {
        let data = Array<any>();
        let columns = this.state.columns;

        if (allSelected)
            column.customColumnProps.filteredValues.forEach(i => i.selected = false);
        columns.find(c => c.key === column.key).customColumnProps = column.customColumnProps;
        columns.find(c => c.key === column.key).isFiltered = column.customColumnProps.filteredValues.filter(x => x.selected).length > 0;

        if (columns.filter(y => y.isFiltered).length > 0) {
            data = this.filter(columns, this.state.allItems);
        }
        else
            data = this.state.allItems;

        this.setState({ items: this.sortData(data), columns: columns });
    }

    private sortData = (data: Array<any>): Array<any> => {
        let sortedColumn = this.state.columns.find(x => x.isSorted);
        if (sortedColumn !== undefined)
            return this.sortFilteredColumn(data, sortedColumn);
        else
            return data;
    }

    private filter = (columns: Array<Modus.CustomColumn>, dataToFilter: Array<any>): Array<any> => {
        let data = Array<any>();
        columns.forEach(c => {
            let filterElement = c.customColumnProps?.filteredValues?.filter(x => x.selected);
            if (filterElement !== undefined && filterElement.length > 0) {
                let checker = false;
                filterElement.forEach(y => {
                    if (checker)
                        data = data.concat(this.filterData(dataToFilter, c.fieldName, y.value, c.customColumnProps.filterType, c.customColumnProps.displayValues?.find(x => !x.key)?.text ?? ''));
                    else {
                        data = this.filterData(dataToFilter, c.fieldName, y.value, c.customColumnProps.filterType, c.customColumnProps.displayValues?.find(x => !x.key)?.text ?? '');
                        checker = true;
                    }
                });
                dataToFilter = data;
            }
        });
        return data;
    }

    private filterData = (data: any, itemName: string, filterValue: string, type: Modus.FilterType, errorText?: string): any => {
        switch (type) {
            case Modus.FilterType.textFilter:
                return data.filter(x => x[itemName].toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
            case Modus.FilterType.dateRangeFilter:
                if (filterValue.indexOf(';') > 0) {
                    let startDate = this.convertDate(filterValue.split(';')[0]);
                    let endDate = this.convertDate(filterValue.split(';')[1]);
                    return data.filter(x => this.convertDate(x[itemName].split(" ")[0]) >=
                        startDate && this.convertDate(x[itemName].split(" ")[0]) <= endDate);
                }
                return data.filter(x => x[itemName].toLowerCase().indexOf(filterValue.toLowerCase()) > -1);
            case Modus.FilterType.booleanFilter:
                return data.filter(x => x[itemName] !== (filterValue === errorText));
            case Modus.FilterType.distinctFilter:
                return data.filter(x => x[itemName] === filterValue);
        }
    }

    private convertDate = (dateString: string): Date => {
        return new Date(dateString.split('.')[2] + '-' + dateString.split('.')[1] + '-' + dateString.split('.')[0]);
    }

    private setMenuProps = (ev: React.MouseEvent<HTMLElement>, column: Modus.CustomColumn): IContextualMenuProps => {
        let item: Array<IContextualMenuItem> = Array<IContextualMenuItem>();
        if (column.customColumnProps !== undefined) {
            if (column.customColumnProps.enableSort) {
                item.push({
                    key: 'sortAscending',
                    text: 'Aufsteigend',
                    iconProps: { iconName: 'Up' },
                    onClick: () => this.sortColumn(column, "Ascending"),
                });
                item.push({
                    key: 'sortDescending',
                    text: 'Absteigend',
                    iconProps: { iconName: 'Down' },
                    onClick: () => this.sortColumn(column, "Descending"),
                });
            }
            if (column.customColumnProps.enableFilter) {
                let disable = Array.from(new Set(this.state.items.map((item: Object) => item[column.fieldName]))).length > 1 ? false : true;
                item.push({
                    key: 'filter',
                    text: 'Filtern nach',
                    iconProps: { iconName: 'Filter' },
                    onClick: () => this.setFilterPanel(column),
                    disabled: column.lastFiltered ? false : disable,
                });
                item.push({
                    key: 'clearFilter',
                    text: 'Filter löschen',
                    iconProps: { iconName: 'ClearFilter' },
                    onClick: () => this.clearColumnFilter(column),
                    disabled: !column.isFiltered
                });
            }
        }
        let menuprops: IContextualMenuProps = {
            items: item,
            target: ev.currentTarget as HTMLElement,
            directionalHint: DirectionalHint.bottomRightEdge,
            isBeakVisible: false,
            onDismiss: this.onContextualMenuDismissed,
            gapSpace: 40
        };
        return menuprops;
    }

    private setFilterPanel = (column: Modus.CustomColumn): void => {
        if (!column.lastFiltered) {
            this.updateLastFiltered(column);
            switch (column.customColumnProps.filterType) {
                case Modus.FilterType.textFilter:
                    if (column.customColumnProps.filteredValues === undefined)
                        column.customColumnProps.filteredValues = new Array<Modus.FilterValue>();
                    break;
                case Modus.FilterType.dateRangeFilter:
                    if (column.customColumnProps.filteredValues === undefined)
                        column.customColumnProps.filteredValues = new Array<Modus.FilterValue>();
                    break;
                case Modus.FilterType.booleanFilter:
                    let index = 0;
                    let boolDistinctValues = Array.from(new Set(this.state.items.map(item => item[column.fieldName])));
                    column.customColumnProps.filteredValues = boolDistinctValues.map(value => {
                        return {
                            key: index++,
                            value: column.customColumnProps.displayValues?.find(x => x.key === value)?.text,
                            selected: column.customColumnProps.filteredValues.find(x => x.value === value)?.selected
                        }
                    });
                    break;
                default:
                    if (column.customColumnProps.filteredValues === undefined)
                        column.customColumnProps.filteredValues = new Array<Modus.FilterValue>();
                    let distinctValues: Array<string> = Array.from(new Set(this.state.items.map(item => item[column.fieldName])));
                    column.customColumnProps.filteredValues = this.generateFilterList(distinctValues, column.customColumnProps.filteredValues);
            }
        }

        this.filterPanel.current.updateData(column);
    }

    private clearColumnFilter = (column: Modus.CustomColumn): void => {
        //column.customColumnProps.filteredValues = Array<any>();
        column.lastFiltered = false;
        this.filterChangedEvent(column, true);
    }

    private updateLastFiltered = (column: Modus.CustomColumn): void => {
        let columns = this.state.columns;
        columns.forEach(column => column.lastFiltered = false);
        columns.find(c => c.key === column.key).lastFiltered = true;
        this.setState({ columns: columns });
    }

    private generateFilterList = (filterList: Array<string>, oldList: Array<Modus.FilterValue>): Array<Modus.FilterValue> => {
        let index: number = 0;
        return filterList.map(value => {
            return {
                key: index++,
                value: value,
                selected: oldList.find(x => x.value === value)?.selected
            }
        });
    }

    private onContextualMenuDismissed = (): void => {
        this.setState({ contextualMenuProps: undefined });
    };

    private sortColumn = (column: Modus.CustomColumn, orderDirection: string): void => {
        let newColumns: Array<Modus.CustomColumn> = this.state.columns.slice();
        let currColumn: Modus.CustomColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: Modus.CustomColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = orderDirection !== 'Ascending';
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        let newItems = this._copyAndSort(this.state.items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({ columns: newColumns, items: newItems });
    };

    private sortFilteredColumn<T>(items: Array<T>, currColumn: Modus.CustomColumn): Array<T> {
        let newItems = this._copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        return newItems;
    };

    private _copyAndSort<T>(items: Array<T>, columnKey: string, isSortedDescending?: boolean): Array<T> {
        let key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
}