import * as React from "react";
import { Component } from "react";
import {
  Customizations,
  IconButton,
  Panel,
  PanelType,
  Persona,
  Link,
  Label,
  Icon,
  PersonaSize,
} from "@fluentui/react";
import ModusRequests from "../../classes/RequestHandler";
import ConfigurationMenu from "../../standard/ConfigurationMenu";
import "../../css/navigation.css";
import { styles } from "@microsoft/mgt/dist/es6/components/mgt-people-picker/mgt-people-picker-css";
import { Button } from "antd/lib/radio";

interface currentUser {
  displayName: string;
  image: string;
}

interface Props {
  loadPersona: boolean;
  isAuthorized: boolean;
}

export default class NavMenu extends Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      personData: { displayName: "", image: "" },
      version: "",
      isVisibleSidebar: false,
      isModalOpen: false,
      state: 1,
      task: "",
      isOpen: false,
      isSettingsOpen: false,
    };
    this.getManifest();
    if (this.props.loadPersona)
      ModusRequests.Get<currentUser>("User/CurrentUser").then((user) => {
        this.setState({ personData: user });
      });
  }

  public render() {
    let theme = Customizations.getSettings(["theme"]).theme;
    return (
      <div className="App">
        <header>
          <div
            className="menu-band"
            style={{ backgroundColor: theme.palette.themePrimary }}
          >
            <img
              src="../images/Logo.png"
              width="58"
              height="48"
              alt="MODUS Consult"
              className="modusImage"
            />
            <span className="menu-name">M365 UniversalLink</span>
            {this.state.personData.image === "" && this.props.isAuthorized && (
              <div
                style={{ position: "absolute", display: "flex", right: "0px" }}
              >
                <IconButton
                  iconProps={{ iconName: "Settings" }}
                  styles={{
                    root: {
                      color: "white",
                      width: "48px",
                      height: "48px",
                      borderRadius: "0px",
                    },
                    icon: {
                      fontSize: "20px",
                      lineHeight: "16px",
                    },
                  }}
                  onClick={this.settingsClick}
                  checked={this.state.isSettingsOpen}
                />
                <IconButton
                  iconProps={{ iconName: "Unknown" }}
                  styles={{
                    root: {
                      color: "white",
                      width: "48px",
                      height: "48px",
                      borderRadius: "0px",
                    },
                    icon: {
                      fontSize: "20px",
                      lineHeight: "16px",
                    },
                  }}
                  onClick={this.helpClick}
                  checked={this.state.isOpen}
                />
                <Persona
                  text={this.state.personData.displayName}
                  hidePersonaDetails
                  imageUrl={this.state.personData.image}
                />
              </div>
            )}
            {this.state.personData.image !== "" && this.props.isAuthorized && (
              <div
                style={{ position: "absolute", display: "flex", right: "8px" }}
              >
                <IconButton
                  iconProps={{ iconName: "Settings" }}
                  styles={{
                    root: {
                      color: "white",
                      width: "48px",
                      height: "48px",
                      borderRadius: "0px",
                    },
                    icon: {
                      fontSize: "20px",
                      lineHeight: "16px",
                    },
                  }}
                  onClick={this.settingsClick}
                  checked={this.state.isSettingsOpen}
                />
                <IconButton
                  iconProps={{ iconName: "Unknown" }}
                  styles={{
                    root: {
                      color: "white",
                      width: "48px",
                      height: "48px",
                      borderRadius: "0px",
                    },
                    icon: {
                      fontSize: "20px",
                      lineHeight: "16px",
                    },
                  }}
                  onClick={this.helpClick}
                  checked={this.state.isOpen}
                />

                <span
                  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    lineHeight: "48px",
                    textAlign: "left",
                    paddingLeft: "12px",
                    paddingRight: "4px",
                  }}
                >
                  {this.state.personData.displayName}
                </span>
                <Persona
                  text={this.state.personData.displayName}
                  hidePersonaDetails
                  size={PersonaSize.size48}
                  imageUrl={this.state.personData.image}
                />
              </div>
            )}
          </div>
        </header>
        <Panel
          headerText="Hilfe"
          // this prop makes the panel non-modal
          //isBlocking={false}
          isLightDismiss
          onDismiss={this.closePanel}
          isOpen={this.state.isOpen}
          closeButtonAriaLabel="Bereich schließen"
          customWidth={"305px"}
          type={PanelType.custom}
          style={{ top: "48px", height: "calc(100vh - 48px)" }}
        >
          <p>{""}</p>
          <p>
            {"Version: "}
            {this.state.version}
          </p>
          <p>{""}</p>

          {/*<p>*/}
          {/*    <Link href="https://www.ModusConsult.de" underline={false} target={"_blank"} color={"#106EBE"}>*/}
          {/*        Dokumentation*/}
          {/*    </Link>*/}
          {/*</p>*/}

          <div
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "rgba(243, 242, 241, 0.5)",
              WebkitBoxShadow: "0 2px 4px -0.8px rgb(0 0 0 / 10%)",
              boxShadow: "0 2px 4px -0.8px rgb(0 0 0 / 10%)",
              margin: "36px 0",
            }}
          >
            <Label>IT Support</Label>
            <div style={{ display: "flex", height: "30px" }}>
              <Icon iconName={"Phone"} style={{ width: "25px" }} />
              <Link
                href="tel:+4952419217210"
                underline={false}
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                +4952419217210
              </Link>
            </div>
            <div style={{ display: "flex", height: "30px" }}>
              <Icon iconName={"Mail"} style={{ width: "25px" }} />
              <Link
                href="mailto:it-support@modusconsult.de"
                underline={false}
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                it-support@modusconsult.de
              </Link>
            </div>
            <div style={{ display: "flex", height: "30px" }}>
              <Icon iconName={"Globe"} style={{ width: "25px" }} />
              <Link
                href="https://modussupport.powerappsportals.com/de-DE/"
                underline={false}
                target={"_blank"}
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                Helpdesk
              </Link>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              marginLeft: "-24px",
              paddingLeft: "24px",
              position: "absolute",
              bottom: "0px",
              borderTopWidth: "1px",
              borderTopStyle: "solid",
            }}
          >
            <Link
              href="https://www.modusconsult.de/fileadmin/Redaktion/PDF/Allgemeine_Geschaeftsbedingungen_der_MODUS_Consult_GmbH.pdf"
              underline={false}
              target={"_blank"}
              style={{ fontSize: "12px" }}
            >
              Rechtliche Hinweise
            </Link>
            <span style={{ fontSize: "12px" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <Link
              href="https://www.modusconsult.de/datenschutz"
              underline={false}
              target={"_blank"}
              style={{ fontSize: "12px" }}
            >
              Datenschutz
            </Link>
          </div>
        </Panel>
        <Panel
          headerText="Einstellungen"
          // this prop makes the panel non-modal
          //isBlocking={false}
          isLightDismiss
          onDismiss={this.closeSettingsPanel}
          isOpen={this.state.isSettingsOpen}
          closeButtonAriaLabel="Bereich schließen"
          customWidth={"305px"}
          type={PanelType.custom}
          style={{ top: "48px", height: "calc(100vh - 48px)" }}
        >
          <ConfigurationMenu />
        </Panel>
      </div>
    );
  }

  private getManifest = (): void => {
    let manifestElement: any = document.getElementById("manifest");
    fetch(manifestElement["href"] + "?v1")
      .then((response) => response.json())
      .then((data) => this.getVersion(data))
      .catch((error) => console.error(error));
  };

  private getVersion = (data: any): void => {
    if (data.hasOwnProperty("description")) {
      this.setState({ version: data["description"] });
    }
  };

  private helpClick = (): void => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private closePanel = (): void => {
    this.setState({ isOpen: false });
  };

  private settingsClick = (): void => {
    this.setState({ isSettingsOpen: !this.state.isSettingsOpen });
  };

  private closeSettingsPanel = (): void => {
    this.setState({ isSettingsOpen: false });
  };
}
