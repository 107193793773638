
export default {
    marginTop: 0,
    float: "left",
    width: '100%',

    control: {
        backgroundColor: '#fff',
        fontSize: 14,
        borderRadius: '2px',
        fontWeight: 'normal',
        border: '1px solid rgb(138, 136, 134)',
        display: 'inline-block',
        width: '100%'
    },

    controlDisabled: {
        marginTop: 0,
        float: "left",
        width: '100%',
        
        highlighter: {
            overflow: 'hidden'
        },

        input: {
            margin: 0,
            color: "rgb(161, 159, 157)",
            fontColor: '-webkit-font-smoothing: antialiased',
            padding: 5,
            paddingLeft: 9,
            maxHeight: 30,
            outline: 0,
            border: 0
        },

        control: {
            backgroundColor: 'rgb(243, 242, 241)',
            fontSize: 14,
            borderRadius: '2px',
            fontWeight: 'normal',
            display: 'inline-block',
            border: '1px solid rgb(243, 242, 241)',
            width: '100%'
        }
    },

    controlError: {
        marginTop: 0,
        float: "left",
        width: '100%',

        highlighter: {
            overflow: 'hidden'
        },

        input: {
            margin: 0,
            fontColor: '-webkit-font-smoothing: antialiased',
            padding: 5,
            paddingLeft: 9,
            maxHeight: 30,
            outline: 0,
            border: 0
        },

        control: {
            backgroundColor: '#fff',
            fontSize: 14,
            borderRadius: '2px',
            fontWeight: 'normal',
            display: 'inline-block',
            border: '1px solid rgb(164, 38, 44)',
            width: '100%'
        },
        suggestions: {
            list: {
                backgroundColor: 'white',
                border: '1px solid rgba(0,0,0,0.15)',
                fontSize: 14,
            },

            item: {
                padding: '5px 15px',
                borderBottom: '1px solid rgba(0,0,0,0.15)',

                '&focused': {
                    backgroundColor: '#e6e6e6'
                },
            },
        }
    },

    highlighter: {
        overflow: 'hidden'
    },

    input: {
        margin: 0,
        fontColor: '-webkit-font-smoothing: antialiased',
        padding: 5,
        paddingLeft:9,
        maxHeight: 30,
        outline: 0,
        border: 0
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
        },

        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',

            '&focused': {
                backgroundColor: '#e6e6e6'
            },
        },
    },
}