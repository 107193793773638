import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';

interface Props {
    hideDialog: boolean;
    dialogMessage: string;
    closeEvent: () => void;
    confirmedEvent: () => void;
}

export default class CustomSaveChangesDialog extends React.Component<Props, any> {
    props: any;

    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <Dialog
                hidden={this.props.hideDialog}
                onDismiss={this.closeDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Änderungen speichern?",
                    subText: this.props.dialogMessage
                }} modalProps={{ isBlocking: true }} >
                <DialogFooter>
                    <DefaultButton className="ms-Cancel-Button" onClick={this.closeDialog} text="Nicht speichern" primaryDisabled={false} />
                    <PrimaryButton className="ms-Default-Button2" onClick={this.deleteConfirmed} text="Speichern" />
                </DialogFooter>
            </Dialog>
        );
    } 

    private closeDialog = (): void => {
        this.props.closeEvent();
    }

    private deleteConfirmed = (): void => {
        this.props.confirmedEvent();
    }
}