import * as React from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { Spinner } from '@fluentui/react/lib/Spinner';

interface Props {
    isLoading?: boolean;
    labelText: string;
}

interface State {
}

export default class WaitingIndicator extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div>
                <Modal isOpen={this.props.isLoading} isBlocking={true} styles={{ main: {padding:"2%"}}}>
                    <div style={{ width: '400px', height: '120px', paddingTop: '15px' }}>
                        <Spinner styles={{ circle: { height: 64, width: 64 } }} label={this.props.labelText} ariaLive="assertive" labelPosition="bottom" />
                    </div>
                </Modal>
            </div>
        );
    }

}

