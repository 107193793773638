import { msalScope } from "../msal/msalConfig";
import { M365Auth } from "../hoc/Auth";
import { SilentRequest } from "@azure/msal-browser";

export namespace ModusRequests {
  function GetBaseUrl() {
    if (window.location.hostname === "localhost" && window.location.port === "3000") {
      return "https://localhost:44380/";
    } else {
      return "";
    }
  }

  export function Get<T>(url: string): Promise<T> {
    return new Promise(async (resolve, reject) => {
      fetch(`${GetBaseUrl()}api/${url}`, await getOptions())
        .then((response) => response.json())
        .then((data: T) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  export function GetData<T>(url: string): Promise<Array<T>> {
    return new Promise(async (resolve, reject) => {
      fetch(`${GetBaseUrl()}api/${url}`, await getOptions())
        .then((response) => response.json())
        .then((data: Array<T>) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  export function GetJson(url: string, body: string): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      fetch(`${GetBaseUrl()}api/${url}`, await getPostOptions(body))
        .then((response) => response.blob())
        .then((data: Blob) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  export function Post<T>(url: string, body: string): Promise<T> {
    return new Promise(async (resolve, reject) => {
      fetch(`${GetBaseUrl()}api/${url}`, await getPostOptions(body))
        .then((response) => response.json())
        .then((data: T) => resolve(data))
        .catch((error) => reject(error));
    });
  }

  export function Export<T>(url: string, body: string): Promise<Array<T>> {
    return new Promise(async (resolve, reject) => {
      fetch(`${GetBaseUrl()}api/${url}`, await getPostOptions(body)).then((response) => {
        if (response.status === 200) return resolve(response.json());
        return Promise.resolve(response.json()).then((response) => {
          return reject(response);
        });
      });
    });
  }

  export async function getTokenAppService(): Promise<string> {
    let acc = M365Auth.getAllAccounts()[0];
    let tokenRequest: SilentRequest = {
      account: acc,
      scopes: msalScope.scopes,
    };
    let accessToken = null;
    try {
      accessToken = await M365Auth.acquireTokenSilent(tokenRequest);
    } catch (error) {
      accessToken = await M365Auth.acquireTokenRedirect(tokenRequest);
    }
    return "Bearer " + accessToken.accessToken;
  }

  export async function getTokenGraphService(
    scopes: string[]
  ): Promise<string> {
    let acc = M365Auth.getAllAccounts()[0];
    let tokenRequest: SilentRequest = {
      account: acc,
      scopes: scopes,
    };
    let accessToken = null;
    try {
      accessToken = await M365Auth.acquireTokenSilent(tokenRequest);
    } catch (error) {
      accessToken = await M365Auth.acquireTokenRedirect(tokenRequest);
    }
    return accessToken.accessToken;
  }

  async function getOptions(): Promise<{}> {
    let result: boolean;
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(
        localStorage.getItem("useAadAuthentication") ?? "false"
      );
    }
    return result
      ? {
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: await getTokenAppService(),
          }),
        }
      : { redirect: "follow" };
  }

  async function getPostOptions(body: string): Promise<{}> {
    let result: boolean;
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(
        localStorage.getItem("useAadAuthentication") ?? "false"
      );
    }
    return result
      ? {
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: await getTokenAppService(),
          }),
          method: "post",
          body: body,
        }
      : {
          headers: new Headers({
            "Content-Type": "application/json",
          }),
          redirect: "follow",
          method: "post",
          body: body,
        };
  }

  async function getDeleteOptions(): Promise<{}> {
    let result: boolean;
    if (
      localStorage !== undefined &&
      localStorage.hasOwnProperty("useAadAuthentication")
    ) {
      result = JSON.parse(
        localStorage.getItem("useAadAuthentication") ?? "false"
      );
    }
    return result
      ? {
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: await getTokenAppService(),
          }),
          method: "post",
        }
      : {
          redirect: "follow",
          method: "post",
        };
  }
}

export default ModusRequests;
