import * as React from 'react';
import ProtocolData from '../classes/models/ProtocolData';
import { Panel, Stack, Label, Icon, PanelType, PivotItem, Pivot, SelectionMode } from '@fluentui/react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import Modus from '../classes/CustomColumn';
import M365DetailList from './M365DetailList';

interface props {
    proData: ProtocolData;
    closeEvent: () => void;
    history: any;
    systemData: Array<any>;
    archiveData: Array<any>;
}


export default class CustomProtocolPanel extends React.Component<props, any> {
    private item: ProtocolData = null;

    public render() {
        return (
            <Panel
                isBlocking={false}
                isOpen={true}
                type={PanelType.custom}
                customWidth={'500px'}
                onDismiss={this.closeDialog}
                styles={{ content: { height: "calc(100vh - 140px) !important", minHeight: "calc(100vh - 140px) !important" } }}
                closeButtonAriaLabel="Close">
                <div style={{ paddingLeft: '20px', display: 'flex', flexWrap: 'wrap' }}>
                    <Icon {...getFileTypeIconProps({ extension: this.props.proData.fileIcon, size: 48, imageFileType: 'png' })} />
                    <Label style={{ marginLeft: '10px', fontSize: '20px', color: '#323130', fontWeight: 'bold' }}>{this.props.proData.fileName}</Label>
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                    <Pivot>
                        <PivotItem headerText="Übersicht" >
                            <div style={{ minWidth: '200px', maxWidth: '600px' }}>
                                <div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px' }}>
                                    <Stack tokens={{ childrenGap: 20 }}>
                                        <Label >System <div style={{ color: '#201f1e', fontWeight: 'normal' }}>{this.props.proData.sourceSystemName}</div></Label>
                                        <Label >DMS <div style={{ color: '#201f1e', fontWeight: 'normal' }}>{this.props.proData.destinationSystemName}</div></Label>
                                        <Label >Zuordnung <div style={{ color: '#201f1e', fontWeight: 'normal' }}>{this.props.proData.documentType}</div></Label>
                                        <Label >Datum <div style={{ color: '#201f1e', fontWeight: 'normal' }}>{this.props.proData.displayDate}</div></Label>
                                        <Label >Fehlermeldung <div style={{ color: '#201f1e', fontWeight: 'normal' }}>{this.props.proData.errorMessage}</div></Label>
                                    </Stack>
                                </div>
                            </div>
                        </PivotItem>
                        {(this.props.systemData.length > 0) &&
                            <PivotItem headerText="System Parameter" >
                                <M365DetailList
                                    heightHeader={210}
                                    items={this.props.systemData}
                                    columns={this.setSysColumns()}
                                    modalSelection={SelectionMode.none}
                                />
                            </PivotItem>}
                        {(this.props.archiveData.length > 0) &&
                            <PivotItem headerText="DMS Parameter" >
                                <M365DetailList
                                    heightHeader={210}
                                    items={this.props.archiveData}
                                    columns={this.setArcColumns()}
                                    modalSelection={SelectionMode.none} />
                            </PivotItem>}
                    </Pivot>
                </div>
            </Panel>);
    }

    public closeDialog = (): void => {
        this.props.closeEvent();
    }

    private setSysColumns = (): Array<Modus.CustomColumn> => {
        return [
            {
                key: 'sysName', name: 'Name', fieldName: 'Key', minWidth: 100,
                maxWidth: 150, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(null, false, true)
            }, {
                key: 'sysValue', name: 'Wert', fieldName: 'Value', minWidth: 150, maxWidth: 200,
                isResizable: true, data: "string"
            }
        ];
    }

    private setArcColumns = (): Array<Modus.CustomColumn> => {
        return [
            {
                key: 'arcName', name: 'Name', fieldName: 'Key', minWidth: 100,
                maxWidth: 150, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(null, false, true)
            }, {
                key: 'arcType', name: 'Typ', fieldName: 'Type', minWidth: 50, maxWidth: 100,
                isResizable: true, data: "string",
                customColumnProps: new Modus.CustomColumnProps(null, false, true)
            }, {
                key: 'arcValue', name: 'Wert', fieldName: 'Value', minWidth: 150, maxWidth: 200,
                isResizable: true, data: "string"
            }
        ];
    }
}