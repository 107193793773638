/// <reference path="../../interfaces/models/IMappingData.ts"/>

class MappingData {
    public id?: number = 0;
    public documentTypeId?: number = 0;
    public name?: string = "";
    public value?: string = "";
    public mappingValue: string = "";
    public useAsFolder?: boolean = false;
    public isFormula?: boolean = false;
    public isPrimaryKey?: boolean = false;
    public formulaType?: number = 0;
    public formatting?: number = 0;
    public length?: number = 0;
    public hasError?: boolean = false;
    public sequence?: number = 0;
    public userName?: string = "";
    public inputNameErrorMessage?: string = "";
    public inputMappingErrorMessage?: string = "";
    public isSharepoint?: boolean = false;
    public isShareflex?: boolean = false;
    public isSelected?: boolean = false;
    public showPrimaryKey?: boolean = false;
    public availableInFileRepository?: boolean = false;
    public availableInDocumentRepository?: boolean = false;

    constructor() { }
}

export default MappingData; 
