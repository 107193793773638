import * as React from 'react';
import Dropzone from "react-dropzone";

import '../../css/Mention.css'; 

export default function CustomDropzone({ handleFileAdded }) {

    const handleDrop = async(acceptedFiles) => {
        if (acceptedFiles[0] !== undefined)
            handleJsonFileAdded(acceptedFiles[0]);
    };
    const handleJsonFileAdded = handleFileAdded;

    return (
        <div className="App" style={{ maxWidth: "420px" }}>
            <Dropzone 
                onDrop={handleDrop} accept="application/json" minSize={0} maxSize={3072000} >
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }
                ) => {
                    const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject" : "";
                    return (
                        <div {...getRootProps({ className: `dropzone ${additionalClass}`})} >
                            <input {...getInputProps()} />
                            <img src='../images/Json.png' width="48" height="48" alt="File Image" />
                            <p>Drag&Drop JSON-Datei,</p>
                            <p>oder klicken Sie, um die Datei auszuwählen</p>
                        </div>
                    );
                }}
            </Dropzone>
        </div>
    );
}