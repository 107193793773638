
class ImportData {
    public Archive?: Array<ImportArchiveData>;
    public Connectors?: Array<ImportDocumentTypeData>;
    public Systems?: Array<ImportSystemData>;
    public Results?: Array<ImportResultData>;

    constructor() { }
}

export class ImportResultData {
    public Id?: number = 0;
    public DocumentTypeId?: number = 0;
    public Sequence?: number = 0;
    public ResultRowName?: string = "";

    constructor() { }
}

export class ImportArchiveData {
    public Id?: number = 0;
    public Name?: string = "";
    public PlatformId?: number = 0;
    public BaseUrl?: string = "";
    public IsOnline?: boolean = false;

    constructor() { }
}

export class ImportSystemData {
    public Id?: number = 0;
    public Name?: string = "";
    public SourceSystemGuid?: string = "";
    public IsOnline?: boolean = false;

    constructor() { }
}

export class ImportDocumentTypeData {
    public Id?: number = 0;
    public SystemId?: number = 0;
    public ArchiveSystemId?: number = 0;
    public SystemName?: string = "";
    public ArchiveSystemName?: string = "";
    public DocumentTypeName?: string = "";
    public ContentTypeId?: string = "";
    public ContentTypeName?: string = "";
    public UseContentTypes?: boolean = false;
    public IsOnline?: boolean = false;
    public Mappings?: Array<ImportMappingData> = [];
    public AvailableParams?: string = "";
    public AvailableParamKeys?: Array<string> = [];
    public StartUrl?: string = "";
    public DocumentLibrary?: string = "";
    public DocumentLibraryName?: string = "";
    public ListLibrary?: string = "";
    public ListLibraryName?: string = "";
    public IsMapped: boolean = false; 
    public IsSharepoint: boolean = false;
    public IsShareflex: boolean = false;

    constructor() { }
}

class ImportMappingData {
    public Id?: number = 0;
    public DocumentTypeId?: number = 0;
    public Name?: string = "";
    public Value?: string = "";
    public MappingValue?: string = "";
    public Sequence?: number = 0;
    public UseAsFolder?: boolean = false;
    public IsPrimaryKey?: boolean = false;
    public IsFormula?: boolean = false;
    public FormulaType?: number = 0;
    public Formatting?: number = 0;
    public Length?: number = 0;
    public UserName?: string = "";

    constructor() { }
}

export default ImportData; 