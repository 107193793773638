import { IColumn } from "@fluentui/react/lib/DetailsList";
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

export namespace Modus {
    export class CustomColumn implements IColumn {
        key: string;
        name: string;
        fieldName?: string;
        className?: string;
        styles?: import("@fluentui/react").IStyleFunctionOrObject<import("@fluentui/react/lib/DetailsList").IDetailsColumnStyleProps, import("@fluentui/react/lib/DetailsList").IDetailsColumnStyles>;
        minWidth: number;
        ariaLabel?: string;
        isRowHeader?: boolean;
        maxWidth?: number;
        columnActionsMode?: import("@fluentui/react/lib/DetailsList").ColumnActionsMode;
        iconName?: string;
        isIconOnly?: boolean;
        iconClassName?: string;
        isCollapsable?: boolean;
        isCollapsible?: boolean;
        isSorted?: boolean;
        isSortedDescending?: boolean;
        isResizable?: boolean;
        isMultiline?: boolean;
        onRender?: (item?: any, index?: number, column?: IColumn) => any;
        getValueKey?: (item?: any, index?: number, column?: IColumn) => string;
        onRenderDivider?: import("@fluentui/react").IRenderFunction<import("@fluentui/react/lib/DetailsList").IDetailsColumnProps>;
        isFiltered?: boolean;
        onColumnClick?: (ev: import("react").MouseEvent<HTMLElement, MouseEvent>, column: IColumn) => void;
        onColumnContextMenu?: (column?: IColumn, ev?: import("react").MouseEvent<HTMLElement, MouseEvent>) => void;
        onColumnResize?: (width?: number) => void;
        isGrouped?: boolean;
        data?: any;
        calculatedWidth?: number;
        currentWidth?: number;
        headerClassName?: string;
        isPadded?: boolean;
        sortAscendingAriaLabel?: string;
        sortDescendingAriaLabel?: string;
        groupAriaLabel?: string;
        filterAriaLabel?: string;
        isMenuOpen?: boolean;
        customColumnProps?: CustomColumnProps;
        lastFiltered?: boolean;
    }

    export class CustomColumnProps {
        filterType?: FilterType;
        enableFilter: boolean;
        enableSort: boolean;
        filteredValues?: Array<FilterValue>;
        displayValues?: Array<DisplayValue>;

        constructor(p_FilterType: FilterType, p_enableFilter: boolean, p_enableSort: boolean,
            p_FilteredValues?: Array<FilterValue>, p_displayValues?: Array<DisplayValue>) {
            this.filterType = p_FilterType;
            this.filteredValues = p_FilteredValues;
            this.displayValues = p_displayValues;
            this.enableFilter = p_enableFilter;
            this.enableSort = p_enableSort;
        }
    }

    export class FilterValue {
        key?: number;
        value?: string;
        selected: boolean
    }

    export enum FilterType {
        textFilter = 0,
        distinctFilter,
        booleanFilter,
        dateRangeFilter
    }

    export class DisplayValue {
        key?: boolean;
        text?: string;
    }

    export function getClientId(): string {
        let id = "";
        if (localStorage !== undefined && localStorage.hasOwnProperty("clientId")) {
            id = JSON.parse(localStorage.getItem("clientId") ?? "");
        }
        return id;
    }

    export function NewGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    export function getListIconStyle() {
        let classNames = mergeStyleSets({
            fileIconHeaderIcon: {
                padding: 0,
                fontSize: '16px',
            },
            fileIconCell: {
                height: 8,
                textAlign: 'center',
                selectors: {
                    '&:before': {
                        content: '.',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        height: '100%',
                        width: '0px',
                        visibility: 'hidden',
                    },
                }, 
                paddingLeft: "8px !important"
            },
            fileIconImg: {
                verticalAlign: 'middle',
                maxHeight: '14px',
                maxWidth: '14px'
            },
            statusIconImg: {
                fontSize: '12px',
                display: 'block',
                marginLeft: '10px'
            }
        });

        return classNames;
    }
}

export default Modus;