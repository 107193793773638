/// <reference path="../../interfaces/models/IProtocolData.ts"/>

class SearchProtocolData {
    public id?: number = 0;
    public fileIcon?: string = "";
    public sourceSystemName?: string = "";
    public destinationSystemName?: string = "";
    public documentType?: string = "";
    public folder?: string = "";
    public timeStamp?: Date = new Date();
    public displayDate?: string = "";
    public status?: boolean = false;
    public errorMessage?: string = "";
    public query?: string = "";
    public queryResult?: string = "";
    public executingUser? : string = "";

    constructor() { }
}

export default SearchProtocolData;