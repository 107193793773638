import * as React from "react";
import {
  Panel,
  PanelType,
  Stack,
  DefaultButton,
  PrimaryButton,
  TextField,
  Dropdown,
  IDropdownOption,
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react";

import SecurityData from "../classes/models/SecurityData";
import { ModusRequests } from "../classes/RequestHandler";
import {PeoplePickerNormalExample} from "./Controls/Peoplepicker"

interface Props {
  confirmedEvent: (message: string) => void;
  history: any;
  startBusy: () => void;
  stopBusy: () => void;
}

export default class CustomSecurityHandling extends React.Component<
  Props,
  any
> {
  constructor(props: any) {
    super(props);
    this.state = { data: new SecurityData() };
  }

  public render(): JSX.Element {
    return (
      <Panel
        isOpen={this.state.hideDialog}
        onDismiss={() => this.confirmDialog("")}
        type={PanelType.custom}
        customWidth={"500px"}
        onRenderFooterContent={this.onRenderFooterContent}
        isFooterAtBottom={true}
        styles={{
          content: {
            height: "calc(100vh - 140px) !important",
            minHeight: "calc(100vh - 140px) !important",
          },
        }}
        headerText={
          this.state.data.id > 0
            ? "Berechtigung bearbeiten"
            : "Neue Berechtigung anlegen"
        }
      >
        <div style={{ minWidth: "200px", maxWidth: "600px" }}>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              marginTop: "20px",
              borderColor: "#CFCDCC",
              borderStyle: "solid",
              borderWidth: "0px",
            }}
          >
            <Stack tokens={{ childrenGap: 20 }}>
              <TextField
                label="Benutzername"
                required={true}
                errorMessage={this.state.inputNameErrorMessage}
                value={this.state.data.userAccount}
                onChange={this.textChange}
                styles={{ root: { maxWidth: "100%" } }}
                disabled={this.state.data.id > 0}
              />
              <ChoiceGroup
                className="defaultChoiceGroup"
                defaultSelectedKey={this.state.data.isActive ? "true" : "false"}
                options={[
                  {
                    key: "true",
                    text: "Aktiv",
                  } as IChoiceGroupOption,
                  {
                    key: "false",
                    text: "Inaktiv",
                  },
                ]}
                onChange={this.onChoiceGroupChange}
                label="Zugriff"
              />
            </Stack>
          </div>
        </div>
      </Panel>
    );
  }

  public updateData = (data: SecurityData): void => {
    this.setState({
      data: data,
      hideDialog: true,
      inputNameErrorMessage: "",
      inputPlatFormErrorMessage: "",
      inputBaseUrlErrorMessage: "",
    });
  };

  public closeDialog = (): void => {
    this.setState({ hideDialog: false });
  };

  private confirmDialog = (message: string): void => {
    this.props.confirmedEvent(message);
  };

  private onRenderFooterContent = () => (
    <div style={{ minWidth: "200px", maxWidth: "600px" }}>
      <Stack tokens={{ childrenGap: 20 }} horizontal>
        <Stack.Item align="start" grow>
          <DefaultButton
            onClick={() => this.confirmDialog("")}
            text="Zurück ohne speichern"
            primaryDisabled={false}
          />
        </Stack.Item>
        <Stack.Item
          align="end"
          grow
          styles={{ root: { alignContent: "flex-end", flexGrow: 0 } }}
        >
          <PrimaryButton
            onClick={this.saveClick}
            text="Berechtigung speichern"
          />
        </Stack.Item>
      </Stack>
    </div>
  );

  private saveClick = (): void => {
    this.clearErrorMessages();
    ModusRequests.Post<SecurityData>(
      "Permission/Post",
      JSON.stringify(this.state.data)
    ).then((data) => {
      this.mapMessages(data);
    });
  };

  private clearErrorMessages = (): void => {
    this.props.startBusy();
    this.setState({
      inputNameErrorMessage: "",
      inputPlatFormErrorMessage: "",
      inputBaseUrlErrorMessage: "",
    });
  };

  private mapMessages = (data): void => {
    if (data.resultType === "Error") {
      this.mapErrorMessages(data.resultMessage);
    } else {
      this.props.confirmedEvent("Berechtigung gespeichert");
    }
  };

  private mapErrorMessages = (message): void => {
    console.error(message);
    this.setState({ inputNameErrorMessage: message });
    this.props.stopBusy();
  };

  private textChange = (eventData): void => {
    let newData = this.state.data;
    newData.userAccount = eventData.target.value;
    this.setState({ data: newData });
  };

  private onDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption
  ): void => {
    let newData = this.state.data;
    newData.permissionLevel = option.key as string;
    this.setState({ data: newData });
  };
  private onChoiceGroupChange = (
    ev: React.FormEvent<HTMLElement>,
    option: IChoiceGroupOption
  ): void => {
    let newData = this.state.data;
    newData.isActive = option.key === "true";
    this.setState({ data: newData });
  };
}
