import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { initializeIcons } from "@uifabric/icons";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import { ModusRequests } from "./classes/RequestHandler";

import { App } from "./App";
import registerServiceWorker from "./registerServiceWorker";
import WizardModel from "./components/Wizard/WizardModel";
import { WizardApp } from "./components/Wizard/WizardApp";

initializeIcons();
initializeFileTypeIcons();

function GetBaseUrl() {
  if (window.location.hostname === "localhost" && window.location.port === "3000") {
    return "https://localhost:44380/";
  } else {
    return "";
  }
}
fetch(`${GetBaseUrl()}api/Wizard/IsConfigured`, {
  method: "GET",
  redirect: "follow",
})
  .then((response) => response.json())
  .then((data: WizardModel) => {
    if (!data.isConfigured || location.href.indexOf("/wizard") !== -1) {
      if (location.href.indexOf("/wizard") !== -1) {
        data.isSqlConnection = true;
      }
      loadWizard(data);
    } else {
      loadApp(data);
    }
  })
  .catch((error) => {
    console.error(error);
  });

function loadWizard(data: WizardModel): void {
  ReactDOM.render(
    <WizardApp isSqlConnection={data.isSqlConnection} />,
    document.getElementById("root")
  );

  registerServiceWorker();
}

function loadApp(data: WizardModel): void {
  localStorage.setItem("authority", data.authority);
  localStorage.setItem("clientId", data.clientId);
  localStorage.setItem("scope", data.scope);
  localStorage.setItem("useAadAuthentication", JSON.stringify(data.useAad));

  ReactDOM.render(<App />, document.getElementById("root"));

  registerServiceWorker();
}
