import * as React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";
import ModusNavigation from "../../standard/ModusNavigation";

import "../../css/navigation.css";

export default class Layout extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div>
        <NavMenu isAuthorized={this.props.isAuthorized} loadPersona={true}/>
        <div className="clearfix">
          <div className="left">
            {this.props.isAuthorized === true && <ModusNavigation />}
          </div>
          <div className="right">
            <Container
              style={{ margin: "0px", padding: "0px", maxWidth: "100%" }}
            >
              {this.props.children}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
