export class ParamMappingError {

    constructor() {
        this.name = "";
        this.parameterName = "";
        this.mappingFormulaType = "";
        this.mappingFormatting = "";
        this.mappingValue = "";
    }

    name?: string;
    parameterName?: string;
    mappingFormulaType?: string;
    mappingFormatting?: string;
    mappingValue?: string;
}