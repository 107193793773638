import * as React from 'react';
import { DefaultButton, PrimaryButton, FontIcon, ICommandBarItemProps, CommandBar, Label, SelectionMode } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter} from '@fluentui/react/lib/Dialog';

import SystemData from '../../classes/models/SystemData';

import WaitingIndicator from '../../customComponents/WaitingIndicator';
import M365DetailList from '../../customComponents/M365DetailList';
import CustomSystemHandling from '../../customComponents/CustomSystemHandling';

import { ctxt } from '../../config/AppContext';
import { Modus } from '../../classes/CustomColumn';
import { ModusRequests } from '../../classes/RequestHandler';

export default class Systems extends React.Component<any, any> {
    private SystemHandling = React.createRef<CustomSystemHandling>();

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);

        this.state = {
            items: [], hideDialog: true, dialogMessage: "",
            isLoading: true, selectedItem: new SystemData(), viewItemMenuParam: "none" };
        this.getSystemData();
    }

    public render(): JSX.Element {
        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading} />
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}>
                        Verfügbare Systeme
                    </Label>
                    <M365DetailList
                        heightHeader={190}
                        items={this.state.items}
                        columns={this.setColumns()}
                        modalSelection={SelectionMode.single}
                        onSelectEvent={this.onItemClick}
                        onSelectedItemEvent={this.onSelectedItemParamClick}
                    />
                </div>
                <CustomSystemHandling
                    confirmedEvent={this.confirm}
                    history={this.props.history}
                    ref={this.SystemHandling}
                    startBusy={() => this.setState({ isLoading: true })}
                    stopBusy={() => this.setState({ isLoading: false })}
                />
                <Dialog
                    hidden={this.state.hideDialog}
                    onDismiss={this.closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Löschen bestätigen",
                        subText: this.state.dialogMessage
                    }}
                    modalProps={{ isBlocking: true }} >
                    <DialogFooter>
                        <DefaultButton onClick={this.closeDialog} text="Abbrechen" primaryDisabled={false} />
                        <PrimaryButton onClick={this.deleteConfirmed} text="Löschen" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }

    private confirm = (message: string): void => {
        if (message !== "") {
            this.setState({ isLoading: true });
            this.getSystemData();
        }
        this.SystemHandling.current.closeDialog();
    }

    private deleteConfirmed = (): void => {
        this.setState({ isLoading: true, hideDialog: true });
        ModusRequests.Post<any>("System/Delete", JSON.stringify(this.state.itemToDelete))
            .then((data: any) => {
                this.getSystemData();
            });
    }

    private showDialog = (item: SystemData): void => {
        this.setState({ hideDialog: false, dialogMessage: `Wollen Sie das System ${item.name} wirklich löschen? Alle Zuordnungen dieses Systems werden ebenfalls gelöscht!`, itemToDelete: item });
    }

    private closeDialog = (): void => {
        this.setState({ hideDialog: true });
    }

    private getSystemData = (): void => {
        ModusRequests.GetData<SystemData>("System/Get")
            .then((data: Array<SystemData>) => {
                this.setState({ items: data, isLoading: false });
                this.context.setKey('key2');
            });
    }

    private editClick = (item: SystemData): void => {
        this.SystemHandling.current.updateData(item);
    }

    private editMappingClick = (item: SystemData): void => {
        this.props.history.push({
            pathname: '/mappings',
            state: { System: item.name }
        });
    }

    private deleteSystemClick = (item: SystemData): void => {
        this.showDialog(item);
    }

    private onItemClick = (pData: SystemData): void => {
        this.editClick(JSON.parse(JSON.stringify(pData)))
    }

    private onSelectedItemParamClick = (pData: Array<SystemData>): void => {
        if (pData.length === 0)
            this.setState({ viewItemMenuParam: "none" });
        else
            this.setState({ selectedItem: pData[0], viewItemMenuParam: "block" });
    }

    private setColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'Name', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true)
            }, {
                key: 'SystemId', name: 'System ID', fieldName: 'sourceSystemGuid', minWidth: 200, maxWidth: 300, isResizable: true, data: 'string'
            }, {
                key: 'IsOnline', name: 'Aktiv', fieldName: 'isOnline', minWidth: 50, maxWidth: 50, isResizable: false, data: 'boolean',
                onRender: (item: SystemData) => {
                    return (item.isOnline ?
                        <FontIcon iconName="completed" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'green' }} /> :
                        <FontIcon iconName="ErrorBadge" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} />)
                },
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.booleanFilter, true, true, null, [{ key: true, text: 'Aktiv' }, { key: false, text: 'Inaktiv' }])
            }, {
                key: 'UserName', name: 'Geändert von', fieldName: 'userName', minWidth: 100, maxWidth: 200, isResizable: true, data: 'string',
            }, {
                key: 'placeHolder', name: '', fieldName: '', minWidth: 10 
            }
        ]
        return columns;
    }

    private renderCommands = (): Array<ICommandBarItemProps> => {
        return [
            {
                key: 'newItem', text: 'Neu', ariaLabel: 'New', style: { backgroundColor: "transparent" },
                iconProps: { iconName: 'Add', style: { fontSize: "15px" } },
                onClick: () => this.SystemHandling.current.updateData(new SystemData())
            }, {
                key: 'editItem', text: 'Bearbeiten', ariaLabel: 'Edit', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Edit', style: { fontSize: "15px" } },
                onClick: () => this.editClick(this.state.selectedItem)
            }, {
                key: 'deleteItem', text: 'Löschen', ariaLabel: 'Delete', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'Delete', style: { fontSize: "15px" } },
                onClick: () => this.deleteSystemClick(this.state.selectedItem)
            }, {
                key: 'mappings', text: 'Zuordnungen', ariaLabel: 'Mappings', style: { display: this.state.viewItemMenuParam, backgroundColor: "transparent" },
                iconProps: { iconName: 'PlugConnected', style: { fontSize: "15px" } },
                onClick: () => this.editMappingClick(this.state.selectedItem)
            }]
    }
}