import * as React from 'react';
import { Label, Icon, FontIcon, CommandBar, ICommandBarItemProps, SelectionMode } from '@fluentui/react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';

import Modus from '../../classes/CustomColumn';
import SearchProtocolData from '../../classes/models/SearchProtocolData';
import WaitingIndicator from '../../customComponents/WaitingIndicator';
import M365DetailList from '../../customComponents/M365DetailList';
import CustomProtocolPanel from '../../customComponents/CustomSearchProtocolPanel';
import { ctxt } from '../../config/AppContext';
import ModusRequests from '../../classes/RequestHandler';

export interface State {
    columns?: Array<Modus.CustomColumn>;
    isLoading: boolean;
    items?: Array<SearchProtocolData>;
    openDataPanel: boolean;
    details: SearchProtocolData;
    viewItemMenu: string;
    selectedProtocol: SearchProtocolData;
    systemData: Array<any>;
    archiveData: Array<any>;
}

export default class SearchProtocols extends React.Component<any, State> {
    private columns: Array<Modus.CustomColumn> = [];

    static contextType = ctxt;
    context: React.ContextType<typeof ctxt>;

    constructor(props: any) {
        super(props);

        this.columns = this.setColumns();
        this.getProtocolData();

        this.state = {
            columns: this.columns, isLoading: true, viewItemMenu: "none", selectedProtocol: new SearchProtocolData(),
            items: [], openDataPanel: false, details: new SearchProtocolData(),
            systemData: [], archiveData: []
        };
    }

    public render(): JSX.Element {
        return (
            <div>
                <WaitingIndicator labelText='Daten werden geladen...' isLoading={this.state.isLoading}></WaitingIndicator >
                <CommandBar
                    items={this.renderCommands()}
                    overflowButtonProps={{ ariaLabel: "Weitere Elemente" }}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                    styles={{
                        root: {
                            backgroundColor: "rgb(243, 242, 241)", fontFamily: "-webkit-font-smoothing: antialiased",
                            height: "40px", lineHeight: "40px"
                        }
                    }}
                />
                <div style={{ paddingLeft: '20px', paddingRight: "20px" }}>
                    <Label style={{ paddingTop: '30px', paddingBottom: '15px', fontSize: '18px' }}>
                        Suchprotokolle
                        </Label>
                    <M365DetailList
                        heightHeader={190}
                        items={this.state.items}
                        columns={this.columns}
                        modalSelection={SelectionMode.single}
                        onSelectEvent={this.openProtocolPanel}
                        onSelectedItemEvent={this.onSelectedItemParamClick}
                    />
                    {this.state.openDataPanel && <CustomProtocolPanel
                        proData={this.state.details}
                        history={this.props.history}
                        archiveData={this.state.archiveData}
                        systemData={this.state.systemData}
                        closeEvent={() => this.setState({ openDataPanel: false })}
                    />}
                </div>
            </div>
        )
    }

    private openProtocolPanel = (pData: SearchProtocolData): void => {
        this.getData(pData);    
    }

    private getData = (pData: SearchProtocolData): void => {
        ModusRequests.GetData<any>("ProtocolDetail/Get?Id=" + pData?.id)
            .then((data) => {
                if (data.hasOwnProperty("systemDetails"))
                    this.mapSystemData(data["systemDetails"]);
                if (data.hasOwnProperty("archiveDetails") || data.hasOwnProperty("archiveFolderDetails"))
                    this.mapArchiveData(data["archiveDetails"], data["archiveFolderDetails"]);

                this.setState({ details: pData, openDataPanel: true });
            });
    }

    private mapSystemData = (data: Array<any>): void => {
        var result = Array<any>();
        for (var key in data) {
            result.push({ "Key": key, "Value": data[key] });
        }
        this.setState({ systemData: result });
    }

    private mapArchiveData = (data?: Array<any>, folderData?: Array<any>): void => {
        var result = Array<any>();
        if (folderData !== undefined)
            for (var key in folderData) {
                result.push({ "Key": key, "Value": folderData[key], "Type": "Ordner" });
            }
        if (data !== undefined)
            for (var key in data) {
                result.push({ "Key": key, "Value": data[key], "Type": "Liste" });
            }
        this.setState({ archiveData: result });
    }

    private getProtocolData(): void {
        ModusRequests.GetData<SearchProtocolData>("SearchProtocol/GetProtocols")
            .then((data: Array<SearchProtocolData>) => {
                this.setState({ items: data, isLoading: false });
                this.context.setKey('key7');
            });
    }

    private onSelectedItemParamClick = (pData: Array<SearchProtocolData>): void => {
        if (pData.length === 0) {
            this.setState({ viewItemMenu: "none" });
        } else {
            this.setState({ selectedProtocol: pData[0], viewItemMenu: "block" });
        }
    }

    private setColumns = (): Array<Modus.CustomColumn> => {
        let columns = [
            {
                key: 'searchKey', name: 'Suchschlüssel', fieldName: 'fileName', minWidth: 150, maxWidth: 200, isResizable: true,
                data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.textFilter, true, true),
                onRender: (item: SearchProtocolData) => {
                    let searchParameters = JSON.parse(item.query).OriginalParamData;
                    return (JSON.stringify(searchParameters));
                }
            }, {
                key: 'status', name: 'Status', fieldName: 'status', minWidth: 55, maxWidth: 55, isResizable: true, data: 'boolean',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.booleanFilter, true, true, [], [{ key: true, text: 'Erfolgreich' }, { key: false, text: 'Fehler' }]),   
                onRender: (item: SearchProtocolData) => {
                    return (item.status ?
                        <FontIcon iconName="completed" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'green' }} /> :
                        <FontIcon iconName="ErrorBadge" className={Modus.getListIconStyle().statusIconImg} style={{ color: 'salmon' }} />)
                }
            }, {
                key: 'sourceSystemName', name: 'System', fieldName: 'sourceSystemName', minWidth: 150, maxWidth: 200,
                isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true)   
            }, {
                key: 'destinationSystemName', name: 'DMS', fieldName: 'destinationSystemName',
                minWidth: 150,  maxWidth: 200, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true)
            }, {
                key: 'documentType', name: 'Zuordnung', fieldName: 'documentType',
                minWidth: 100, maxWidth: 150, isResizable: true, data: 'string',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.distinctFilter, true, true)
            }, {
                key: 'displayDate', name: 'Datum', fieldName: 'displayDate', minWidth: 80, maxWidth: 100,
                isResizable: true, data: 'date',
                customColumnProps: new Modus.CustomColumnProps(Modus.FilterType.dateRangeFilter, true, true)
            }, {
                key: 'placeHolder', name: '', fieldName:'', minWidth: 10
            }
        ]
        return columns;
    }

    private editMappingClick = (item: SearchProtocolData): void => {
        this.props.history.push({
            pathname: '/mappings',
            state: { System: item.sourceSystemName, Document: item.documentType }
        });
    }

    private renderCommands = (): ICommandBarItemProps[] => {
        return [{
            key: 'reload', text: 'Aktualisieren', ariaLabel: 'Refresh', style: { backgroundColor: "transparent" },
            iconProps: { iconName: 'Refresh', style: { fontSize: "15px" } },
            onClick: () => { this.setState({ isLoading: true }); this.getProtocolData(); }
        }, {
            key: 'dividers',
            onRender: () => <div style={{ display: this.state.viewItemMenu, margin: '5px', width: '1px', background: '#ddd' }} > </div>
        },{
            key: 'viewDetails', text: 'Information', ariaLabel: 'View Details', style: { display: this.state.viewItemMenu, backgroundColor: "transparent" },
            iconProps: { iconName: 'Info2', style: { fontSize: "15px" } },
            onClick: () => this.setState({ details: this.state.selectedProtocol, openDataPanel: true })
        }, {
            key: 'divider',
            onRender: () => <div style={{ display: this.state.viewItemMenu, margin: '5px', width: '1px', background: '#ddd' }} > </div>
        }, {
            key: 'mappings', text: 'Zuordnungen', ariaLabel: 'Mappings', style: { display: this.state.viewItemMenu, backgroundColor: "transparent" },
            iconProps: { iconName: 'PlugConnected', style: { fontSize: "15px" } },
                onClick: () => this.editMappingClick(this.state.selectedProtocol)
        }] 
    }
}