import * as React from 'react';
import { Panel, PanelType, Stack, DefaultButton, PrimaryButton, IChoiceGroupOption, TextField, IDropdownOption, Dropdown, Label } from '@fluentui/react';
import { ImportDocumentTypeData } from '../../classes/models/ImportData';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from '../Style/MentionStyle';
import ModusRequests from '../../classes/RequestHandler';

interface Props {
    saveEvent: (data: ImportDocumentTypeData) => void;
    waitingEvent: (isWaiting: boolean) => void;
    Systems: Array<any>;
    Archive: Array<any>;
}

interface State {
    data: ImportDocumentTypeData;
    hideDialog: boolean;
    inputNameErrorMessage: string;
    inputSystemErrorMessage: string;
    inputArchivErrorMessage: string;
    inputRootUrlErrorMessage: string;
    tempStartUrl: string;
    isValidated: boolean;
    dropdownData: Array<any>;
}

export default class ImportConnectorHandling extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: new ImportDocumentTypeData(), hideDialog: false, isValidated: false,
            inputNameErrorMessage: "", inputSystemErrorMessage: "", inputArchivErrorMessage: "",
            inputRootUrlErrorMessage: "", tempStartUrl: "", dropdownData: []
        };
    }

    public render(): JSX.Element {
        return (
            <Panel
                isOpen={this.state.hideDialog}
                onDismiss={() => this.confirmDialog(null)}
                type={PanelType.custom}
                customWidth={'500px'}
                isLightDismiss={false}
                onRenderFooterContent={this.onRenderFooterContent}
                isFooterAtBottom={true}
                styles={{ content: { height: "calc(100vh - 140px) !important", minHeight: "calc(100vh - 140px) !important" } }}
                headerText={
                    this.state.data.Id > 0 ?
                        this.state.data.DocumentTypeName + '-Zuordnung' : 'Neue Zuordnung anlegen'
                } >
                <div style={{ minWidth: '200px', maxWidth: '600px' }}>
                    <div style={{
                        paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', marginTop: '20px',
                        borderColor: '#CFCDCC', borderStyle: 'solid', borderWidth: '0px'
                    }}>
                        <Stack tokens={{ childrenGap: 20 }}>
                            <TextField
                                label="Eindeutiger Name"
                                required={true}
                                errorMessage={this.state.inputNameErrorMessage}
                                validateOnLoad={false}
                                value={this.state.data.DocumentTypeName}
                                onChange={this.textChange}
                                styles={{ root: { maxWidth: '100%' } }}
                            />
                            <Dropdown placeholder="Bitte wählen Sie ein System aus!"
                                defaultSelectedKey={this.state.data.SystemId}
                                label="System"
                                options={this.props.Systems}
                                styles={{ dropdown: { maxWidth: '100%' } }}
                                onChange={this._onDropdownSystemChange}
                                required={true}
                                errorMessage={this.state.inputSystemErrorMessage}
                            />
                            <Dropdown placeholder="Bitte wählen Sie ein DMS aus!"
                                defaultSelectedKey={this.state.data.ArchiveSystemId}
                                label="DMS"
                                options={this.props.Archive}
                                styles={{ dropdown: { maxWidth: '100%' } }}
                                onChange={this._onDropdownArchivChange}
                                required={true}
                                errorMessage={this.state.inputArchivErrorMessage}
                            /> {(this.state.data.IsShareflex || this.state.data.IsSharepoint) &&
                                <div style={{ width: "100%" }}>
                                    <Label required={true} >Site-URL</Label>
                                    <div style={{ width: '100%' }}>
                                        <div style={!this.state.isValidated ? { width: 'calc(100% - 105px)', float: 'left' } : { width: '100%', float: 'left' }}>
                                            <MentionsInput
                                                value={this.state.data.StartUrl ?? ""}
                                                onChange={this.onMentionChange}
                                                style={this.state.inputRootUrlErrorMessage === '' ? defaultStyle : defaultStyle.controlError}
                                                placeholder={"Parameterliste mit '{' aufrufen"}>
                                                <Mention
                                                    markup="@[__display__](__id__)"
                                                    trigger="{"
                                                    data={this.state.data.AvailableParamKeys.map(item => { return { id: item, display: '{' + item + '}' } })}
                                                    renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                                        <div className={`${focused ? 'focused' : ''}`}>
                                                            {highlightedDisplay}
                                                        </div>
                                                    )}
                                                    style={{ backgroundColor: '#e6e6e6', }} />
                                            </MentionsInput>
                                        </div>
                                        {!this.state.isValidated &&
                                            <PrimaryButton
                                                onClick={this.validateSpPage}
                                                text="Validieren"
                                                primaryDisabled={false}
                                                ariaLabel="Validieren"
                                            />}
                                    </div>
                                    <span style={{ whiteSpace: "nowrap", fontWeight: 400, paddingTop: "5px", fontSize: '12px', color: 'rgb(164, 38, 44)' }}>{this.state.inputRootUrlErrorMessage} </span>
                                </div>}
                            {!this.state.data.IsShareflex && !this.state.data.IsSharepoint &&
                                <div style={{ width: "100%" }}>
                                    <Dropdown
                                        placeholder="Bitte wählen Sie eine Dokumentenmaske aus!"
                                        defaultSelectedKey={this.state.data.DocumentLibrary}
                                        required={true}
                                        styles={{ dropdown: { maxWidth: '100%' } }}
                                        options={this.state.dropdownData}
                                        errorMessage={this.state.inputRootUrlErrorMessage}
                                        onChange={this.onLibChanged}
                                        label="Dokumentenmaske" />
                                </div>
                            }
                        </Stack>
                    </div>
                </div>
            </Panel >
        );
    }

    public updateData = (data: ImportDocumentTypeData): void => {
        if (!data.IsSharepoint && !data.IsShareflex) {
            this.validateDmsPage(data);
            return;
        }
        this.setState({
            data: data, hideDialog: true, inputNameErrorMessage: "", inputArchivErrorMessage: "",
            inputSystemErrorMessage: "", inputRootUrlErrorMessage: "",  tempStartUrl: data.StartUrl, isValidated: data.IsMapped
        });
    }

    public closeDialog = (): void => {
        this.setState({ hideDialog: false });
    }

    //#region DMS

    private validateDmsPage = (p_data: ImportDocumentTypeData): void => {
        this.props.waitingEvent(true);
        this.setState({
            data: p_data, hideDialog: true, inputNameErrorMessage: "", inputArchivErrorMessage: "",
            inputSystemErrorMessage: "", inputRootUrlErrorMessage: "", tempStartUrl: p_data.StartUrl });
        ModusRequests.Get<any>("ImportDms/GetLists?ArchiveSystemId=" + p_data.ArchiveSystemId)
            .then((data) => {
                if (typeof (data) === "string") {
                    this.setState({ inputRootUrlErrorMessage: data, isValidated: false });
                } else {
                    let ddData: Array<any> = [];
                    Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: (pf.key as string), text: pf.value }) });
                    if (ddData.filter(x => x.key === (p_data.DocumentLibrary)).length === 1 &&
                        ddData.filter(x => x.key === p_data.DocumentLibrary)[0].text === p_data.DocumentLibraryName) {
                        this.setState({ dropdownData: ddData, isValidated: true });
                    }
                    else if (ddData.filter(x => x.text === p_data.DocumentLibraryName).length === 1) {
                        let newData = data;
                        newData.DocumentLibrary = (ddData.filter(x => x.text === p_data.DocumentLibraryName)[0].key as number);
                        this.setState({ data: newData, dropdownData: ddData, isValidated: true });
                    } else {
                        this.setState({
                            dropdownData: ddData, isValidated: false,
                            inputRootUrlErrorMessage: "Die Dokumentenmaske konnte nicht gefunden werden!"
                        });
                    }
                }
                this.props.waitingEvent(false);
            });
    }

    private onLibChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let newData = this.state.data;
        newData.DocumentLibrary = option.key as string;
        newData.DocumentLibraryName = option.text;
        newData.ContentTypeId = "";
        newData.ContentTypeName = option.text;
        this.setState({ data: newData, isValidated: true });
    }

    //#endregion DMS

    //#region Mention

    private onMentionChange = (event, newValue, newPlainTextValue, mentions): void => {
        let newData = this.state.data;
        newData.StartUrl = newValue.replace("/ @[", "/@[");
        this.setState({
            data: newData, isValidated: false, tempStartUrl:
                this.replacePlaceholder(newValue.replace("/ @[", "/@["))
        });
    }

    private replacePlaceholder = (text: string): string => {
        do {
            text = this.replaceMentionText(text);
        } while (text.indexOf("@[{") >= 0);

        return text;
    }

    private replaceMentionText = (text: string): string => {
        let startIndex = text.indexOf("@[{");
        if (startIndex < 0)
            return text;
        startIndex += 3;
        let stopIndex = text.indexOf("}](");
        let mentionText = text.substring(startIndex, stopIndex);
        let mention = "@[{" + mentionText + "}](" + mentionText + ")";
        let availableParams = JSON.parse(this.state.data.AvailableParams);
        let newValue = "";
        if (availableParams.hasOwnProperty(mentionText))
            newValue = availableParams[mentionText];

        return text.replace(mention, newValue);
    }

    //#endregion Mention

    //#region SpValidation

    private validateSpPage = (): void => {
        if (this.state.data.ArchiveSystemId === 0) {
            this.setState({ inputArchivErrorMessage: "Bitte wählen Sie ein DMS aus!" });
            return;
        }
        this.props.waitingEvent(true);
        this.setState({ inputRootUrlErrorMessage: "" });
        this.getDocTyp();
    }

    private getDocTyp = (): void => {
        let validatedUrl = this.replaceMentionText(this.state.tempStartUrl);
        console.info(`Validating Url ${validatedUrl}...`)
        ModusRequests.Get<any>("ImportSharepoint/GetLists?ArchiveSystemId=" + this.state.data.ArchiveSystemId + "&PathToFolder=" + validatedUrl)
            .then((data: any) => {
                if (typeof (data) === "string") {
                    this.setState({ inputRootUrlErrorMessage: data });
                    this.props.waitingEvent(false);
                    return;
                } else {
                    let ddData: Array<any> = [];
                    Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.key, text: pf.value }) });
                    if (ddData.filter(x => x.key === this.state.data.DocumentLibrary).length === 1 &&
                        ddData.filter(x => x.key === this.state.data.DocumentLibrary)[0].text === this.state.data.DocumentLibraryName) { }
                    else {
                        let newData = this.state.data;
                        newData.DocumentLibrary = ddData.filter(x => x.text === this.state.data.DocumentLibraryName)[0].key;
                        this.setState({ data: newData });
                    }
                    this.getConTyp();
                }
            });
    }

    private getConTyp = (): void => {
        if (this.state.data.UseContentTypes)
        {
            let validatedUrl = this.replaceMentionText(this.state.tempStartUrl);
            console.info(`Validating Url ${validatedUrl}...`)
            ModusRequests.Get<any>("ImportSharepoint/GetContentTypes?ArchiveSystemId=" + this.state.data.ArchiveSystemId + "&PathToFolder=" + validatedUrl + '&LibaryName=' + this.state.data.DocumentLibraryName)
                .then((data: any) => {
                    if (typeof (data) === "string") {
                        this.setState({ inputRootUrlErrorMessage: data });
                        this.props.waitingEvent(false);
                        return;
                    } else {
                        let ddData: Array<any> = [];
                        Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.value, text: pf.key }) });
                        if (ddData.filter(x => x.key === this.state.data.ContentTypeId).length === 1 &&
                            ddData.filter(x => x.key === this.state.data.ContentTypeId)[0].text === this.state.data.ContentTypeName) { }
                        else {
                            let newData = this.state.data;
                            newData.ContentTypeId = ddData.filter(x => x.text === this.state.data.ContentTypeName)[0].key;
                            this.setState({ data: newData });
                        }
                    }
                    this.getShFlex();
                });
        }
        else {
            this.getShFlex();
        }
    }

    private getShFlex = (): void => {
        if (this.state.data.IsShareflex)
        {
            let validatedUrl = this.replaceMentionText(this.state.tempStartUrl);
            console.info(`Validating Url ${validatedUrl}...`)
            ModusRequests.Get<any>("ImportSharepoint/GetSfLists?ArchiveSystemId=" + this.state.data.ArchiveSystemId + "&PathToFolder=" + validatedUrl)
                .then((data: any) => {
                    if (typeof (data) === "string") {
                        this.setState({ inputRootUrlErrorMessage: data });
                    } else {
                        let ddData: Array<any> = [];
                        Object.entries(data).map(([key, value]) => ({ key, value })).forEach(pf => { ddData.push({ key: pf.key, text: pf.value }) });
                        if (ddData.filter(x => x.key === this.state.data.ListLibrary).length === 1 &&
                            ddData.filter(x => x.key === this.state.data.ListLibrary)[0].text === this.state.data.ListLibraryName) { }
                        else {
                            let newData = this.state.data;
                            newData.ListLibrary = ddData.filter(x => x.text === this.state.data.ListLibraryName)[0].key;
                            this.setState({ data: newData });
                        }
                    }
                    this.setState({ isValidated: true });
                    this.props.waitingEvent(false);
                });
        }
        else {
            this.props.waitingEvent(false);
            this.setState({ isValidated: true });
        }
    }

    //#endregion SpValidation

    private confirmDialog = (data: ImportDocumentTypeData): void => {
        this.props.saveEvent(data);
    }

    private onRenderFooterContent = () => (
        <div style={{ minWidth: '200px', maxWidth: '600px' }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal >
                <Stack.Item align="start" grow >
                    <DefaultButton onClick={() => this.confirmDialog(null)} text="Zurück ohne speichern" primaryDisabled={false} />
                </Stack.Item>
                <Stack.Item align="end" grow styles={{ root: { alignContent: "flex-end", flexGrow: 0 } }} >
                    <PrimaryButton onClick={this.saveClick} text="Zuordnung speichern" disabled={!this.state.isValidated} />
                </Stack.Item>
            </Stack>
        </div>
    );

    private saveClick = (): void => {
        this.clearErrorMessages();
        if (this.state.data.DocumentTypeName === "" || this.state.data.SystemId === 0 || this.state.data.ArchiveSystemId === 0) {
            if (this.state.data.DocumentTypeName === "")
                this.setState({ inputNameErrorMessage: "Bitte vergeben Sie einen Zuordnungsnamen!" });
            if (this.state.data.SystemId === 0)
                this.setState({ inputSystemErrorMessage: "Bitte wählen Sie ein System aus!" });
            if (this.state.data.ArchiveSystemId === 0)
                this.setState({ inputArchivErrorMessage: "Bitte wählen Sie ein DMS aus!" });
        } else
            this.props.saveEvent(this.state.data);

    }

    private clearErrorMessages = (): void => {
        this.setState({ inputNameErrorMessage: "", inputSystemErrorMessage: "", inputArchivErrorMessage: "" })
    }

    private textChange = (eventData): void => {
        let newData = this.state.data;
        newData.DocumentTypeName = eventData.target.value;
        this.setState({ data: newData });
    }

    private _onDropdownSystemChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let newData = this.state.data;
        newData.SystemId = option.key as number;
        newData.SystemName = option.text;
        this.setState({ data: newData });
    }

    private _onDropdownArchivChange = (event: React.FormEvent<HTMLDivElement>, option: IDropdownOption): void => {
        let newData = this.state.data;
        newData.ArchiveSystemId = option.key as number;
        newData.ArchiveSystemName = option.text;
        this.setState({ data: newData, isValidated: false });

        if (!newData.IsSharepoint && !newData.IsShareflex) {
            this.validateDmsPage(newData);
            return;
        }
    }
}

